import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useReducer, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Button, Typography, Card, Box } from '@mui/material';
import "./Home.scss";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { searchBoxProp, defaultValueApi, exportInternalUserHeader, exportAgentHeader, agentTableHeader, internalUserTableHeader } from "../../Models/home";
import { headers, apiUrls, roleName, apiResponseCode, skipToCheck, componentConst, toastNoiticationConfig, responseCodes, exportFileName, commonConst, dataGridDensity, filterOperators } from "../../Commoncode/constant";
import ReserveBondModals from "../reserveBondModals/ReserveBondModals";
import "../../style/Style.scss";
import PrintExportEmailModal from "../Common/PrintExportEmailModal/PrintExportEmailModal";
import { encryptData,decryptData } from "../../Commoncode/encryptDecrypt";

const Home = () => {

  const [searchBox, setSearchBox] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [userRole, setUserRole] = useState(roleName.inquiry);
  const [isReserveBondDisable, setisReserveBondDisable] = useState(false);
  const [bondNumber, setBondNumber] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
  const [tableRows, settableRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [searchState, searchDispatch] = useReducer(searchReducer, searchBox);

  const [showModal, setShowModal] = useState(false);
  const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
  const [modalPopUpScenarioProp, setModalPopUpScenarioProp] = useState({});
  const [exportAllowed, setExportAllowed] = useState(true);
  const [exportData, setExportData] = useState(defaultValueApi);

  useEffect(() => {
    let loggedInUserDetails = UserDetails();
    if (loggedInUserDetails !== false) {
      setUserRole(loggedInUserDetails.userRoleName);
      let homedata = localStorage.getItem(commonConst.homeData);
      if (homedata !== null) {
        var filteredData = decryptData(homedata);
        setSearchBox(filteredData.Search);

        var filterData = [];
        if (filteredData.FilterData !== null && filteredData.FilterData.length > 0) {
          filteredData.FilterData.map((item, index) => {
            return filterData.push({
              columnField: item.ColumnField,
              operatorValue: item.OperatorValue,
              value: item.Value ? item.Value : null
            });
          });
        }
        setFilteredData(filterData);
        setLinkOperator(filteredData.LinkOperator);
        searchDispatch({ type: searchBoxProp.setFilteredData, value: filteredData });
      }
      else {
        searchDispatch({ type: searchBoxProp.setData, value: loggedInUserDetails });
      }
    }
  }, [])

  useEffect(() => {
    submitSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  const exportHeaders = userRole === roleName.agent ? exportAgentHeader : exportInternalUserHeader;

  const size = window.innerWidth;

  function searchReducer(searchState, action) {
    switch (action.type) {
      case searchBoxProp.search:
        return {
          ...searchState, Search: action.value,
        }
      case searchBoxProp.pageNumber:
        return {
          ...searchState, PageNumber: action.value,
        }
      case searchBoxProp.pageSize:
        return {
          ...searchState, PageNumber: 0, PageSize: action.value,
        }
      case searchBoxProp.setData:
        return {
          ...searchState,
          UserName: action.value.userName,
          UserRole: action.value.userRoleCode,
          PageSize: 20,
          PageNumber: 0,
          Search: "",
          TotalCount: 0,
          SortData: searchBoxProp.dateReserved,
          AscDesc: searchBoxProp.desc,
          FilterData: [],
          LinkOperator: ""
        }
      case searchBoxProp.setFilteredData:
        return {
          ...searchState,
          UserName: action.value.UserName,
          UserRole: action.value.UserRole,
          PageSize: action.value.PageSize,
          PageNumber: action.value.PageNumber,
          Search: action.value.Search,
          TotalCount: action.value.TotalCount,
          SortData: action.value.SortData,
          AscDesc: action.value.AscDesc,
          FilterData: action.value.FilterData,
          LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator
        }
      case searchBoxProp.columnFilterData:
        return {
          ...searchState,
          FilterData: action.value,
          LinkOperator: action.operator
        }
      case searchBoxProp.sortData:
        return {
          ...searchState,
          SortData: action.value[0].field,
          AscDesc: action.value[0].sort,
          PageNumber: 0
        }
      default:
        return {
          ...searchState
        }
    }
  }

  function submitSearch() {
    setBondNumber("");
    var isDataAvailable = false;

    for (const item in searchState) {
      if (searchState[item] && item !== skipToCheck.pageNo && searchState[item].toString().trim() !== "") {
        isDataAvailable = true;
      }
    }

    if (isDataAvailable) {
      localStorage.setItem(commonConst.homeData, encryptData(searchState));

      setShowLoader(true);
      axios.post(apiUrls.getHomePageApiUrl, searchState, { headers: headers })
        .then((response) => {
        setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) 
        {
          settableRows([]);
          settableRows(response.data.homePageDetails.homePageDtls);
          setisReserveBondDisable(response.data.homePageDetails.reserveBondDisabled);
          setRowsCount(response.data.homePageDetails.totalRecords);
        }
      })
        .catch(error => {
          setShowLoader(false);
        });
      }

  }


  const reserveBondClick = () => {
    setShowLoader(true);

    let loggedInUserDetails = UserDetails();

    let setDetails = {
      UserName: loggedInUserDetails.userName,
      AgencyNum: loggedInUserDetails.userAgencyNumber
    }

    axios.post(apiUrls.getReserveBondScenarioDetails, setDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          loggedInUserDetails = UserDetails();

          let reserveModalPopUpScenario = {
            reserveBlock: response.data.scenarioDetails.reserveBlock,
            singleReserveBond: response.data.scenarioDetails.isSingleBondReserve,
            maxOpenAllowed: response.data.scenarioDetails.maxOpenAllowed,
            reserveMax: response.data.scenarioDetails.reserveMax,
            currentOpenReserve: response.data.scenarioDetails.getmaxReserveBond,
            agencyNumber: loggedInUserDetails.userAgencyNumber
          }

          setModalPopUpScenarioProp(reserveModalPopUpScenario);

          setShowModal(true);
        }
      })
        .catch(error => {
            setShowLoader(false);
        });
  }

  function onRowChange(event) {
    setBondNumber(event.row.bondNo);
  }

  function onFilterChange(filterModel) {
    if(filterModel.items.length >= 2){
      var check = filterModel.items;
      var columnList = []
      for(var item in check){
        if(!columnList.includes(check[item].columnField)){
          
          columnList.push(check[item].columnField)
        }
          else{ 
            if(check[item].value){
              
            toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
              position: toast.POSITION.TOP_RIGHT
            });
            filterModel.items.pop();
            setFilteredData(filterModel.items);
            return;
            }
          }
        }
    }
    setFilteredData(filterModel.items);
    if (filterModel !== null && filterModel.linkOperator !== "") {
      setLinkOperator(filterModel.linkOperator);
    }
    else {
      setLinkOperator(GridLinkOperator.And);
    }

    var filterData = [];
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: searchBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });

  }

  const callFnPageSize = React.useCallback((newPageSize) => {
    searchDispatch({ type: searchBoxProp.pageSize, value: newPageSize });
  }, []);

  const handleClosePopup = () => {
    setShowModal(false);
  };

  const CallPrintExportEmail = () => {
    let exportData = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.getHomePageApiUrl, exportData, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setExportData(defaultValueApi);
        if (response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.DataTooLarge) {
          setExportAllowed(false);
          setShowPrintExportEmailModal(true);
        }
        else if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          let totalRecords = [];
          if (response.data.homePageDetails.homePageDtls.length > 0) {

            response.data.homePageDetails.homePageDtls.map((item, index) => {
              return totalRecords.push({
                bondNo: item.bondNo ? item.bondNo : '',
                agencyNo: item.agencyNo ? '=""' +item.agencyNo+ '""' : '',
                agencyName: item.agencyName ? item.agencyName : '',
                userFullName: item.userFullName ? item.userFullName : '',
                dateReserved: item.dateReserved ? item.dateReserved : '',
                dateBooked: item.dateBooked ? item.dateBooked : '',
                status: item.status ? item.status : '',
                insured: item.insured ? item.insured : '',
                profitCenter: item.profitCenter ? item.profitCenter : '',
                department: item.department ? item.department : '',
                product: item.product ? item.product : '',
                comment: item.comment ? item.comment : '',
                lastUpdateBy: item.lastUpdateBy ? item.lastUpdateBy : '',
                lastUpdate: item.lastUpdate ? item.lastUpdate : '',
                agencyVarianceResolved: item.agencyVarianceResolved ?  item.agencyVarianceResolved : false,
                internalComment: item.internalComment ? item.internalComment : '',
              });
            });
            setExportData(totalRecords);
            setExportAllowed(true);
            setShowPrintExportEmailModal(true);
          }
          else
          {
            toast.warning(toastNoiticationConfig.noRecordFound, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
      .catch(error => {
        setShowLoader(false);
      });
  }

  const handleClosePrintExportEmailPopup = () => {
    setShowPrintExportEmailModal(false);
  };

  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.emailHomeDetails, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }

  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: searchBoxProp.sortData, value: prop });
    }
  }



  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        C&F Bond Reservation System
      </Typography>

      <Box className="body-container homePageContainer" noValidate autoComplete="off">
        <div className="table-header">
          <div className="table-header-left">
            <Paper className="paperStyleHome" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >

              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} value={searchBox}
                onKeyPress={(e) => e.key === 'Enter' && searchDispatch({ type: searchBoxProp.search, value: e.target.value })} onChange={(e) => { setSearchBox(e.target.value) }} />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => { searchDispatch({ type: searchBoxProp.search, value: searchBox }); }}>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>

          </div>

          <div className="table-header-right">
            <NavLink style={{ textDecoration: 'none' }} to={bondNumber === "" ? componentConst.home : componentConst.updateBond + "?bondnum=" + bondNumber}>  <Button type="button" variant="contained" className="btnStyling" disabled={bondNumber === "" ? true : false}>View/Modify</Button> </NavLink>  &nbsp;
            {userRole !== roleName.agent && <NavLink style={{ textDecoration: 'none' }} to={componentConst.agencyVarianceReport}>  <Button type="button" variant="contained" className="btnStyling">Agency Variance</Button></NavLink>} &nbsp;
            <NavLink style={{ textDecoration: 'none' }} to={componentConst.bondThirtyDaysReport}>  <Button type="button" variant="contained" className="btnStyling">Over 90 Day Report</Button></NavLink> &nbsp;
            <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallPrintExportEmail}>Export/Email</Button> &nbsp;

            {userRole === roleName.agent && <Button type="button" variant="contained" className="btnStyling" disabled={isReserveBondDisable} onClick={reserveBondClick}>Reserve Bond Numbers</Button>}
            {userRole !== roleName.agent && <NavLink style={{ textDecoration: 'none' }} to={isReserveBondDisable === false ? componentConst.reserveBond : componentConst.home}>  <Button type="button" disabled={isReserveBondDisable} className="btnStyling" variant="contained">Reserve Bond Numbers</Button> </NavLink>}

          </div>
        </div>

        <div className="table-body">
          <Card sx={{ minWidth: 275 }}>
            <div className="tableContainerHome" style={{ width: '100%' }}>
              <DataGridPro 
                className="muiDataGrid" density={dataGridDensity.compact}
                componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
                components={{ Toolbar: GridToolbar, disableExport: true }}
                disableColumnFilter={false}
                rows={tableRows}
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                rowCount={rowsCount}
                columns={userRole === roleName.agent ? agentTableHeader : internalUserTableHeader}
                rowsPerPageOptions={[20, 50, 100, 500]}
                pagination
                page={searchState.PageNumber}
                pageSize={searchState.PageSize}
                paginationMode="server"
                onPageChange={(data) => {
                  searchDispatch({ type: searchBoxProp.pageNumber, value: data });
                }}
                onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
                rowHeight={size >= 1400 ? 50 : 20}
                maxRowHeight={size >= 1400 ? 50 : 20}
                minRowHeight={size >= 1400 ? 50 : 20}
                headerHeight={size >= 1400 ? 50 : 22}
                onRowClick={(e) => onRowChange(e)}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterModel={{
                  items: filteredData,
                  linkOperator: linkOperator,
                }}
              />

            </div>
          </Card>
        </div>
      </Box>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <ReserveBondModals showPopUpflag={showModal} modalPopUpScenario={modalPopUpScenarioProp} closePopup={handleClosePopup}></ReserveBondModals>
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.reserveBondReport} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportHeaders} closePopup={handleClosePrintExportEmailPopup} sendEmail={callSendEmail}></PrintExportEmailModal>
    </React.Fragment>
  )
}

export default Home;