import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { roleName,componentConst,filterOperators } from "../../Commoncode/constant";
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserDetails from "../../Hooks/UserDetails";

const getRoles =()=>{
  let userDetails = UserDetails();
  if(userDetails){
     let roleDetails = userDetails.userRoleSystem.filter(e=>e.systemId ===3);
     if(roleDetails.length>0){
      return roleDetails[0].roleName ===roleName.admin;
     }
  }
  return false;
}

export const searchBox = {
  UserName: "",
  UserRole: 0,
  Search: "",
  PageNumber: 0,
  PageSize: 20,
  FilterData: [],
  LinkOperator: "",

  Export: false,
  TotalCount: 0,

  SortData: "id",
  AscDesc: "desc"
}

export const searchBoxProp = {
  search: "search",
  pageNumber: "pageNumber",
  pageSize: "pageSize",
  columnFilterData: "columnFilterData",

  contains: "contains",
  equals: "equals",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  isAnyOf: "isAnyOf",

  id:"id",
  currentDate:"currDate",
  respondBy:"respondBy",
  insRqmtProdctNo:"insRqmtProdctNo",
  policyNumber:"policyNumber",
  producerName:"producerName",
  producerNo:"producerNo",
  accountName:"accountName",
  insuredName:"insuredName",
  underWriterName:"underwriterName",
  effectiveDate:"effDate",
  prodctExpDt:"prodctExpDt",
  bondAmount:"bondAmount",
  comment:"comment",

  setData: "setData",
  setFilteredData: "setFilteredData",
  TotalCount: "TotalCount",
  sortData: "sortData",
  asc: "asc",
  desc: "desc"
}

export const projectStatusProp ={
  id:"id",
  currentDate:"currDate",
  respondBy:"respondBy",
  insRqmtProdctNo:"insRqmtProdctNo",
  policyNumber:"policyNumber",
  producerId:"adId",
  producerName:"producerName",
  producerNo:"producerNo",
  accountName:"accountName",
  insuredName:"insuredName",
  underWriterName:"underwriterName",
  underWriterId:"underwriterId",
  effectiveDate:"effDate",
  prodctExpDt:"prodctExpDt",
  bondAmount:"bondAmount",
  dateReceived:"dateReceived",
  ua:"uamId",
  uAName:"uaName",
  status:"smId",
  statusName:"statusName",
  anticipatedCompleteDate:"anticipatedCompleteDate",
  bondAmountChange:"isBondAmountChanged",
  newBondAmount:"newBondAmount",
  bondAmountChangeValue:"bondAmountChange",
  aPRP:"apRp",
  comment:"comment"
}

export const initialprojectStatusValue ={
  id:0,
  adId:0,
  producerName:'',
  producerNo:'',
  currDate:'',
  respondBy:'',
  insRqmtProdctNo:0,
  policyNumber:'',
  accountName:'',
  insuredName:'',
  underwriterName:'',
  underwriterId:'',
  effDate:'',
  prodctExpDt:'',
  bondAmount:0,
  dateReceived:'',
  uamId:0,
  uaName:'',
  smId:1,
  statusName:'',
  anticipatedCompleteDate:'',
  isBondAmountChanged:false,
  newBondAmount:0,
  bondAmountChange:0,
  apRp:0,
  comment:'',
  psCreateUserid:'',
  psCreateDatetime: '',
  psUpdateUserid: '',
  psUpdateDatetime: '',
}

export const projectStatusErrorStatus ={
  currDate:false,
  respondBy:false,
  insRqmtProdctNo:false,
  policyNumber:false,
  producerName:false,
  producerNo:false,
  accountName:false,
  insuredName:false,
  underWriterName:false,
  effDate:false,
  prodctExpDt:false,
  bondAmount:false,
  dateReceived:false,
  uamId:false,
  smId:false,
  anticipatedCompleteDate:false,
  isBondAmountChanged:false,
  newBondAmount:false,
  bondAmountChange:false,
  apRP:false,
  comment:false,
  psCreateUserid:false,
  psCreateDatetime: false,
  psUpdateUserid: false,
  psUpdateDatetime: false,
}

export const projectStatusName ={
  id:"Letter Number",
  currentDate:"Mailed Date",
  respondBy:"Respond By",
  insRqmtProdctNo:"Product Number",
  policyNumber:"Bond Number",
  producerName:"Producer Name",
  producerNo:"Producer Number",
  accountName:"Account Name",
  insuredName:"Insured Name",
  underWriterName:"UnderWriter Name",
  effectiveDate:"Effective Date",
  prodctExpDt:"Expiration Date",
  bondAmount:"Bond Amount",
  dateReceived:"Date Received",
  ua:"UA",
  status:"Status",
  anticipatedCompleteDate:"Anticipated Complete Date",
  bondAmountChange:"Bond Amount Change",
  bondAmountChangeValue:"Bond Amount Change Value",
  newBondAmount:"New Bond Amount",
  aPRP:"AP/RP",
  comment:"Comment"
}

export const exportProjectStatusHeader =
  [
    { label: projectStatusName.currentDate, key: 'currDate' },
    { label: projectStatusName.respondBy, key: 'respondBy' },
    { label: projectStatusName.insRqmtProdctNo, key: 'insRqmtProdctNo' },
    { label: projectStatusName.policyNumber, key: 'policyNumber' },
    { label: projectStatusName.producerNo, key: 'producerNo' },
    { label: projectStatusName.producerName, key: 'producerName' },
    { label: projectStatusName.accountName, key: 'accountName' },
    { label: projectStatusName.insuredName, key: 'insuredName' },
    { label: projectStatusName.underWriterName, key: 'underwriterName' },
    { label: projectStatusName.effectiveDate, key: 'effDate' },
    { label: projectStatusName.prodctExpDt, key: 'prodctExpDt' },
    { label: projectStatusName.bondAmount, key: 'bondAmount' },
    { label: projectStatusName.dateReceived, key: 'dateReceived' },
    { label: projectStatusName.anticipatedCompleteDate, key: 'anticipatedCompleteDate'  },
    { label: projectStatusName.bondAmountChange, key: 'isBondAmountChanged' },
    { label: projectStatusName.newBondAmount, key: 'newBondAmount' },
    { label: projectStatusName.bondAmountChange, key: 'bondAmountChange' },
    { label: projectStatusName.aPRP, key: 'apRp' },
    { label: projectStatusName.ua, key: 'uaName' },
    { label: projectStatusName.status, key: 'statusName' },
    //{ label: projectStatusName.comment, key: 'comment' },
  ]



export const projectStatusTableHeader =
  [
    //id
    {
        field: projectStatusProp.id, 
        headerName: projectStatusName.id, 
        sortable: true, 
        minWidth:120,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.equals,
        ),
        renderCell: (params) => 
        (<NavLink to={{pathname:componentConst.addUpdateProjectStatus,
                        projectStatusprop:params.row}}>
                  <Button type="button">{params.row.id}</Button>
        </NavLink>)
   
    },
    //currentDate
    {
        field: projectStatusProp.currentDate, 
        headerName:projectStatusName.currentDate, 
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridDateOperators().filter(
          (operator) => operator.value === filterOperators.is 
                     || operator.value === filterOperators.before 
                     || operator.value === filterOperators.after 
                     || operator.value === filterOperators.isEmpty 
                     || operator.value === filterOperators.isNotEmpty,
        )
    },
    //respondBy
    {
        field: projectStatusProp.respondBy, 
        headerName: projectStatusName.respondBy, 
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridDateOperators().filter(
          (operator) => operator.value === filterOperators.is 
                     || operator.value === filterOperators.before 
                     || operator.value === filterOperators.after 
                     || operator.value === filterOperators.isEmpty 
                     || operator.value === filterOperators.isNotEmpty,
        )
    },
    //dateReceived
    {
      field: projectStatusProp.dateReceived, 
      headerName:projectStatusName.dateReceived,
      //hide:true,
      //hideable:false, 
      sortable: true, 
      minWidth:110, 
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is 
                   || operator.value === filterOperators.before 
                   || operator.value === filterOperators.after 
                   || operator.value === filterOperators.isEmpty 
                   || operator.value === filterOperators.isNotEmpty,
      )
    },
    //insRqmtProdctNo
    {
        field: projectStatusProp.insRqmtProdctNo, 
        headerName: projectStatusName.insRqmtProdctNo, 
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.equals,
        )
    },
    //policyNumber
    {
      field: projectStatusProp.policyNumber, 
      headerName:projectStatusName.policyNumber, 
      sortable: true, 
      minWidth:100, 
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains 
                   || operator.value === filterOperators.equals,
      )
    },
    //producerNo
    {
        field: projectStatusProp.producerNo, 
        headerName: projectStatusName.producerNo, 
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains 
                     || operator.value === filterOperators.equals,
        )
    },
    //producerName
    {
        field: projectStatusProp.producerName, 
        headerName:projectStatusName.producerName, 
        sortable: true, 
        minWidth:250, 
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains 
                     || operator.value === filterOperators.equals,
        )
    },
    //accountName
    {
      field: projectStatusProp.accountName, 
      sortable:true,
      headerName: projectStatusName.accountName, 
      filterable: true,
      minWidth:150, 
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains 
                   || operator.value === filterOperators.equals,
      )
    },
    //insuredName
    {
        field: projectStatusProp.insuredName, 
        headerName:projectStatusName.insuredName, 
        sortable: true, 
        minWidth:150, 
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains 
                     || operator.value === filterOperators.equals,
        )
    },
    //underWriterName
    {
        field: projectStatusProp.underWriterName, 
        headerName:projectStatusName.underWriterName, 
        sortable: true, 
        minWidth:150, 
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains 
                     || operator.value === filterOperators.equals,
        )
    },
    //bondAmount
    {
        field: projectStatusProp.bondAmount, 
        headerName:projectStatusName.bondAmount, 
        filterable: false,
        sortable: true, 
        minWidth:100,
    },
    //effectiveDate
    {
        field: projectStatusProp.effectiveDate, 
        headerName:projectStatusName.effectiveDate,
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridDateOperators().filter(
          (operator) => operator.value === filterOperators.is 
                     || operator.value === filterOperators.before 
                     || operator.value === filterOperators.after 
                     || operator.value === filterOperators.isEmpty 
                     || operator.value === filterOperators.isNotEmpty,
        )
    },
    //prodctExpDt
    {
        field: projectStatusProp.prodctExpDt, 
        headerName:projectStatusName.prodctExpDt, 
        sortable: true, 
        minWidth:100, 
        filterOperators: getGridDateOperators().filter(
          (operator) => operator.value === filterOperators.is 
                     || operator.value === filterOperators.before 
                     || operator.value === filterOperators.after 
                     || operator.value === filterOperators.isEmpty 
                     || operator.value === filterOperators.isNotEmpty,
        )
    },
    //statusName
    {
      field: projectStatusProp.statusName, 
      headerName:projectStatusName.status, 
      sortable: true, 
      minWidth:150, 
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains 
                   || operator.value === filterOperators.equals,
      )
    },
    //uAName
    {
      field: projectStatusProp.uAName, 
      headerName:projectStatusName.ua, 
      sortable: true, 
      minWidth:150, 
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains 
                   || operator.value === filterOperators.equals,
      )
    },
    //anticipatedCompleteDate
    {
      field: projectStatusProp.anticipatedCompleteDate, 
      headerName:projectStatusName.anticipatedCompleteDate,
      sortable: true, 
      minWidth:100, 
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is 
                   || operator.value === filterOperators.before 
                   || operator.value === filterOperators.after 
                   || operator.value === filterOperators.isEmpty 
                   || operator.value === filterOperators.isNotEmpty,
      )
    },
        //comment
        {
          field: projectStatusProp.comment, 
          headerName:projectStatusName.comment, 
          sortable: true, 
          minWidth:150, 
          filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === filterOperators.contains 
                       || operator.value === filterOperators.equals,
          )
        },
    //Delete
    {
      field: 'actions', 
      hide:!(getRoles()),
      hideable:getRoles(),
      headerName: 'Delete', 
      filterable: false,
      renderCell: (params) => {
        return (
          <NavLink to={{pathname:componentConst.projectStatus,
            projectStatusDeleteprop:params.row.id}}>
            <FontAwesomeIcon icon={faTrash} />
        </NavLink>
          
        );
      }
    },
  ]
