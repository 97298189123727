export const updateBondProp = {
    BondNumber: "",
    UserName: "",
    InsuredName: "",
    DateReserved: "",
    DateBooked: "",
    Comment: "",
    AgencyNum: "",
    StatusCode: "",
    ProfitCenterCode: 0,
    DepartmentCode: 0,
    ProductCode: 0,
    isFormUpdated: false,
    BookedAgencyNum: "",
    AgencyVarianceResolved:false,
    InternalComments:""
}
export const bondDataValidateProp = {
    BondNumber: "BondNumber",
    InsuredName: "InsuredName",
    DateReserved: "DateReserved",
    DateBooked: "DateBooked",
    Comment: "Comment",
    UserName: "UserName",
    AgencyNum: "AgencyNum",
    StatusCode: "StatusCode",
    ProfitCenterCode: "ProfitCenterCode",
    DepartmentCode: "DepartmentCode",
    ProductCode: "ProductCode",
    BookedAgencyNum: "BookedAgencyNum"
}

export const updateBondPropByName = {
    bondNumber: "bondNumber",
    insuredName: "insuredName",
    dateReserved: "dateReserved",
    dateBooked: "dateBooked",
    comment: "comment",
    userName: "userName",
    agencyNum: "agencyNum",
    statusCode: "statusCode",
    profitCenterCode: "profitCenterCode",
    departmentCode: "departmentCode",
    productCode: "productCode",
    reset: "reset",
    setProp: "setProp",
    bookedAgencyNum: "bookedAgencyNum",
    agencyVarianceCheck: "agencyVarianceCheck",
    internalComments: "internalComments",
}
export const defaultStatusList = {
    statusCode: 0,
    statusName: ""
}
export const defaultProfitCenterList = {
    profitCenterCode: 0,
    profitCenterName: ""
}
export const defaultDepartmentList = {
    deptCode: 0,
    deptName: ""
}
export const defaultProductList = {
    productCode: 0,
    productName: ""
}
export const agencyListProp = {
    agencynumber: "",
    agencyName: ""
}