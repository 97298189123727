import axios from "axios";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Stack, Autocomplete, TextField, Grid, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from "react";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { headers, apiUrls, apiResponseCode, roleName, responseCodes, toastNoiticationConfig, componentConst } from "../../Commoncode/constant";
import "../reserveBond/ReserveBond.scss";
import ReserveBondModals from "../reserveBondModals/ReserveBondModals";
import "../../style/Style.scss";

const ReserveBond = () => {

  const [showLoader, setShowLoader] = useState(false);
  const [agentItems, setAgentItem] = useState([]);
  const [showAgentGrid, setAgentGrid] = useState(false);
  const [agentNumber, setAgentNumber] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalPopUpScenarioProp, setModalPopUpScenarioProp] = useState({});

  useEffect(() => {
    let loggedInUserDetails = UserDetails();

    if (loggedInUserDetails !== false) {
      if (loggedInUserDetails.userRoleName !== roleName.agent) {
        setAgentGrid(true);
        bindAgentList();
      }
    }
  }, [])

  function bindAgentList() {
    setShowLoader(true);
    axios.get(apiUrls.getAgentList, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setAgentItem([]);
        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          setAgentItem(response.data.agencyList);
        }
      })
        .catch(error => {
            setShowLoader(false);
        });
  }

  const agentChange = (value) => {
    setAgentNumber("");
    if (value != null) {
      setAgentNumber(value.agencyNumber);
    }
  }

  const continueClick = () => {
    if (agentNumber === "") {
      toast.warning(toastNoiticationConfig.agencyRequired, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setShowLoader(true);
    let loggedInUserDetails = UserDetails();
    let setDetails = {
      UserName: loggedInUserDetails.userName,
      AgencyNum: agentNumber
    }

    axios.post(apiUrls.getReserveBondScenarioDetails, setDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          let reserveModalPopUpScenario = {
            reserveBlock: response.data.scenarioDetails.reserveBlock,
            singleReserveBond: response.data.scenarioDetails.isSingleBondReserve,
            maxOpenAllowed: response.data.scenarioDetails.maxOpenAllowed,
            reserveMax: response.data.scenarioDetails.reserveMax,
            currentOpenReserve: response.data.scenarioDetails.getmaxReserveBond,
            agencyNumber: agentNumber
          }
          setModalPopUpScenarioProp(reserveModalPopUpScenario);
          setShowModal(true);
        }
      })
        .catch(error => {
            setShowLoader(false);
        });
  }

  const handleClosePopup = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading" style={{ textAlign: 'center' }}>
        Reserve Bond
      </Typography>
      <div className="body-container" style={{ display: showAgentGrid === true ? "block" : "none", overflow: "hidden" }}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} sx={{ mt: 10, ml: 1 }}>
          <Grid container spacing={1}>
            <Grid item sm={3}></Grid>
            <Grid item sm={4} xs={10}>
              <Stack spacing={2} sx={{ width: 410 }}>
                <Autocomplete
                  id="ddlSelectAgent"
                  size="small"
                  options={agentItems}
                  defaultValue={agentItems[5]}
                  getOptionLabel={(option) => option.agencyName}
                  renderOption={(props, agentItems) => {
                    return (
                      <li {...props} key={agentItems.agencyNumber}>
                        {agentItems.agencyName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} variant="standard" label="Select agent" placeholder="Agency number - Agent name" />
                  )}
                  onChange={(event, value) => agentChange(value)}
                />
              </Stack>
            </Grid>
            <Grid item sm={3} sx={{ mt: 1, ml: 1 }}>
              <Button variant="contained" className="btnStyling" onClick={continueClick}>Continue</Button>
              <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}> <Button variant="contained" className="btnStyling ml1" color="info">Cancel</Button> </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <ReserveBondModals showPopUpflag={showModal} modalPopUpScenario={modalPopUpScenarioProp} closePopup={handleClosePopup}></ReserveBondModals>
    </React.Fragment>
  )
}

export default ReserveBond;