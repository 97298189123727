import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Autocomplete, Button, Typography, TextField } from '@mui/material'
import { useHistory } from "react-router-dom";
import React, { useState, useReducer, useEffect } from "react";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import { removeTime } from "../../Hooks/Validation";
import { headers, apiUrls, apiResponseCode, skipToCheck, toastNoiticationConfig, responseCodes } from "../../Commoncode/constant";
import Box from '@mui/material/Box';
import "../../style/Style.scss";
import { submissionProp, submissionValidateProp, defaultDDL } from "../../Models/SubmissionModels/createSubmission";

const CreateSubmission = (props) => {


    const [underwriterList, setUnderwriterList] = useState([]);
    const [underwriterValue, setUnderwriterValue] = useState(defaultDDL);
    const [regionList, setRegionList] = useState([]);
    const [regionValue, setRegionValue] = useState(defaultDDL);
    const [statusList, setStatusList] = useState([]);
    const [statusValue, setStatusValue] = useState(defaultDDL);
    const [accountList, setAccountList] = useState([]);
    const [accountValue, setAccountValue] = useState(defaultDDL);

    const [showLoader, setShowLoader] = useState(false);
    const [state, Dispatch] = useReducer(Reducer, submissionProp);

    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {

        if (props.location.search) {

            const search = props.location.search.replace(/#/g, "?");
            const params = new URLSearchParams(search);

            var id = params.get('sub');
            if (id !== undefined && id !== null && id !== "") {
                setIsUpdate(true);
                BindDetails(id);
            }
            else {
                BindList();
                Dispatch({ type: submissionValidateProp.Reset });
            }
        }
        else {
            BindList();
            Dispatch({ type: submissionValidateProp.Reset });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    let updateButtonClick = () => {
        var validateMsg = commonSubmitValidations();

        if (validateMsg === "") {
            setShowLoader(true);
            axios.post(apiUrls.subUpdateDetails, state, { headers: headers })
                .then((response) => {
                    setShowLoader(false);
                    if (response.status === 200 && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

                        toast.success(toastNoiticationConfig.recordUpdatedSuccessfully, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        goToPreviousPath();

                    }
                })
                .catch(error => {
                    setShowLoader(false);
                });
        }
        else {
            toast.warning(validateMsg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    let createButtonClick = () => {
        var validateMsg = commonSubmitValidations();

        if (validateMsg === "") {
            setShowLoader(true);
            axios.post(apiUrls.subCreateDetails, state, { headers: headers })
                .then((response) => {
                    setShowLoader(false);
                    if (response.status === 200 && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

                        toast.success(toastNoiticationConfig.recordCreatedSuccessfully, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        goToPreviousPath();

                    }
                })
                .catch(error => {
                    setShowLoader(false);
                });
        }
        else {
            toast.warning(validateMsg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    function commonSubmitValidations() {
        var validateMsg = "";
        for (const item in state) {
            if (item !== skipToCheck.isFormUpdated) {
                if (item === submissionValidateProp.AccountName && state[item].toString().trim() === "") {
                    validateMsg = "Account name is required";
                    break;
                }
                else if (item === submissionValidateProp.BrokerName && state[item].toString().trim() === "") {
                    validateMsg = "Account address is required";
                    break;
                }
                else if (item === submissionValidateProp.AgencyName && state[item].toString().trim() === "") {
                    validateMsg = "Agency/Broker name is required";
                    break;
                }
                else if (item === submissionValidateProp.UnderwriterId && state[item].toString().trim() === "0") {
                    validateMsg = "Underwriter is required";
                    break;
                }
                else if (item === submissionValidateProp.RegionId && state[item].toString().trim() === "0") {
                    validateMsg = "Region is required";
                    break;
                }
                else if (item === submissionValidateProp.AccountId && state[item].toString().trim() === "0") {
                    validateMsg = "Account Type is required";
                    break;
                }
                else if (item === submissionValidateProp.StatusId && state[item].toString().trim() === "0") {
                    validateMsg = "Status is required";
                    break;
                }
                else if (item === submissionValidateProp.Program && state[item].toString().trim() === "") {
                    validateMsg = "Program is required";
                    break;
                }
                else if (item === submissionValidateProp.SubmittedDate && state[item].toString().trim() === "") {
                    validateMsg = "Submitted date is required";
                    break;
                }
                else if (item === submissionValidateProp.SubmittedDate && new Date(state[item].toString().trim()) > new Date()) {
                    validateMsg = "Submitted date must be less than or equal to current date";
                    break;
                }
                else if (item === submissionValidateProp.CurrentSurety && state[item].toString().trim() === "") {
                    validateMsg = "Current surety is required";
                    break;
                }
            }
        }
        return validateMsg;
    }

    function BindList() {
        setShowLoader(true);
        axios.get(apiUrls.subGetMasterList, { headers: headers })
            .then((response) => {
                setShowLoader(false);

                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

                    setUnderwriterList(response.data.uwList);
                    setStatusList(response.data.statusList);
                    setAccountList(response.data.accountTypeList);
                    setRegionList(response.data.regionTypeList);

                }
                else if (response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.RecordNotFound) {
                    toast.warning(toastNoiticationConfig.noRecordFound, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }

    function BindDetails(id) {
        setShowLoader(true);
        axios.post(apiUrls.subDetails, id, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setUnderwriterList(response.data.uwList);
                    setStatusList(response.data.statusList);
                    setAccountList(response.data.accountTypeList);
                    setRegionList(response.data.regionTypeList);

                    setUnderwriterValue(response.data.uwList.find(m => m.key === response.data.underwriterId));
                    setRegionValue(response.data.regionTypeList.find(m => m.key === response.data.regionId));
                    setStatusValue(response.data.statusList.find(m => m.key === response.data.statusId));
                    setAccountValue(response.data.accountTypeList.find(m => m.key === response.data.accountId));
                    Dispatch({ type: submissionValidateProp.SetProp, value: response.data });

                }
                else if (response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.RecordNotFound) {
                    toast.warning(toastNoiticationConfig.noRecordFound, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }

    function Reducer(state, action) {
        switch (action.type) {
            case submissionValidateProp.UnderwriterId:
                return {
                    ...state, UnderwriterId: action.value,
                    isFormUpdated: true
                }
                case submissionValidateProp.RegionId:
                return {
                    ...state, RegionId: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.StatusId:
                return {
                    ...state, StatusId: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.AccountId:
                return {
                    ...state, AccountId: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.SubmittedDate:
                return {
                    ...state, SubmittedDate: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.Comments:
                return {
                    ...state, Comments: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.Program:
                return {
                    ...state, Program: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.CurrentSurety:
                return {
                    ...state, CurrentSurety: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.BrokerName:
                return {
                    ...state, BrokerName: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.AccountName:
                return {
                    ...state, AccountName: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.AgencyName:
                return {
                    ...state, AgencyName: action.value,
                    isFormUpdated: true
                }
            case submissionValidateProp.SetProp:
                return {
                    ...state,
                    LogId: action.value.logId,
                    AccountName: action.value.accountName,
                    AgencyName: action.value.agencyName,
                    BrokerName: action.value.brokerName,
                    Program: action.value.program,
                    Comments: action.value.comments === null ? "" : action.value.comments,
                    SubmittedDate: action.value.submittedDate === null ? "" : removeTime(action.value.submittedDate),
                    UnderwriterId: action.value.underwriterId === null ? 0 : action.value.underwriterId,
                    RegionId: action.value.regionId === null ? 0 : action.value.regionId,
                    StatusId: action.value.statusId === null ? 0 : action.value.statusId,
                    AccountId: action.value.accountId === null ? 0 : action.value.accountId,
                    CurrentSurety: action.value.currentSurety === null ? "" : action.value.currentSurety,
                    isFormUpdated: false
                }
            case submissionValidateProp.Reset:
                return {
                    ...state,
                    LogId: 0,
                    AccountName: "",
                    AgencyName: "",
                    BrokerName: "",
                    UnderwriterId: 0,
                    RegionId: 0,
                    StatusId: 0,
                    AccountId: 0,
                    Program: "",
                    SubmittedDate: "",
                    Comments: "",
                    CurrentSurety: "",
                    isFormUpdated: false
                }
            default:
                return {
                    ...state
                }
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h5" className="pageHeading" style={{ textAlign: 'center' }}>
                {isUpdate ? "Update" : "Create"} Submission
            </Typography>
            <Box className="body-container" component="form" noValidate autoComplete="off">
                <TextField className="col-6" size="small" id="txtAccName" label="Account Name" inputProps={{ maxLength: 250 }} value={state.AccountName} onChange={(e) => { Dispatch({ type: submissionValidateProp.AccountName, value: e.target.value }); }} />
                <TextField className="col-6" size="small" id="txtAccountAddress" label="Account Address" inputProps={{ maxLength: 250 }} value={state.BrokerName} onChange={(e) => { Dispatch({ type: submissionValidateProp.BrokerName, value: e.target.value }); }} />
                <TextField className="col-6" size="small" id="txtAgencyName" label="Agency/Broker Name" inputProps={{ maxLength: 250 }} value={state.AgencyName} onChange={(e) => { Dispatch({ type: submissionValidateProp.AgencyName, value: e.target.value }); }} />

                <Autocomplete className="col-6" id="ddlUnderwriter" size="small" options={underwriterList} value={underwriterValue} getOptionLabel={(option) => option.value}
                    onChange={(event, value) => { Dispatch({ type: submissionValidateProp.UnderwriterId, value: value.key }); setUnderwriterValue({ key: value.key, value: event.target.innerHTML }) }}
                    renderOption={(props, underwriterItems) => {
                        return (
                            <li {...props} key={underwriterItems.key}>
                                {underwriterItems.value}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Underwriter" placeholder="Underwriter" />
                    )} />

                <Autocomplete className="col-6" id="ddlRegion" size="small" options={regionList} value={regionValue} getOptionLabel={(option) => option.value}
                    onChange={(event, value) => { Dispatch({ type: submissionValidateProp.RegionId, value: value.key }); setRegionValue({ key: value.key, value: event.target.innerHTML }) }}
                    renderOption={(props, regionItems) => {
                        return (
                            <li {...props} key={regionItems.key}>
                                {regionItems.value}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Region" placeholder="Region" />
                    )} />
                <Autocomplete className="col-6" id="ddlStatus" size="small" options={statusList} value={statusValue} getOptionLabel={(option) => option.value}
                    onChange={(event, value) => { Dispatch({ type: submissionValidateProp.StatusId, value: value.key }); setStatusValue({ key: value.key, value: event.target.innerHTML }) }}
                    renderOption={(props, statusIdItems) => {
                        return (
                            <li {...props} key={statusIdItems.key}>
                                {statusIdItems.value}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Status" placeholder="Status" />
                    )} />

                <Autocomplete className="col-6" id="ddlAccount" size="small" options={accountList} value={accountValue} getOptionLabel={(option) => option.value}
                    onChange={(event, value) => { Dispatch({ type: submissionValidateProp.AccountId, value: value.key }); setAccountValue({ id: value.key, value: event.target.innerHTML }) }}
                    renderOption={(props, accountIdItems) => {
                        return (
                            <li {...props} key={accountIdItems.key}>
                                {accountIdItems.value}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Account" placeholder="Account" />
                    )} />

                <TextField className="col-6" size="small" id="txtCurrentSurety" label="Current Surety" inputProps={{ maxLength: 250 }} value={state.CurrentSurety} onChange={(e) => { Dispatch({ type: submissionValidateProp.CurrentSurety, value: e.target.value }); }} />

                <TextField className="col-6" size="small" id="txtProgram" label="Program" inputProps={{ maxLength: 250 }} value={state.Program} onChange={(e) => { Dispatch({ type: submissionValidateProp.Program, value: e.target.value }); }} />

                <TextField size="small" value={state.SubmittedDate} id="dateSubmitted" label="Submission Date" type="date" className="col-6" InputLabelProps={{ shrink: true, }}
                    onChange={(event, value) => { Dispatch({ type: submissionValidateProp.SubmittedDate, value: event.target.value }) }} />

                <TextField className="col-12" size="small" id="txtComment" label="Comment" multiline fullWidth minRows={6} inputProps={{ maxLength: 500 }} value={state.Comments} onChange={(e) => { Dispatch({ type: submissionValidateProp.Comments, value: e.target.value }); }} />

                <div className="col-12 text-center">

                    {isUpdate && <Button variant="contained" className="btnStyling" color="success" disabled={!state.isFormUpdated} onClick={updateButtonClick}>Update</Button>}
                    {!isUpdate && <Button variant="contained" className="btnStyling" color="success" disabled={!state.isFormUpdated} onClick={createButtonClick}>Create</Button>}

                    <span>  </span>
                    <Button variant="contained" className="btnStyling" color="info" onClick={goToPreviousPath}>Cancel</Button>
                </div>

            </Box>
            <ToastContainer />
            <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
    )
}

export default CreateSubmission;