import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { filterOperators,componentConst } from "../Commoncode/constant";
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
export const agencyTableHeader =
  [
    {
      field: 'adNumber', headerName: 'Agency', sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
      ,renderCell: (params) => 
      (<NavLink to={{pathname:componentConst.addUpdateAgency,agencyprop:params.row}}><Button type="button">{params.row.adNumber}</Button></NavLink>)
 
    },
    {
      field: 'adName', headerName: 'Agency Name', sortable: true, minWidth: 400, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'adAddress1', headerName: 'Address Line 1', sortable: true,minWidth: 400, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
        field: 'adAddress2', headerName: 'Address Line 2', sortable: true, maxWidth: 400, filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adCity', headerName: 'City', sortable: true, minWidth: 400,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adState', headerName: 'State', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adZip', headerName: 'Zip', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adAgencyemailid', headerName: 'Email', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adReserveBlock', headerName: 'Reserve Block', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adReserveMax', headerName: 'Reserve Max', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'adMaxOpenAllowed', headerName: 'Max Open Allowed', sortable: true,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      // {
      //   field: 'adBondInfo', headerName: 'Bond Info', sortable: true,type: 'boolean',  filterOperators: getGridDateOperators().filter(
      //     (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      //   )
      // },
    {
      field: 'adActive', headerName: 'Status (Active)', sortable: true,  filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'adIsSingleBondReserve', headerName: 'Is Single Bond Reserve', sortable: true,  filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'adCreateUserid', headerName: 'Created By', sortable: true,  filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },

    {
      field: 'adCreateDate', headerName: 'Created On', type: 'date', sortable: true,  filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'adUpdateUserid', headerName: 'Updated By', sortable: true,  filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },

    {
      field: 'adUpdateDate', headerName: 'Updated On', type: 'date', sortable: true,  filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    }
  ]

export const initialAgencyValue = {
  adId:0,
  adNumber:"",
  adName:"",
  adAddress1:"",
  adAddress2:"",
  adCity: "",
  adState: "",
  adZip:"",
  adAgencyemailid:"",
  adReserveBlock:true,
  adReserveMax:'',
  adMaxOpenAllowed:'',
  adBondInfo:false,
  adActive:true,
  adIsSingleBondReserve:false,
  adCreateUserid:"",
  adUpdateUserid:"",
  adCreateDate:"",
}

export const searchBoxProp = {
  search: "search",
  pageNumber: "pageNumber",
  pageSize: "pageSize",
  columnFilterData: "columnFilterData",

  adNumber: "adNumber",
  adName: "adName",
  adAddress1: "adAddress1",
  adAddress2: "adAddress2",
  adCity: "adCity",
  adState: "adState",
  adZip:"adZip",
  adAgencyemailid:"adAgencyemailid",
  adReserveBlock:"adReserveBlock",
  adReserveMax:"adReserveMax",
  adMaxOpenAllowed:"adMaxOpenAllowed",
  adBondInfo:"adBondInfo",
  adActive:"adActive",
  adIsSingleBondReserve:"adIsSingleBondReserve",
  adCreateUserid:"adCreateUserid",
  adUpdateUserid:"adUpdateUserid",
  adCreateDate:"adCreateDate",

  contains: "contains",
  equals: "equals",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  isAnyOf: "isAnyOf",

  setData: "setData",
  setFilteredData: "setFilteredData",
  TotalCount: "TotalCount",
  sortData: "sortData",
  asc: "asc",
  desc: "desc"
}