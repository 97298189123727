import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useReducer, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Button, Typography, Box } from '@mui/material';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { searchBox, searchBoxProp, defaultValueApi, exportInternalUserHeader, exportAgentHeader, agentTableHeader, internalUserTableHeader } from "../../Models/bondThirtyDaysReport";
import { headers, apiUrls, roleName, apiResponseCode, componentConst, toastNoiticationConfig, responseCodes, exportFileName, dataGridDensity, filterOperators } from "../../Commoncode/constant";
import "../../style/Style.scss";
import PrintExportEmailModal from "../Common/PrintExportEmailModal/PrintExportEmailModal";

const BondThirtyDaysReport = () => {

  const [showLoader, setShowLoader] = useState(false);
  const [userRole, setUserRole] = useState(roleName.inquiry);
  const [tableRows, settableRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [searchState, searchDispatch] = useReducer(searchReducer, searchBox);

  const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
  const [exportData, setExportData] = useState(defaultValueApi);
  const [exportAllowed, setExportAllowed] = useState(true);

  useEffect(() => {
    let loggedInUserDetails = UserDetails();
    if (loggedInUserDetails !== false) {
      setUserRole(loggedInUserDetails.userRoleName);
      searchDispatch({ type: searchBoxProp.setData, value: loggedInUserDetails });
    }
  }, [])

  useEffect(() => {
    submitSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  const exportHeaders = userRole === roleName.agent ? exportAgentHeader : exportInternalUserHeader;

  const size = window.innerWidth;

  function searchReducer(searchState, action) {
    switch (action.type) {
      case searchBoxProp.search:
        return {
          ...searchState, Search: action.value,
        }
      case searchBoxProp.pageNumber:
        return {
          ...searchState, PageNumber: action.value,
        }
      case searchBoxProp.pageSize:
        return {
          ...searchState, PageNumber: 0, PageSize: action.value,
        }
      case searchBoxProp.setData:
        return {
          ...searchState,
          UserName: action.value.userName,
          UserRole: action.value.userRoleCode,
          PageSize: 20,
          PageNumber: 0,
          Search: "",
          TotalCount: 0,
          SortData: searchBoxProp.dateReserved,
          AscDesc: searchBoxProp.desc,
          FilterData: [],
          LinkOperator: ""
        }
      case searchBoxProp.setFilteredData:
        return {
          ...searchState,
          UserName: action.value.UserName,
          UserRole: action.value.UserRole,
          PageSize: action.value.PageSize,
          PageNumber: action.value.PageNumber,
          Search: action.value.Search,
          TotalCount: action.value.TotalCount,
          SortData: action.value.SortData,
          AscDesc: action.value.AscDesc,
          FilterData: action.value.FilterData,
          LinkOperator: action.value.LinkOperator
        }
      case searchBoxProp.columnFilterData:
        return {
          ...searchState,
          FilterData: action.value,
          LinkOperator: action.operator
        }
      case searchBoxProp.sortData:
        return {
          ...searchState,
          SortData: action.value[0].field,
          AscDesc: action.value[0].sort,
          PageNumber: 0
        }
      default:
        return {
          ...searchState
        }
    }
  }

  function submitSearch() {
    var isDataAvailable = false;

    if (searchState.UserName !== "" && searchState.UserRole !== "") {
      isDataAvailable = true;
    }

    if (isDataAvailable) {
      setShowLoader(true);
      axios.post(apiUrls.get30DaysReportApiUrl, searchState, { headers: headers })
        .then((response) => {
          setShowLoader(false);
          settableRows([]);
          settableRows(response.data.thirtyDaysDetails.thirtyDaysDtls);
          setRowsCount(response.data.thirtyDaysDetails.totalRecords);
        })
        .catch(error => {
          setShowLoader(false);
        });
    }

  }
  function onFilterChange(filterModel) {
    var filterData = [];
    if(filterModel.items.length >= 2){
      var check = filterModel.items;
      var columnList = []
      for(var item in check){
        if(!columnList.includes(check[item].columnField)){
          
          columnList.push(check[item].columnField)
        }
          else{ 
            if(check[item].value){
              
            toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
              position: toast.POSITION.TOP_RIGHT
            });
            filterModel.items.pop();
            //setFilteredData(filterModel.items);
            return;
            }
          }
        }
    }
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: searchBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator });

  }
  const callFnPageSize = React.useCallback((newPageSize) => {
    searchDispatch({ type: searchBoxProp.pageSize, value: newPageSize });
  }, []);

  const CallPrintExportEmail = () => {
    let exportData = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.get30DaysReportApiUrl, exportData, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setExportData(defaultValueApi);

        if (response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.DataTooLarge) {
          setExportAllowed(false);
          setShowPrintExportEmailModal(true);
        }
        else if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          let totalRecords = [];
          if (response.data.thirtyDaysDetails.thirtyDaysDtls.length > 0) {

            response.data.thirtyDaysDetails.thirtyDaysDtls.map((item, index) => {
              return totalRecords.push({
                bondNo: item.bondNo ? item.bondNo : '',
                agencyNo: item.agencyNo ? '=""' +item.agencyNo+ '""' : '',
                agencyName: item.agencyName ? item.agencyName : '',
                userFullName: item.userFullName ? item.userFullName : '',
                dateReserved: item.dateReserved ? item.dateReserved : '',
                dateBooked: item.dateBooked ? item.dateBooked : '',
                status: item.status ? item.status : '',
                insured: item.insured ? item.insured : '',
                profitCenter: item.profitCenter ? item.profitCenter : '',
                department: item.department ? item.department : '',
                product: item.product ? item.product : '',
                comment: item.comment ? item.comment : '',
                daysReserved: item.daysReserved ? item.daysReserved : ''
              });
            });
            setExportData(totalRecords);
            setExportAllowed(true);
            setShowPrintExportEmailModal(true);
          }
          else
          {
            toast.warning(toastNoiticationConfig.noRecordFound, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }


  const handleClosePrintExportEmailPopup = () => {
    setShowPrintExportEmailModal(false);
  };
  const handleCloseExportPopup = () => {

    toast.success(toastNoiticationConfig.exportSuccessfully, {
      position: toast.POSITION.TOP_RIGHT
    });

    setShowPrintExportEmailModal(false);
  };

  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.email30DaysReport, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.success(toastNoiticationConfig.emailNotSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }

  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: searchBoxProp.sortData, value: prop });
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        Reserved Bonds Over 90 Days
      </Typography>

      <div className="body-container" >
        <Box sx={{ height: 417, overflow: "auto" }} noValidate autoComplete="off">

          <DataGridPro className="muiDataGrid" density={dataGridDensity.compact}
            componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
            components={{ Toolbar: GridToolbar, disableExport: true }}
            disableColumnFilter={false}
            rows={tableRows}
            onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
            rowCount={rowsCount}
            columns={userRole === roleName.agent ? agentTableHeader : internalUserTableHeader}
            rowsPerPageOptions={[20, 50, 100, 500]}
            pagination
            page={searchState.PageNumber}
            pageSize={searchState.PageSize}
            paginationMode="server"
            onPageChange={(data) => {
              searchDispatch({ type: searchBoxProp.pageNumber, value: data });
            }}
            onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
            rowHeight={size >= 1400 ? 50 : 20}
            maxRowHeight={size >= 1400 ? 50 : 20}
            minRowHeight={size >= 1400 ? 50 : 20}
            headerHeight={size >= 1400 ? 50 : 22}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />

        </Box>
      </div>

      <div className="col-md-12">
        <Box textAlign="center">
          <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallPrintExportEmail}>Export/Email</Button>
          <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}>  <Button type="button" variant="contained" color="primary" className="btnStyling">Cancel</Button> </NavLink> &nbsp;
        </Box>
      </div>

      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.reserveBondOverThirtyDays} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportHeaders} closePopup={handleClosePrintExportEmailPopup} closeExportPopup={handleCloseExportPopup} sendEmail={callSendEmail}></PrintExportEmailModal>
    </React.Fragment>
  )
}
export default BondThirtyDaysReport;