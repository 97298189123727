import { commonConst } from "../Commoncode/constant";
import { decryptData } from "../Commoncode/encryptDecrypt";

export default function UserDetails() {
    let userInfo = localStorage.getItem(commonConst.userInfo);
    if (userInfo) {
        return decryptData(userInfo);
    }
    else {
        return false;
    }
}