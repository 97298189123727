import axios from "axios";
import { Typography,Button, Box ,TextField,Select,MenuItem,InputLabel,FormControl,FormHelperText} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory,useLocation } from "react-router-dom";
import React,{ useEffect,useState  } from "react";
import { Grid,Autocomplete } from '@mui/material';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import {initialprojectStatusValue,projectStatusProp,projectStatusName,projectStatusErrorStatus} from "../../Models/ProjectStatus/homeModel"
import { headers, apiUrls,responseCodes,apiResponseCode,componentConst,pSStatus} from "../../Commoncode/constant"
import { intMaxValue } from "../shared/common";
import { removeTime } from "../../Hooks/Validation";
import UserDetails from "../../Hooks/UserDetails";
const AddUpdateProjectStatus = () =>{
    let history = useHistory();
    let location =useLocation();
    const [projectStatusValue,setProjectStatusValue] = useState(initialprojectStatusValue);
    const [showLoader, setShowLoader] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [uaList,setUAList] = useState([]);
    const [psStatus,setPSStatus]= useState([]);
    const [onBlurAPRPStatus,setOnBlurAPRPStatus] = useState(true);
    const [onBlurAmountStatus,setOnBlurAmountStatus] = useState(true);
    const [onBlurNewAmountStatus,setOnBlurNewAmountStatus] = useState(true);
    const [agencyDetails,setAgencyDetails] = useState([]);
    const [errorCheck,setErrorCheck] = useState(false);
    const [errorFieldCheck,setErrorFieldCheck] = useState(projectStatusErrorStatus);
    const [underWriterDetails,setUnderWriterDetails] = useState([]);
    const [pStatus,setPStatus]= useState('');
    const [bAChange,setBAChange]= useState('');
    const [policyCheck,setPolicyCheck] = useState(false);
    const [newBAmount,setNewBAmount] = useState(0);
    const goToPreviousPath = (responseMessage) => {
            history.push(componentConst.projectStatus,responseMessage)
        }
    
    useEffect(()=>{
        if(location.projectStatusprop !== undefined){
            setIsEdit(true);
            getUAList();
            getPSStatusList();
            location.projectStatusprop.currDate = removeTime(location.projectStatusprop.currDate);
            location.projectStatusprop.dateReceived = removeTime(location.projectStatusprop.dateReceived);
            location.projectStatusprop.effDate = removeTime(location.projectStatusprop.effDate);
            location.projectStatusprop.prodctExpDt = removeTime(location.projectStatusprop.prodctExpDt);
            location.projectStatusprop.respondBy = removeTime(location.projectStatusprop.respondBy);
            location.projectStatusprop.anticipatedCompleteDate = removeTime(location.projectStatusprop.anticipatedCompleteDate);
            setProjectStatusValue(location.projectStatusprop);
            if(location.projectStatusprop.smId){
                setPStatus(location.projectStatusprop.smId);
            }

            if(location.projectStatusprop.newBondAmount){
                setNewBAmount(location.projectStatusprop.newBondAmount);
            }
            if(location.projectStatusprop.isBondAmountChanged){
                setBAChange(location.projectStatusprop.isBondAmountChanged);
            }
        }  
        getAgencyDetails();
        getUnderWriterList();     
    },[location.projectStatusprop])

    const getUAList =()=>{
        setShowLoader(true);
        axios.get(apiUrls.getUAList, { headers: headers })
        .then((response) => {
            setShowLoader(false);
            if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest){
                setUAList(response.data.keyAndValueList);
            }
        })
      .catch(error => {
        setShowLoader(false);
      });
    }
    
    const getUnderWriterList =()=>{
        setShowLoader(true);
        axios.get(apiUrls.getUnderWriterListUrl, { headers: headers })
        .then((response) => {
            setShowLoader(false);
            if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest){
                var output = response.data.keyAndValueList;
                setUnderWriterDetails(output);
            }
        })
      .catch(error => {
        setShowLoader(false);
      });
    }

    const getPSStatusList =()=>{
        setShowLoader(true);
         axios.get(apiUrls.getPSStatusList, { headers: headers })
        .then((response) => {
            setShowLoader(false);
            if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest){
                setPSStatus(response.data.keyAndValueList);
            }
        })
      .catch(error => {
        setShowLoader(false);
      });
    }

    const statusCheck =(statuCheckList)=>{
        return statuCheckList.includes((psStatus.length>0&&pStatus!=="" )?psStatus.filter(e=>e.key === pStatus)[0].value:"")
    }

    const onClickErrorStatusUpdate=(e)=>{
        const {name} = e.target;
        if(name === projectStatusProp.ua){
            if(!statusCheck([pSStatus.letterSent])){
                setErrorFieldCheck({
                    ...errorFieldCheck,
                    [name]:true
                });
            }
            return;
        }
        if(name === projectStatusProp.dateReceived){
            if(!statusCheck([pSStatus.letterResent,pSStatus.letterSent])){
                setErrorFieldCheck({
                    ...errorFieldCheck,
                    [name]:true
                });
            }
            return;
        }
        setErrorFieldCheck({
            ...errorFieldCheck,
            [name]:true
        });
    }

    const handleChange =(e)=>{
        const {name,value} = e.target;
         if(name === projectStatusProp.bondAmountChangeValue
            || name ===projectStatusProp.newBondAmount
            || name === projectStatusProp.aPRP ){
             const validated = (/^(-{0,1}?)(\d{0,8})(\.[0-9]{0,2})?$/).test(value);
             if (!validated) {
                 return;
             }
         }
         if(name === projectStatusProp.bondAmount){
             const validated = (/^(\d{0,8})(\.[0-9]{0,2})?$/).test(value);
             if (!validated) {
                 return;
             }
         }
         if(name === projectStatusProp.newBondAmount){
            setNewBAmount(value);
            setProjectStatusValue({
                ...projectStatusValue,
                [name]:value,
                [projectStatusProp.bondAmountChangeValue]:''+(value - projectStatusValue.bondAmount)
            })
            return;
         }
         
        if(name === projectStatusProp.status){
            setPStatus(value);
        }

        if(name === projectStatusProp.policyNumber){
            if(Number.isNaN(+value)){
                return;
            }
            if(value.length === 10){
                setPolicyCheck(false);    
            }else{
                setPolicyCheck(true);
            }
            
        }

        if(name === projectStatusProp.bondAmountChange){
            setBAChange(value);
        }

        if(name === projectStatusProp.underWriterId){
            setProjectStatusValue({
                ...projectStatusValue,
                [name]:value,
                [projectStatusProp.underWriterName]:underWriterDetails.filter(e=>e.key === value)[0].value
            });
            return;
        }

        setProjectStatusValue({
            ...projectStatusValue,
            [name]:value
        });
    }

    const formatAmount =(val)=>{
        return Number((''+val).replaceAll(',','')).toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})
    }

    const insertProjectStatusDetails =()=>{
        
        if((projectStatusValue.policyNumber ===''
           ||projectStatusValue.policyNumber.length !== 10
           ||projectStatusValue.currDate ===''
           ||projectStatusValue.respondBy ===''
           ||projectStatusValue.insRqmtProdctNo ===''
           ||projectStatusValue.insRqmtProdctNo ===0
           ||projectStatusValue.producerName ===''
           ||projectStatusValue.producerNo ===''
           ||projectStatusValue.accountName ===''
           ||projectStatusValue.insuredName ===''
           ||projectStatusValue.underwriterId ===''
           ||projectStatusValue.effDate ===''
           ||projectStatusValue.prodctExpDt ===''
           ||projectStatusValue.bondAmount ==='') && !isEdit
           ){
            setErrorCheck(true);
            return;
        }
        if(((projectStatusValue.policyNumber ===''
        ||projectStatusValue.policyNumber.length !== 10
        ||projectStatusValue.currDate ===''
        ||projectStatusValue.respondBy ===''
        ||projectStatusValue.insRqmtProdctNo ===''
        ||projectStatusValue.insRqmtProdctNo ===0
        ||projectStatusValue.producerName ===''
        ||projectStatusValue.producerNo ===''
        ||projectStatusValue.accountName ===''
        ||projectStatusValue.insuredName ===''
        ||projectStatusValue.underwriterId ===''
        ||projectStatusValue.effDate ===''
        ||projectStatusValue.prodctExpDt ===''
        ||projectStatusValue.bondAmount ===''
        ||(projectStatusValue.dateReceived ==='' && !statusCheck([pSStatus.letterResent,pSStatus.letterSent]))
        ||((projectStatusValue.uamId ===''
        ||projectStatusValue.uamId ===0 || projectStatusValue.uamId ===null) && !statusCheck([pSStatus.letterSent]))
        ||projectStatusValue.smId ===''
        ||projectStatusValue.smId ===0
        //||projectStatusValue.apRp ===''
        //||projectStatusValue.apRp ===null
        ||projectStatusValue.bondAmountChange ===''
        ||(statusCheck([pSStatus.projectOpen,pSStatus.projectClosed]) && projectStatusValue.anticipatedCompleteDate ==='') ) 
        ||(bAChange && (projectStatusValue.newBondAmount ==='' ||projectStatusValue.newBondAmount ===0)))
        && isEdit
        ){
         setErrorCheck(true);
         return;
     }
        setShowLoader(true);
        projectStatusValue.apRp =projectStatusValue.apRp ===''?0:projectStatusValue.apRp; 
        var user = UserDetails();
        if(user){
            if(isEdit){
                projectStatusValue.psUpdateUserid = user.userName;
                var projectStatusAdd = projectStatusValue
            }else{
                projectStatusValue.psCreateUserid = user.userName;
                var projectStatusAdd =[];
                projectStatusAdd.push(projectStatusValue);
            }
        }
        
        var url = isEdit?apiUrls.updateProjectStatusDetails:apiUrls.insertProjectStatusDetails;
        axios.post(url, projectStatusAdd,{ headers: headers })
        .then((response) => {
            setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessage.length > 0 && response.data.responseCode === apiResponseCode.SuccessRequest) 
        {
            goToPreviousPath(response.data.responseMessage);
        }else{
            
            toast.error(response.data.responseMessage.responseMessage, {
                position: toast.POSITION.TOP_RIGHT});
        }
      })
        .catch(error => {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT});
          setShowLoader(false);
        });
    }

    const getAgencyDetails =() =>{
        var search = {
          "PageSize": intMaxValue,
          "PageNumber": 0,
          "Search": "",
          "TotalCount": 0,
          "SortData": "adNumber",
          "AscDesc": "desc",
          "FilterData": [
              {
                  "ColumnField": "adActive",
                  "OperatorValue": "equals",
                  "Value": "true"
              }
          ],
          "LinkOperator": "and"
       }
       setShowLoader(true);
        axios.post(apiUrls.getAgencyDetails,search, { headers: headers })
            .then((response) => {
                setShowLoader(false);
            if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
            {
              var result = response.data.agencyDetail;
              if(result.length>0){
                result = result.map(({adId,adNumber,adName})=>({adId,adNumber,adName}));
                setAgencyDetails(result);
              }
            }
          })
            .catch(error => {
              setShowLoader(false);
            });
    }
    
    return (
            <React.Fragment>
                <Typography variant="h5" className="pageHeading">
                    {isEdit===true?"Update Project Status":"Add Project Status"}
                </Typography>
                <Box className="body-container" component="form" noValidate autoComplete="off">
                    <Grid container spacing={1}>
                        
                        {isEdit &&
                        <Grid item xs={6}>
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.dateReceived} 
                                       id={projectStatusProp.dateReceived} 
                                       label={projectStatusName.dateReceived}
                                       name ={projectStatusProp.dateReceived} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onChange={handleChange} 
                                       onClick={onClickErrorStatusUpdate}
                                       error ={((errorCheck || errorFieldCheck.dateReceived) && projectStatusValue.dateReceived ==='' && !statusCheck([pSStatus.letterResent,pSStatus.letterSent]))}
                                       helperText ={(errorCheck || errorFieldCheck.dateReceived) && projectStatusValue.dateReceived==='' && !statusCheck([pSStatus.letterResent,pSStatus.letterSent])?projectStatusName.dateReceived+' is required':''}
                                       required = {!statusCheck([pSStatus.letterResent,pSStatus.letterSent])}
                                        />
                        </Grid>
                        }
                        {isEdit &&
                            <Grid item xs={6}>
                                <FormControl required ={!statusCheck([pSStatus.letterSent])}
                                             variant="outlined" 
                                             style={{ width: '-webkit-fill-available' }}
                                             error ={(errorCheck || errorFieldCheck.uamId) && (projectStatusValue.uamId ==='' ||projectStatusValue.uamId ===0 ||projectStatusValue.uamId ==null) && !statusCheck([pSStatus.letterSent])}
                                >
                                <InputLabel>{projectStatusName.ua}</InputLabel>
                                    <Select
                                        labelId={projectStatusProp.ua}
                                        name={projectStatusProp.ua}
                                        size="small"
                                        id={projectStatusProp.ua}
                                        value={projectStatusValue.uamId ===0 ||projectStatusValue.uamId ===null ||projectStatusValue.uamId ===''?'':projectStatusValue.uamId}
                                        label={projectStatusName.ua}
                                        onChange={handleChange}
                                        onClick={onClickErrorStatusUpdate}
                                    >
                                        {uaList.map((item)=>(
                                            <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                    <FormHelperText>{(errorCheck ||errorFieldCheck.uamId) && (projectStatusValue.uamId ==='' ||projectStatusValue.uamId ===0 ||projectStatusValue.uamId ==null) && !statusCheck(["Letter Sent"])?projectStatusName.ua+' is required':''}</FormHelperText>
                                </FormControl>
                                    
                            </Grid>
                        }
                        {isEdit &&
                            <Grid item xs={6}>
                                <FormControl 
                                    required 
                                    variant="outlined" 
                                    style={{ width: '-webkit-fill-available' }}
                                    error ={(errorCheck || errorFieldCheck.smId) && (projectStatusValue.smId ==='' ||projectStatusValue.smId ===0 ||projectStatusValue.smId ===null)}
                                >
                                <InputLabel>{projectStatusName.status}</InputLabel>
                                    <Select
                                        labelId={projectStatusProp.status}
                                        name={projectStatusProp.status}
                                        size="small"
                                        id={projectStatusProp.status}
                                        value={projectStatusValue.smId ===0 ||projectStatusValue.smId ===null?'':projectStatusValue.smId}
                                        label={projectStatusName.status}
                                        onChange={handleChange}
                                        onClick={onClickErrorStatusUpdate}
                                    >
                                        {
                                            psStatus.map((item)=>(
                                                <MenuItem value={item.key} key ={item.key}>
                                                    {item.value}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                      <FormHelperText>{(errorCheck ||errorFieldCheck.smId) && (projectStatusValue.smId ==='' ||projectStatusValue.smId ===0 ||projectStatusValue.smId ===null)?projectStatusName.status+' is required':''}</FormHelperText>
                                </FormControl>
                                    
                            </Grid>
                        }
                        {isEdit && statusCheck(["Project Open","Project Closed"])&&
                        <Grid item xs={6}>
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.anticipatedCompleteDate}
                                       id={projectStatusProp.anticipatedCompleteDate} 
                                       label={projectStatusName.anticipatedCompleteDate}
                                       name ={projectStatusProp.anticipatedCompleteDate} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onChange={handleChange} 
                                       onClick={onClickErrorStatusUpdate}
                                       error ={(errorCheck || errorFieldCheck.anticipatedCompleteDate) && (statusCheck(["Project Open","Project Closed"]) && projectStatusValue.anticipatedCompleteDate ==='')}
                                       helperText ={(errorCheck ||errorFieldCheck.anticipatedCompleteDate) && (statusCheck(["Project Open","Project Closed"]) && projectStatusValue.anticipatedCompleteDate ==='')?projectStatusName.anticipatedCompleteDate+' is required':''}
                                       required
                                        />
                        </Grid>
                        }
        
                        {isEdit &&
                            <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{width:'-webkit-fill-available'}}
                                       value={onBlurAPRPStatus && projectStatusValue.apRp?formatAmount(projectStatusValue.apRp):projectStatusValue.apRp} 
                                       onBlur={()=> setOnBlurAPRPStatus(true)}
                                       onFocus={()=> setOnBlurAPRPStatus(false)}
                                       id={projectStatusProp.aPRP} 
                                       label={projectStatusName.aPRP}
                                       name ={projectStatusProp.aPRP} 
                                       onChange={handleChange} 
                                       //onClick={onClickErrorStatusUpdate}
                                       //error ={(errorCheck || errorFieldCheck.apRP) && (projectStatusValue.apRp ==='' || projectStatusValue.apRp ===null)}
                                       //helperText ={(errorCheck ||errorFieldCheck.apRP) && (projectStatusValue.apRp ==='' || projectStatusValue.apRp ===null)?projectStatusName.aPRP+' is required':''}
                                       //required
                                        />
                            </Grid>
                        }

                        <Grid item xs={6}>
                                <TextField 
                                style={{width:'-webkit-fill-available'}}
                                 size="small"  
                                 name={projectStatusProp.policyNumber}
                                 id={projectStatusProp.policyNumber} 
                                 label={projectStatusName.policyNumber} 
                                 inputProps={{ minLength: 10,maxLength: 10 }}
                                 InputProps={{readOnly: isEdit,}}
                                 onChange={handleChange}
                                 value={projectStatusValue.policyNumber}
                                 onClick={onClickErrorStatusUpdate}
                                 error ={((errorCheck || errorFieldCheck.policyNumber) && projectStatusValue.policyNumber ==='')||policyCheck}
                                 helperText ={((errorCheck || errorFieldCheck.policyNumber) && projectStatusValue.policyNumber==='')||policyCheck ?policyCheck?projectStatusName.policyNumber+' Should be atleast have 10 digit.':projectStatusName.policyNumber+' is required':''}
                                 required/>
                        </Grid>

                        <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.currDate} 
                                       id={projectStatusProp.currentDate} 
                                       label={projectStatusName.currentDate}
                                       name ={projectStatusProp.currentDate} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onChange={handleChange} 
                                       onClick={onClickErrorStatusUpdate}
                                       error ={(errorCheck || errorFieldCheck.currDate) && projectStatusValue.currDate ===''}
                                       helperText ={(errorCheck ||errorFieldCheck.currDate) && projectStatusValue.currDate ===''?projectStatusName.currentDate+' is required':''}
                                       required
                                        />
                        </Grid>

                        <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.respondBy} 
                                       id={projectStatusProp.respondBy} 
                                       label={projectStatusName.respondBy}
                                       name ={projectStatusProp.respondBy} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onClick={onClickErrorStatusUpdate}
                                       onChange={handleChange} 
                                       error ={(errorCheck || errorFieldCheck.respondBy) && projectStatusValue.respondBy ===''}
                                       helperText ={(errorCheck || errorFieldCheck.respondBy) && projectStatusValue.respondBy ===''?projectStatusProp.respondBy+' is required':''}
                                       required
                                        />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                             style={{width:'-webkit-fill-available'}}
                             name={projectStatusProp.insRqmtProdctNo}
                             size="small"  
                             id={projectStatusProp.insRqmtProdctNo} 
                             label={projectStatusName.insRqmtProdctNo}
                             inputProps={{maxLength: 10 }} 
                             onChange={handleChange}
                             value={projectStatusValue.insRqmtProdctNo}
                             onClick={onClickErrorStatusUpdate}
                             error ={(errorCheck || errorFieldCheck.insRqmtProdctNo) && (projectStatusValue.insRqmtProdctNo ===''||projectStatusValue.insRqmtProdctNo ===0)}
                             helperText ={(errorCheck || errorFieldCheck.insRqmtProdctNo) &&(projectStatusValue.insRqmtProdctNo ===''||projectStatusValue.insRqmtProdctNo ===0)?projectStatusName.insRqmtProdctNo+' is required':''}    
                             required/>
                        </Grid>

                        <Grid item xs={6}>
                                    <Autocomplete
                                        disablePortal
                                        id={projectStatusProp.producerName}
                                        name ={projectStatusProp.producerName}
                                        required
                                        size="small"
                                        options={agencyDetails}
                                        //isOptionEqualToValue={(option)=>(option.adName === projectStatusValue.producerName)}
                                        value={{adName:projectStatusValue.producerName,adId:projectStatusValue.adId,adNumber:projectStatusValue.producerNo}}
                                        getOptionLabel={(option) => option.adName}
                                        renderOption={(props, agencyDetail) => {
                                            return (
                                              <li {...props} key={agencyDetail.adNumber}>
                                                {agencyDetail.adName}
                                              </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField size="small" {...params} 
                                                       variant="outlined" 
                                                       label={projectStatusName.producerName} 
                                                       placeholder={projectStatusName.producerName}  
                                                       
                                                       style={{ width: '-webkit-fill-available' }} 
                                                       error ={(errorCheck ||errorFieldCheck.producerName) && projectStatusValue.producerName ===''}
                                                       helperText ={(errorCheck ||errorFieldCheck.producerName) && projectStatusValue.producerName ===''?projectStatusName.producerName+' is required':''}
                                                       required  
                                        />
                                          )}
                                        onChange={(event,value)=>{
                                            setProjectStatusValue({
                                                ...projectStatusValue,
                                                [projectStatusProp.producerName]:value !=null?value.adName:"",
                                                [projectStatusProp.producerNo]:value !=null?value.adNumber:"",
                                                [projectStatusProp.producerId]:value !=null?value.adId:""
                                            })}
                                        }
                                        
                                        />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                            name={projectStatusProp.producerNo}
                            size="small"  
                            id={projectStatusProp.producerNo} 
                            label={projectStatusName.producerNo}
                            value={projectStatusValue.producerNo}
                            InputProps={{readOnly: true,}}
                            onClick={onClickErrorStatusUpdate}
                            error ={(errorCheck ||errorFieldCheck.producerNo) && projectStatusValue.producerNo ===''}
                            helperText ={(errorCheck ||errorFieldCheck.producerNo) && projectStatusValue.producerNo ===''?projectStatusName.producerNo+' is required':''} 
                            onChange={handleChange}
                            required 
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                            name={projectStatusProp.accountName}
                            size="small"  
                            id={projectStatusProp.accountName} 
                            label={projectStatusName.accountName}
                            value={projectStatusValue.accountName}
                            onChange={handleChange}
                            error ={(errorCheck ||errorFieldCheck.accountName) && projectStatusValue.accountName ===''}
                            helperText ={(errorCheck ||errorFieldCheck.accountName) && projectStatusValue.accountName ===''?projectStatusName.accountName+' is required':''} 
                            onClick={onClickErrorStatusUpdate}
                            required 
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             size="small"  
                             id={projectStatusProp.insuredName} 
                             label={projectStatusName.insuredName} 
                             name={projectStatusProp.insuredName} 
                             value={projectStatusValue.insuredName}
                             onChange={handleChange} 
                             error ={(errorCheck ||errorFieldCheck.insuredName) && projectStatusValue.insuredName ===''}
                             helperText ={(errorCheck ||errorFieldCheck.insuredName) && projectStatusValue.insuredName ===''?projectStatusName.insuredName+' is required':''} 
                             onClick={onClickErrorStatusUpdate}
                             required 
                             />
                        </Grid>

                        <Grid item xs={6}>
                        <FormControl required 
                                     variant="outlined" 
                                     style={{ width: '-webkit-fill-available' }}
                                     error ={(errorCheck ||errorFieldCheck.underWriterName) && projectStatusValue.underwriterName ===''}
                        >
                                <InputLabel>{projectStatusName.underWriterName}</InputLabel>
                                    <Select
                                        labelId={projectStatusProp.underWriterName}
                                        name={projectStatusProp.underWriterId}
                                        size="small"
                                        id={projectStatusProp.underWriterName}
                                        value={projectStatusValue.underwriterId ===0?'':projectStatusValue.underwriterId}
                                        label={projectStatusName.underWriterName}
                                        onChange={handleChange}
                                        onClick={onClickErrorStatusUpdate}
                                    >
                                        {underWriterDetails.map((item)=>(
                                            <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                    <FormHelperText>{(errorCheck ||errorFieldCheck.underWriterName) && projectStatusValue.underwriterName ===''?projectStatusName.underWriterName+' is required':''}</FormHelperText>
                                </FormControl>
                        </Grid>

                        <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.effDate} 
                                       id={projectStatusProp.effectiveDate} 
                                       label={projectStatusName.effectiveDate}
                                       name ={projectStatusProp.effectiveDate} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onClick={onClickErrorStatusUpdate}
                                       onChange={handleChange} 
                                       error ={(errorCheck ||errorFieldCheck.effDate) && projectStatusValue.effDate ===''}
                                       helperText ={(errorCheck ||errorFieldCheck.effDate) && projectStatusValue.effDate ===''?projectStatusName.effectiveDate+' is required':''} 
                                       required 
                                        />
                        </Grid>

                        <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{ width: '-webkit-fill-available' }}
                                       value={projectStatusValue.prodctExpDt} 
                                       id={projectStatusProp.prodctExpDt} 
                                       label={projectStatusName.prodctExpDt}
                                       name ={projectStatusProp.prodctExpDt} 
                                       type="date" 
                                       InputLabelProps={{ shrink: true, }}
                                       onClick={onClickErrorStatusUpdate}
                                       onChange={handleChange} 
                                       error ={(errorCheck ||errorFieldCheck.prodctExpDt) && projectStatusValue.prodctExpDt ===''}
                                       helperText ={(errorCheck ||errorFieldCheck.prodctExpDt) && projectStatusValue.prodctExpDt ===''?projectStatusName.prodctExpDt+' is required':''} 
                                       required 
                                        />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                            style={{width:'-webkit-fill-available'}}
                             name={projectStatusProp.bondAmount}
                             size="small" 
                             //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                             id={projectStatusProp.bondAmount} 
                             label={projectStatusName.bondAmount} 
                            value={onBlurAmountStatus &&projectStatusValue.bondAmount ?formatAmount(projectStatusValue.bondAmount):projectStatusValue.bondAmount}
                            onBlur={()=>setOnBlurAmountStatus(true)}
                            onFocus={()=>setOnBlurAmountStatus(false)}
                            error ={(errorCheck ||errorFieldCheck.bondAmount) && (projectStatusValue.bondAmount ===''  || projectStatusValue.bondAmount ===0)}
                            helperText ={(errorCheck ||errorFieldCheck.bondAmount) && (projectStatusValue.bondAmount ==='' || projectStatusValue.bondAmount ===0)?projectStatusName.bondAmount+' is required':''}
                            onClick={onClickErrorStatusUpdate}
                            onChange={handleChange}
                            required
                             />
                        </Grid>

                        {isEdit &&
                            <Grid item xs={6}>
                                <FormControl required variant="outlined" style={{ width: '-webkit-fill-available' }}>
                                <InputLabel>{projectStatusName.bondAmountChange}</InputLabel>
                                    <Select
                                        labelId={projectStatusProp.bondAmountChange}
                                        name={projectStatusProp.bondAmountChange}
                                        size="small"
                                        id={projectStatusProp.bondAmountChange}
                                        value={projectStatusValue.isBondAmountChanged}
                                        label={projectStatusName.bondAmountChange}
                                        onClick={onClickErrorStatusUpdate}
                                        error ={(errorCheck ||errorFieldCheck.bondAmountChange) && projectStatusValue.bondAmountChange ===''}
                                        required 
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{(errorCheck ||errorFieldCheck.bondAmountChange) && projectStatusValue.bondAmountChange ===''?projectStatusName.bondAmountChange+' is required':''}</FormHelperText>
                                </FormControl>
                                    
                            </Grid>
                        }

                        {isEdit && bAChange &&
                            <Grid item xs={6}>    
                            <TextField size="small" 
                                       value={onBlurNewAmountStatus &&projectStatusValue.newBondAmount?formatAmount(projectStatusValue.newBondAmount):projectStatusValue.newBondAmount} 
                                       id={projectStatusProp.newBondAmount} 
                                       label={projectStatusName.newBondAmount}
                                       name ={projectStatusProp.newBondAmount} 
                                       onBlur={()=>setOnBlurNewAmountStatus(true)}
                                       onFocus={()=>setOnBlurNewAmountStatus(false)}
                                       style={{width:'-webkit-fill-available'}}
                                       onChange={handleChange} 
                                       onClick={onClickErrorStatusUpdate}
                                       error ={((errorCheck ||errorFieldCheck.newBondAmount) && bAChange) && (projectStatusValue.newBondAmount ===''||projectStatusValue.newBondAmount ===0||projectStatusValue.newBondAmount ===null )}
                                       helperText ={((errorCheck ||errorFieldCheck.newBondAmount) && bAChange) && (projectStatusValue.newBondAmount ==='' ||projectStatusValue.newBondAmount ===0||projectStatusValue.newBondAmount ===null)?projectStatusName.newBondAmount+' is required':''} 
                                        required 
                                        />
                            </Grid>
                        }

                        {isEdit && bAChange 
                         && newBAmount !==0  &&
                            <Grid item xs={6}>    
                            <TextField size="small" 
                                       style={{width:'-webkit-fill-available'}}
                                       value={formatAmount(''+(newBAmount - projectStatusValue.bondAmount))}
                                       disabled 
                                       id={projectStatusProp.bondAmountChangeValue} 
                                       label={projectStatusName.bondAmountChangeValue}
                                       name ={projectStatusProp.bondAmountChangeValue} 
                                       
                                       //onChange={handleChange} 
                                        />
                            </Grid>
                         }

                        <Grid item xs={12}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             size="small"  
                             id={projectStatusProp.comment} 
                             label={projectStatusName.comment} 
                             name={projectStatusProp.comment} 
                             value={projectStatusValue.comment}
                             onChange={handleChange} 
                             multiline 
                             fullWidth 
                             minRows={6} 
                             inputProps={{ maxLength: 500 }}
                             //error ={(errorCheck ||errorFieldCheck.comment) && projectStatusValue.comment ===''}
                             //helperText ={(errorCheck ||errorFieldCheck.comment) && projectStatusValue.comment ===''?projectStatusName.comment+' is required':''} 
                             onClick={onClickErrorStatusUpdate}
                             //required 
                             />
                        </Grid>

                        <Grid item xs={12}>
                            <div className="text-center">
                                <Button 
                                 variant="contained" 
                                 className="btnStyling" 
                                 color="success" 
                                 disabled={false} 
                                 onClick={insertProjectStatusDetails}
                                 >
                                    {isEdit===true?"Update":"Add"}</Button>
                                <span>  </span>
                                <Button variant="contained" className="btnStyling" color="info" onClick={()=>goToPreviousPath("")}>Cancel</Button> 
                            </div>
                        </Grid>
                        
                    </Grid>
                </Box>
                <ToastContainer></ToastContainer>
                <LoaderComponent show={showLoader}></LoaderComponent>
            </React.Fragment>
            )
}
export default AddUpdateProjectStatus;