import React,{useState  } from "react";
import { Typography,Button, Box } from '@mui/material';
import LoaderComponent from "../../Common/Loader/LoaderComponent";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@mui/material';
import {validateDate} from "../../../Commoncode/dateUtility";
import {downloadCSVFile,csvFileToArray,arrayToCSVFile} from "../../../Commoncode/fileUtility";
import {mimeType,fileInputType,bondUploadFile,agencyDetails,headers, apiUrls,responseCodes,apiResponseCode,toastNoiticationConfig} from "../../../Commoncode/constant";
import { intMaxValue } from "../../shared/common";
import axios from "axios";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BondUploadDashBoard = () =>{ 
  const columns = [
    {
      bond_Number:"",
      agency_Code:"",
      bond_Reserve_Date:"",
      user_Id:"",
      error:""
    }
  ];
  const csvColumnsName = "bond_Number,agency_Code,bond_Reserve_Date,user_Id";
  
  const [showLoader, setShowLoader] = useState(false);
  const [array, setArray] = useState([]);
  const [errorList, setErrorList] = useState([columns]);
  const [validateButton,setValidateButton]= useState(true);
  const [submitButton,setSubmitButton]= useState(true);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if(file){
    reader.onload = function (event) {
      const { result } = event.target;
      if(result.length>0){
        if(result.split('\r\n')[0] !== csvColumnsName){
          toast.warning(toastNoiticationConfig.bondUploadHeaderCheckMessage , {position: toast.POSITION.TOP_RIGHT});
          //resetValue();
          return;
        }
      var outputArray =csvFileToArray(result);
      var validateFlag =validateData(outputArray);
      if(!validateFlag){
        setValidateButton(false);
        setArray(outputArray);
      }

      //validateBondDetils(outputArray);
      }
    }
   reader.readAsText(file);
    }
  };
  const resetValue =({currentTarget})=>{
    currentTarget.value = '';
    setErrorList(columns); 
    setArray(columns);
    setSubmitButton(true);
    setValidateButton(true);
  }
  const validateBondDetils = inputArray =>{
    setShowLoader(true);
    var bondNumberValidate = JSON.stringify(inputArray);
    axios.post(apiUrls.validateBondDetails,bondNumberValidate, { headers: headers })
        .then((response) => {
          if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
            {
              if(response.data.bondNumberValidates.length>0)
                setErrorList(response.data.bondNumberValidates);
              else
                {
                  setErrorList(columns); 
                  setSubmitButton(false);
                }
                setShowLoader(false);
            }
        }).catch(error => {
          setShowLoader(false);
        });
  }

  const submitBondDetils = inputArray =>{
    setShowLoader(true);
    var submitBondDetails = JSON.stringify(inputArray);
    axios.post(apiUrls.submitBondDetails,submitBondDetails, { headers: headers })
        .then((response) => {
          if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
            {
              setShowLoader(false);
              toast.success(toastNoiticationConfig.recordCreatedSuccessfully , {position: toast.POSITION.TOP_RIGHT});
              resetValue();
            }
        }).catch(error => {
          setShowLoader(false);
        });
  }

  const validateData = inputArray =>{
    setShowLoader(true);
    for(var x in inputArray){
      var error ="";
      if(checkFormatForBondNumber(inputArray[x].bond_Number)){
        error = "Invalid Bond Number";
      }
      if(!validateDate(inputArray[x].bond_Reserve_Date)){
        if(error){
          error = error+", Invalid Date" 
        }else{
          error = "Invalid Date"; 
        }
      }
      if(checkDuplicateBondNumber(inputArray,inputArray[x].bond_Number)){
        if(error){
          error = error+", Duplicate bond number" 
        }else{
          error = "Duplicate bond number"; 
        } 
      }
      if(inputArray[x].agency_Code.length !==5 || Number.isNaN(inputArray[x].agency_Code)){
        if(error){
          error = error+", Invalid Agency Code" 
        }else{
          error = "Invalid Agency Code"; 
        }
      }
      inputArray[x].error = error;
    }
    var outputArray = inputArray.filter(x=>x.error !=="");
    setErrorList(outputArray);
    setShowLoader(false);
    return outputArray.length>0;
  }

  const checkFormatForBondNumber = inputString =>{
    var validBondNumberRegex = /\d{3}-\d{6}-\d{1}/;
    return !validBondNumberRegex.test(inputString);
  }

  const checkDuplicateBondNumber =(inputarray,inputString) =>{
    var result = inputarray.filter(x=>x.bond_Number === inputString);
    return (result.length>1)
  }
  const getAgencyDetails =() =>{
    var search = {
      "PageSize": intMaxValue,
      "PageNumber": 0,
      "Search": "",
      "TotalCount": 0,
      "SortData": "adNumber",
      "AscDesc": "desc",
      "FilterData": [
          {
              "ColumnField": "adActive",
              "OperatorValue": "equals",
              "Value": "true"
          }
      ],
      "LinkOperator": "and"
   }
   setShowLoader(true);
    axios.post(apiUrls.getAgencyDetails,search, { headers: headers })
        .then((response) => {
            setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
        {
          var result = response.data.agencyDetail;
          if(result.length>0){
            result = result.map(x=>[x.adNumber,x.adName])
            var headerString = '"Agency Code","Agency Name"\n';
            var csv =arrayToCSVFile(result,headerString);
         downloadCSVFile(csv,agencyDetails.fileName,mimeType.csv,fileInputType.textCSV);
          }

        }
      })
        .catch(error => {
          setShowLoader(false);
        });
  }
  const tableRows = errorList.map((element) => {
    return (
      <TableRow
      key={element.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {element.bond_Number}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.agency_Code}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.bond_Reserve_Date}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.user_Id}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.error}
          </TableCell>
        </TableRow>
    );
});
  return (
        <React.Fragment>
          <Typography variant="h5" className="pageHeading">
            Bond Upload
          </Typography>
          <Box className="body-container">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450}} size="small"  aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                          <TableCell style={{fontWeight: 'bold'}}>Instruction</TableCell>
                          <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row" >
                            1. Download the CSV format.File should be in CSV format.
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            <Button type="button" variant="contained" className="btnStyling"
                                onClick={()=>downloadCSVFile(bondUploadFile.contentValue,bondUploadFile.fileName,mimeType.csv,fileInputType.base64)}
                            >
                              Download CSV Format
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            2. Fill the csv with required bond details.
                          </TableCell>
                        </TableRow>
                          <TableCell component="th" scope="row">
                          <Table sx={{ minWidth: 450}} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{fontWeight: 'bold'}}>Rules:</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              Bond Number should be in ddd-dddddd-d format(here d means digit).For example: 601-123456-6
                            </TableCell>
                          </TableRow>
                          <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              Date should be MM/DD/YYYY format.
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                              Duplicate Bond numbers are not allowed.
                          </TableCell>
                          </TableRow>
                          <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              Agency code must be 5 digit number. For example: 12345,00001
                            </TableCell>
                          </TableRow>
                          </TableBody>
                          </Table>
                          </TableCell>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                          Please use download agency button for Agency code.
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button type="button" variant="contained" className="btnStyling" onClick={()=>getAgencyDetails()}>
                                Download Agency details
                            </Button>
                          </TableCell>
                          </TableRow>
                          
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            3. Upload the csv file with upload button
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button component="label" variant="contained" className="btnStyling">
                              Upload
                              <input type="file" accept=".csv" hidden onClick ={resetValue} onChange={handleFileUpload} />
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                          4. Once the file is uploaded and all error are resolved,then click on validate button for validating data from database.
                          </TableCell>
                          <TableCell component="th" scope="row">
                          <Button type="button" variant="contained" className="btnStyling" 
                                  disabled ={validateButton} onClick={()=>validateBondDetils(array)}>
                            Validate
                          </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            5. Once all data is validated from database, then submit the details with submit button</TableCell>
                          <TableCell component="th" scope="row">
                          <Button
                            type="button" 
                            variant="contained" 
                            className="btnStyling"
                            disabled ={submitButton}
                            onClick={()=>submitBondDetils(array)}
                          >
                            Submit
                          </Button>
                          </TableCell>
                        </TableRow>
                         
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450}} size="small" aria-label="a dense table">
                <TableHead>
                        <TableRow>                            
                          <TableCell style={{fontWeight: 'bold'}}>bond_Number</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>agency_Code</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>bond_Reserve_Date</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>user_Id</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>error</TableCell>
                        </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
            </TableContainer>
           </Box>
           <ToastContainer />
           <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
        )
}
export default BondUploadDashBoard;