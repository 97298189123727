import React, { useState,useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../component/shared/Header/Header";
import Sidebar from "../component/shared/Sidebar/Sidebar";
import { commonConst,idealTime} from "../Commoncode/constant";
import { encryptData,decryptData} from "../Commoncode/encryptDecrypt";
import  LogOutUserData  from "../Commoncode/logOutUserData";

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {

  useEffect(() => {
    handleIdealTime();
    // eslint-disable-next-line
  }, [])

  const [isMenuActive, setMenuActive] = useState(false);
  const clickMenuToggleClick = () => {
    setMenuActive(!isMenuActive);
  }

  function handleIdealTime(event)
  {
    var lastMovementTime = localStorage.getItem(commonConst.lastMovement) === null ? null : decryptData(localStorage.getItem(commonConst.lastMovement)); 
    if(lastMovementTime !== null && idealTime.maxIdealTime < (new Date().getTime() - new Date(lastMovementTime).getTime())/1000)
    {
      LogOutUserData();
    }
    else
    {
      localStorage.setItem(commonConst.lastMovement,encryptData(new Date()));
    }
  }
  
  return (
    <Route {...rest} render={(props) => {
      if (auth) {
        return (
          <React.Fragment>
            <div className={isMenuActive ? "" : "mini-sidebar"} onMouseMove={(ev)=> handleIdealTime(ev)} onKeyDown={(ev)=> handleIdealTime(ev)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="main-container">
                    <div className="left-side">
                      <Sidebar />
                    </div>
                    <div className="right-side">
                      <Header onMenuToggleClick={clickMenuToggleClick} />
                      <Component {...props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>


        );
      }
      if (!auth) {
        return (<Redirect to="/login" />);

      }

    }} />
  );
};

export default ProtectedRoute;