import axios from "axios";
import { Typography, Button, Box, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";
import { faCircleMinus,faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { initialUserValue,systemRoleMapping } from '../../../Models/userUtility';
import UserDetails from "../../../Hooks/UserDetails";
import LoaderComponent from "../../Common/Loader/LoaderComponent";
import { headers, apiUrls, responseCodes, apiResponseCode, componentConst,toastNoiticationConfig } from "../../../Commoncode/constant"
const AddUpdateUser = () => {
    let history = useHistory();
    let location = useLocation();
    const [userValue, setUserValue] = useState(initialUserValue);
    const [showLoader, setShowLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [userRoles,setUserRoles] = useState([])
    const [systemAccess,setSystemAccess] = useState([]);
    const [sysRolMapping,setSysRolMapping] = useState([systemRoleMapping]);
    const goToPreviousPath = (responseMessage) => {
        history.push(componentConst.users, responseMessage)
    }
    useEffect(() => {
        setShowLoader(true);
        axios.get(apiUrls.getUserRolesList, { headers: headers })
            .then((response) => {
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    if(response.data.userRoles.length>0){
                        var roles = response.data.userRoles.filter(e=>e.roleName !='Agent');
                        setUserRoles(roles);
                        setShowLoader(false);
                    }
                  }
            })
            .catch(error => {
                setShowLoader(false);
            });
            
            axios.get(apiUrls.getSystemAcessList, { headers: headers })
            .then((response) => {
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setSystemAccess(response.data.suretySystemAccesses); 
                    setShowLoader(false);
                  }
            })
            .catch(error => {
                setShowLoader(false);
            });
        if (location.userprop !== undefined) {
            setUserValue(location.userprop);
            setSysRolMapping(location.userprop.systemAccessMappings);
            setIsEdit(true);
        }
    }, []);

    const handleInputChange = (e,i) => {
        const { name, value } = e.target;
        if(name === 'systemId' || name === 'roleId'){
            if(name === 'systemId'){
                var output=systemAccess.filter(x=>value.includes(x.systemName)).map(z=>z.systemId)[0];
                if(sysRolMapping.map(x=>x[name]).includes(output)){
                    toast.error(toastNoiticationConfig.duplicateSystemNotAllowed, {
                        position: toast.POSITION.TOP_RIGHT
                });
                    return;
                }
                
            }else{
                var output=userRoles.filter(x=>value ===(x.roleName)).map(z=>z.roleId)[0];
            }
            var out =sysRolMapping.map((item,j)=>{
                if(i==j){
                   return {
                        ...item,
                        [name]:output
                    };
                }else{return item;}
            });
            setSysRolMapping(out);
            setUserValue({
                ...userValue,
                ['systemAccessMappings']:out
            });
            return;
        }else{
            setUserValue({
                ...userValue,
                [name]: value
            });
        }

    };

    const handleRemoveClick = (index )=> {
        const list = [...sysRolMapping];
        list.splice(index, 1);
        setSysRolMapping(list);
        setUserValue({
            ...userValue,
            ['systemAccessMappings']:list
        });
    };

    const handleAddClick = () => {
        setSysRolMapping(sysRolMapping =>[...sysRolMapping, systemRoleMapping]);
    };

    function insertUserDetails() {
        setShowLoader(true);
        if(userValue.systemAccessMappings.some(e=>e.systemId === 0 || e.roleId ===0)){
            return;
        }
        userValue.addUpdateUserid = UserDetails().userName;
        //if (isEdit) {
        //    userValue.udLock = userValue?.udLock === 'true';
        //}

        var apiUrl = isEdit ? apiUrls.updateUser : apiUrls.addUser;

        axios.post(apiUrl, userValue, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) {
                    
                    goToPreviousPath(response.data.responseMessage.responseMessage);
                } else {

                    toast.error(response.data.responseMessage.responseMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setShowLoader(false);
            });
    }

    return (
        <React.Fragment>
            <Typography variant="h5" className="pageHeading">
                {isEdit === true ? "Update User" : "Add User"}
            </Typography>
            <Box className="body-container" component="form" noValidate autoComplete="off">
                <Grid container spacing={1}>
                    
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="loginUserName"
                            size="small"
                            id="loginUserName"
                            label="Login User Name"
                            disabled={isEdit}
                            onChange={handleInputChange}
                            value={userValue.loginUserName}
                            error={userValue.loginUserName === ''}
                            helperText={userValue.loginUserName === '' ? 'Login User Name is required' : ''}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="email"
                            size="small"
                            id="email"
                            label="Email"
                            disabled={isEdit}
                            value={userValue.email}
                            error={userValue.email === ''}
                            helperText={userValue.email === '' ? 'Email is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="firstName"
                            size="small"
                            id="firstName"
                            label="First Name"
                            value={userValue.firstName}
                            error={userValue.firstName === ''}
                            helperText={userValue.firstName === '' ? 'First Name is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="lastName"
                            size="small"
                            id="lastName"
                            label="Last Name"
                            value={userValue.lastName}
                            error={userValue.lastName === ''}
                            helperText={userValue.lastName === '' ? 'Last Name is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ width: '-webkit-fill-available' }}>
                            <InputLabel>Lock</InputLabel>
                            <Select
                                id="lock"
                                size="small"
                                name="udLock"
                                value={userValue.udLock}
                                label="Lock"
                                required
                                onChange={handleInputChange}
                            >
                                <MenuItem value={true} key={true}>
                                    True
                                </MenuItem >
                                <MenuItem value={false} key={false}>
                                    False
                                </MenuItem >
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ width: '-webkit-fill-available' }}>
                            <InputLabel>Active</InputLabel>
                            <Select
                                id="status"
                                size="small"
                                name="status"
                                value={userValue.status}
                                label="status"
                                required
                                onChange={handleInputChange}
                            >
                                <MenuItem value={true} key={true}>
                                    True
                                </MenuItem >
                                <MenuItem value={false} key={false}>
                                    False
                                </MenuItem >
                            </Select>
                        </FormControl>
                    </Grid>
                {sysRolMapping.map((x,i)=>{
                    return(
                        <>
                        <Grid item xs={5}> 
                        <FormControl error ={sysRolMapping[i]['systemId'] ===0} required variant="outlined" style={{width:'-webkit-fill-available'}}>
                            <InputLabel >System Access</InputLabel>
                            <Select
                                id="systemAccess"
                                size="small"
                                name="systemId"
                                key={i}
                                value={x.systemId !== 0 && systemAccess.length>0?systemAccess.filter(z=>z.systemId === x.systemId)[0].systemName:""}
                                label="systemAccess"
                                onChange={e=>handleInputChange(e,i)}
                                required
                            >
                                {systemAccess.map((system) => (
                                    <MenuItem value={system.systemName} key={system.systemId}>
                                            {system.systemName}
                                    </MenuItem >
                                 ))}
                            </Select>
                            {sysRolMapping[i]['systemId'] ===0 &&<FormHelperText>System is required</FormHelperText>}
                        </FormControl>
                            
                        </Grid>
                        <Grid item xs={5}> 
                        <FormControl error ={sysRolMapping[i]['roleId'] === 0} required variant="outlined" style={{width:'-webkit-fill-available'}}>
                            <InputLabel >Role</InputLabel>
                            <Select
                                id="role"
                                size="small"
                                name="roleId" 
                                key ={i+1} 
                                value={x.roleId !==0 && userRoles.length>0?userRoles.filter(z=>z.roleId === x.roleId)[0].roleName:""}
                                label="Role"
                                onChange={e=>handleInputChange(e,i)}
                                required
                            >
                                {userRoles.map((role) => (
                                    <MenuItem value={role.roleName} key={role.roleId}>
                                            {role.roleName}
                                    </MenuItem >
                                 ))}
                            </Select>
                            {sysRolMapping[i]['roleId'] === 0 && <FormHelperText>Role is required</FormHelperText>}
                        </FormControl>
                            
                        </Grid>
                        {sysRolMapping.length !==1 &&
                        <Grid item xs={1}>
                        <button
                            type="button"
                            className="btn btn-link"
                            key={i}
                            onClick={() => handleRemoveClick(i)}
                            >
                            <i style={{color:'red'}} ><FontAwesomeIcon icon={faCircleMinus} /></i>
                        </button>
                        </Grid>}
                        {
                         sysRolMapping.length-1 ===i && sysRolMapping.length < systemAccess.length &&
                         <Grid item xs={1}>
                         <button
                             type="button"
                             className="btn btn-link"
                             key={i}
                             onClick={() => handleAddClick()}
                             >
                             <i style={{color:'green'}} ><FontAwesomeIcon icon={faCirclePlus} /></i>
                         </button>
                         </Grid>   
                        }
                    </>
                    )
                })}
                    
                    
                    <Grid item xs={12}>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                className="btnStyling"
                                color="success"
                                disabled={userValue.loginUserName === '' || userValue.firstName === '' || userValue.lastName === '' || userValue.email === '' || userValue.systemAccessMappings.some(e=>e.systemId === 0 || e.roleId ===0) }
                                onClick={insertUserDetails}>{isEdit === true ? "Update" : "Add"}</Button>
                            <span>  </span>
                            <Button variant="contained" className="btnStyling" color="info" onClick={() => goToPreviousPath("")}>Cancel</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <ToastContainer></ToastContainer>
            <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
    )
}
export default AddUpdateUser;