import { commonConst,apiUrls,componentConst,roleName } from "./constant";
import UserDetails from "../Hooks/UserDetails";

export default function LogOutUserData()
{
    let loggedInUserDetails = UserDetails();

      localStorage.removeItem(commonConst.idToken);
      localStorage.removeItem(commonConst.userInfo);
      localStorage.removeItem(commonConst.refrenceData);
      localStorage.removeItem(commonConst.msg);
      localStorage.removeItem(commonConst.homeData);
      localStorage.removeItem(commonConst.lastMovement);
      localStorage.removeItem(commonConst.submissionData);
      localStorage.removeItem(commonConst.uwSubmissionData);
      localStorage.removeItem('filterModel');
      if (loggedInUserDetails.userRoleName === roleName.agent) {
        window.open(apiUrls.getAgentLogOutApiUrl, "_self");
      }
      else {
        window.location.assign(componentConst.loginOption);
      }
      return true;
}