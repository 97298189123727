import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { filterOperators,componentConst } from "../Commoncode/constant";
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
export const agentTableHeader =
  [
    {
      field: 'id', headerName: 'ID', sortable: true, width: 75, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
      ,renderCell: (params) => 
      (<NavLink to={{pathname:componentConst.addUpdateAgent,agentprop:params.row}}><Button type="button">{params.row.id}</Button></NavLink>)
 
    },
    {
      field: 'loginUserName', headerName: 'Login User Name', sortable: true, minWidth: 200, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'firstName', headerName: 'First Name', sortable: true, minWidth: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'lastName', headerName: 'Last Name', sortable: true, minWidth: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
        field: 'email', headerName: 'Email', sortable: true, minWidth: 250, filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'agencyCode', headerName: 'Agency Code', sortable: true, minWidth: 150,  filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
      {
        field: 'udLock', headerName: 'Lock Status', sortable: true, minWidth: 100, filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
        )
      },
  ]

export const initialAgentValue = {
  id:0,
  loginUserName:"",
  firstName:"",
  lastName:"",
  email:"",
  agencyCode: "",
  udLock: false,
  addUpdateUserid: ""
}

export const searchBoxProp = {
  search: "search",
  pageNumber: "pageNumber",
  pageSize: "pageSize",
  columnFilterData: "columnFilterData",

  adNumber: "adNumber",
  adName: "adName",
  adAddress1: "adAddress1",
  adAddress2: "adAddress2",
  adCity: "adCity",
  adState: "adState",
  adZip:"adZip",
  adAgentemailid:"adAgentemailid",
  adReserveBlock:"adReserveBlock",
  adReserveMax:"adReserveMax",
  adMaxOpenAllowed:"adMaxOpenAllowed",
  adBondInfo:"adBondInfo",
  adActive:"adActive",
  adIsSingleBondReserve:"adIsSingleBondReserve",
  adCreateUserid:"adCreateUserid",
  adUpdateUserid:"adUpdateUserid",
  adCreateDate:"adCreateDate",

  contains: "contains",
  equals: "equals",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  isAnyOf: "isAnyOf",

  setData: "setData",
  setFilteredData: "setFilteredData",
  TotalCount: "TotalCount",
  sortData: "sortData",
  asc: "asc",
  desc: "desc"
}