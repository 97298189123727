import { numeric } from "../Commoncode/constant";
import moment from "moment";

export function numberValidation(value, length) {
    if ((value === "") || (value && value > 0 && numeric.test(value) && value <= length)) {
        return true;
    }
    else {
        return false;
    }
}

export function isValidNumberOnKeyDown(event) {
    var value = event.key;
    if (value === 'e' || value === '-' || value === '+' || value === '.' || value === 'E') {
        event.preventDefault()
    }
}

export function isValidNumberOnPaste(event) {
    var value = event.clipboardData.getData('Text');
    if (value.includes('e') || value.includes('-') || value.includes('+') || value.includes('.') || value.includes('E')) {
        event.preventDefault()
    }
}

export function isValidDecimalOnKeyDown(event) {
    var value = event.key;
    if (value === 'e' || value === '-' || value === '+' || value === 'E') {
        event.preventDefault()
    }
}

export function isValidDecimalOnPaste(event) {
    var value = event.clipboardData.getData('Text');
    if (value.includes('e') || value.includes('-') || value.includes('+') || value.includes('E')) {
        event.preventDefault()
    }
}

export function removeTime(datetime) {
    if (datetime === "") {
        return datetime;
    }
    else {
        return moment(datetime).format('YYYY-MM-DD');
    }
}
export function isCheckDate(date){
    if(new Date(date).toString() === 'Invalid Date'){
        return false;
    }else{
        return true;
    }
}