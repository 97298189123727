import axios from "axios";
import { Typography,Button, Box ,TextField,Checkbox,FormControlLabel,Select,MenuItem,InputLabel,FormControl,OutlinedInput,FormHelperText} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory,useLocation } from "react-router-dom";
import React,{ useEffect,useState  } from "react";
import { Grid } from '@mui/material';
import { initialAgencyValue } from '../../../Models/agencyUtility';
import UserDetails from "../../../Hooks/UserDetails";
import { headers, apiUrls,responseCodes,apiResponseCode,usState,componentConst} from "../../../Commoncode/constant"
const AddUpdateAgency = () =>{
    let history = useHistory();
    let location =useLocation();
    const [enableInputReserveBlock,setEnableInputReserveBlock] = useState(false);
    const [agencyValue,setAgencyValue] = useState(initialAgencyValue);
    const [showLoader, setShowLoader] = useState(false);
    const [isEdit,setIsEdit] = useState(false)
    
    const goToPreviousPath = (responseMessage) => {
            history.push(componentConst.agency,responseMessage)
        }
    useEffect(()=>{
        if(location.agencyprop !== undefined){
            setAgencyValue(location.agencyprop);
            setEnableInputReserveBlock(!location.agencyprop.adReserveBlock);
            setIsEdit(true);
        }       
    },[location.agencyprop])

    const handleInputChange = (e) =>{
        const {name,value,checked} = e.target;
        if(name === 'adReserveBlock' || name === 'adIsSingleBondReserve' || name === 'adActive'){
            setAgencyValue({
                ...agencyValue,
                [name]:checked
            });
        }else{ 
        setAgencyValue({
            ...agencyValue,
            [name]:value
        });
        }
    };

  
    function insertAgencyDetails(){
        if(isEdit){
            agencyValue.adUpdateUserid = UserDetails().userName;
        }else{
            agencyValue.adNumber = agencyValue.adNumber.padStart(5,'0');
            agencyValue.adZip = agencyValue.adZip.padStart(5,'0');
            agencyValue.adCreateUserid = UserDetails().userName;
        }

        axios.post(apiUrls.insertAgencyDetails, agencyValue,{ headers: headers })
        .then((response) => {
            setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
        {
            // toast.success(response.data.responseMessage.responseMessage, {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            goToPreviousPath(response.data.responseMessage.responseMessage);
        }else{
            
            toast.error(response.data.responseMessage.responseMessage, {
                position: toast.POSITION.TOP_RIGHT});
        }
      })
        .catch(error => {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT});
          setShowLoader(false);
        });
    }

    function enableReserveMaxAndopenallowed(e){
        setEnableInputReserveBlock(!e.target.checked);
        agencyValue.adIsSingleBondReserve = !e.target.checked;
        if(!e.target.checked){
            agencyValue.adReserveMax = 0;
            agencyValue.adMaxOpenAllowed = 0;

        }
        else{
            agencyValue.adReserveMax = '';
            agencyValue.adMaxOpenAllowed = '';
        }
    }

    function setReserveMaxAndopenallowedToZeroAndDisable(event){
        setEnableInputReserveBlock(event.target.checked);
        agencyValue.adReserveBlock = !event.target.checked;
        if(!event.target.checked){
            agencyValue.adReserveMax = '';
            agencyValue.adMaxOpenAllowed = '';
        }else{
            agencyValue.adReserveMax = 0;
            agencyValue.adMaxOpenAllowed = 0;
        }
    }

    return (
            <React.Fragment>
                <Typography variant="h5" className="pageHeading">
                    {isEdit===true?"Update Agency":"Add Agency"}
                </Typography>
                <Box className="body-container" component="form" noValidate autoComplete="off">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                                <TextField 
                                style={{width:'-webkit-fill-available'}}
                                 size="small"  
                                 name='adNumber'
                                 id="agencyNumber" 
                                 label="Agency" 
                                 inputProps={{ maxLength: 5 }}
                                 disabled={isEdit}
                                 onChange={handleInputChange}
                                 value={agencyValue.adNumber}
                                 error ={agencyValue.adNumber ===''}
                                 helperText ={agencyValue.adNumber ===''?'Agency is required':''}
                                 required/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                            style={{width:'-webkit-fill-available'}}
                             name="adName"
                             size="small"  
                             id="agencyName" 
                             label="Agency Name" 
                             onChange={handleInputChange}
                             value={agencyValue.adName}
                             error ={agencyValue.adName ===''}
                             helperText ={agencyValue.adName ===''?'Agency name is required':''}    
                             required/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                            name="adAddress1"
                            size="small"  
                            id="address1" 
                            label="Address Line 1"
                            value={agencyValue.adAddress1}
                            error ={agencyValue.adAddress1 ===''}
                            helperText ={agencyValue.adAddress1 ===''?'Address Line 1 is required':''} 
                            onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             size="small"  
                             id="address2" 
                             label="Address2" 
                             name='adAddress2' 
                             value={agencyValue.adAddress2}
                             onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             name='adCity'
                             size="small"  
                             id="city" 
                             label="City" 
                             value={agencyValue.adCity}
                             error ={agencyValue.adCity ===''}
                             helperText ={agencyValue.adCity ===''?'City is required':''} 
                             onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}> 
                        <FormControl error ={agencyValue.adState ===''} required variant="outlined" style={{width:'-webkit-fill-available'}}>
                            <InputLabel >State</InputLabel>
                            <Select
                                id="state"
                                size="small"
                                name="adState"  
                                value={agencyValue.adState}
                                label="State"
                                onChange={handleInputChange}
                            >
                                {usState.map((usState) => (
                                    <MenuItem value={usState.abbreviation} key={usState.abbreviation}>
                                            {usState.name}
                                    </MenuItem >
                                 ))}
                            </Select>
                            {agencyValue.adState ==='' &&<FormHelperText>State is required</FormHelperText>}
                        </FormControl>
                            
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                            style={{width:'-webkit-fill-available'}}
                             name='adZip'
                             size="small" 
                             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                             id="zip" 
                             label="Zip" 
                             value={agencyValue.adZip}
                             error ={agencyValue.adZip ===''}
                             helperText ={agencyValue.adZip ===''?'Zip is required':''}
                             onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             name='adAgencyemailid'
                             size="small"  
                             id="email" 
                             label="Email" 
                             value={agencyValue.adAgencyemailid}
                             onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel 
                            style={{width:'-webkit-fill-available'}}
                             name='adReserveBlock'
                             control={<Checkbox id='reserveBlock'/>} 
                             className="checkbox" 
                             label="Reserve Block" 
                             checked={agencyValue.adReserveBlock}
                             onClick={enableReserveMaxAndopenallowed} 
                             onChange={handleInputChange}/> 
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel 
                            style={{width:'-webkit-fill-available'}}
                             name='adIsSingleBondReserve'
                             control={<Checkbox id='isSingleBondReserve'/>} 
                             className="checkbox" 
                             checked={agencyValue.adIsSingleBondReserve}
                             onClick={setReserveMaxAndopenallowedToZeroAndDisable}
                             label="Is Single Bond Reserved" 
                             onChange={handleInputChange}/> 
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                            style={{width:'-webkit-fill-available'}}
                             name='adReserveMax'
                             size="small" 
                             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                             id="reserveMax" 
                             label="Reserve Max" 
                             value={agencyValue.adReserveMax}
                             error ={agencyValue.adReserveMax ===''}
                             helperText ={agencyValue.adReserveMax ===''?'Reserve max value is required':''}
                             disabled={enableInputReserveBlock} 
                             onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField  
                            style={{width:'-webkit-fill-available'}}
                             name='adMaxOpenAllowed'
                             size="small" 
                             inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                             id="maxOpenAllowed" 
                             label="Max open allowed" 
                             value={agencyValue.adMaxOpenAllowed}
                             error ={agencyValue.adMaxOpenAllowed ===''}
                             helperText ={agencyValue.adMaxOpenAllowed ===''?'Max open allowed is required':''}
                             disabled={enableInputReserveBlock} 
                             onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel 
                            style={{width:'-webkit-fill-available'}}
                                name='adActive'
                                control={<Checkbox id='isActive'/>} 
                                checked={agencyValue.adActive}
                                className="checkbox" 
                                label="Is Active"  
                                defaultChecked ={true}
                                onChange={handleInputChange}/> 
                        </Grid>
                        <Grid item xs={12}>
                            <div className="text-center">
                                <Button 
                                 variant="contained" 
                                 className="btnStyling" 
                                 color="success" 
                                 disabled={false} 
                                 onClick={insertAgencyDetails}>{isEdit===true?"Update":"Add"}</Button>
                                <span>  </span>
                                <Button variant="contained" className="btnStyling" color="info" onClick={()=>goToPreviousPath("")}>Cancel</Button> 
                            </div>
                        </Grid>
                        
                    </Grid>
                </Box>
                <ToastContainer></ToastContainer>
            </React.Fragment>
            )
}
export default AddUpdateAgency;