import { commonConst } from "../Commoncode/constant";
import { decryptData } from "../Commoncode/encryptDecrypt";
import { setDateTimeZone } from "../Hooks/DateUtility";

export default function IsUserAuthenticated() {
    let userInfo = localStorage.getItem(commonConst.userInfo);
    if (userInfo) {
        var createTime = decryptData(userInfo).createTime;
        createTime = new Date(createTime);
        var present_date = setDateTimeZone(new Date());
        var hours = 1000 * 60 * 60;
        var timeDiff = (present_date.getTime() - createTime.getTime()) / hours;
        if (timeDiff > 11) {
            localStorage.removeItem(commonConst.userInfo);
            localStorage.removeItem(commonConst.refrenceData);
            localStorage.removeItem(commonConst.homeData);
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return false;
    }
}