import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Autocomplete,TextField,Button, Typography, Card, Box,Grid } from '@mui/material';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { searchSubmissionBoxProp } from "../../Models/SubmissionModels/listSubmission";
import { defaultUWSubmissionListApi,searchUWSubmissionBoxProp,searchUWSubmissionBox,uwSubmissionListHeader, exportUWSubmissionHeader,defaultMonthDropdown,searchMonthDropdown } from "../../Models/SubmissionModels/submissionUW";
import { headers, apiUrls, apiResponseCode, skipToCheck, commonConst, toastNoiticationConfig, responseCodes, exportFileName, dataGridDensity, filterOperators } from "../../Commoncode/constant";
import "../../style/Style.scss";
import PrintExportEmailModal from "../Common/PrintExportEmailModal/PrintExportEmailModal";
import { encryptData,decryptData } from "../../Commoncode/encryptDecrypt";
import moment from "moment";

const SubmissionUW = () => {
    const [showLoader, setShowLoader] = useState(false);
    
    const [filteredData, setFilteredData] = useState([]);
    const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
    const [tableRows, settableRows] = useState([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [monthValue, setMonthValue] = useState(defaultMonthDropdown);
    const [searchState, searchDispatch] = useReducer(searchReducer, searchUWSubmissionBox);
  
    const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
    const [exportAllowed, setExportAllowed] = useState(true);
    const [exportData, setExportData] = useState(defaultUWSubmissionListApi);

    const [subDet, setSubDet] = useState("");
    
  const size = window.innerWidth;

  let history = useHistory();
    useEffect(() => {
        let loggedInUserDetails = UserDetails();
        if (loggedInUserDetails !== false) {
          let submissionData = localStorage.getItem(commonConst.uwSubmissionData);
      if (submissionData !== null) {
        var filteredData = decryptData(submissionData);
        var filterData = [];
        if (filteredData.FilterData !== null && filteredData.FilterData.length > 0) {
          filteredData.FilterData.map((item, index) => {
            return filterData.push({
              columnField: item.ColumnField,
              operatorValue: item.OperatorValue,
              value: item.Value ? item.Value : null
            });
          });
        }
        setFilteredData(filterData);
        setLinkOperator(filteredData.LinkOperator);
        setMonthValue(searchMonthDropdown.filter(m=>m.key === filteredData.Month)[0]);
        searchDispatch({ type: searchUWSubmissionBoxProp.setFilteredData, value: filteredData });
      }
      else {
        searchDispatch({ type: searchUWSubmissionBoxProp.setData, value: loggedInUserDetails });
      }
            
    }
  }, [])
      
  useEffect(() => {
    submitSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  
  function searchReducer(searchState, action) {
    switch (action.type) {
      case searchUWSubmissionBoxProp.search:
        return {
          ...searchState, Search: action.value,
        }
      case searchUWSubmissionBoxProp.fromDate:
        return {
          ...searchState, FromDate: action.value,
        }
      case searchUWSubmissionBoxProp.toDate:
          return {
            ...searchState, ToDate: action.value,
          }
      case searchUWSubmissionBoxProp.Month:
              return {
                ...searchState, Month: action.value,
              }
      case searchUWSubmissionBoxProp.pageNumber:
        return {
          ...searchState, PageNumber: action.value,
        }
      case searchUWSubmissionBoxProp.pageSize:
        return {
          ...searchState, PageNumber: 0, PageSize: action.value,
        }
      case searchUWSubmissionBoxProp.setData:
        return {
          ...searchState,
          PageSize: 20,
          PageNumber: 0,
          TotalCount: 0,
          SortData: searchUWSubmissionBoxProp.totalSubmissions,
          AscDesc: searchUWSubmissionBoxProp.desc,
          FilterData: [],
          LinkOperator: "",
          Search:"",
          Month:0,
          Export: false
        }
      case searchUWSubmissionBoxProp.setFilteredData:
        return {
          ...searchState,
          PageSize: action.value.PageSize,
          PageNumber: action.value.PageNumber,
          Search: action.value.Search,
          TotalCount: action.value.TotalCount,
          SortData: action.value.SortData,
          AscDesc: action.value.AscDesc,
          FilterData: action.value.FilterData,
          LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator,
          FromDate: action.value.FromDate,
          ToDate: action.value.ToDate,
          Month: action.value.Month,
          Export: false  
        }
      case searchUWSubmissionBoxProp.columnFilterData:
        return {
          ...searchState,
          FilterData: action.value,
          LinkOperator: action.operator
        }
      case searchUWSubmissionBoxProp.sortData:
        return {
          ...searchState,
          SortData: action.value[0].field,
          AscDesc: action.value[0].sort,
          PageNumber: 0
        }
      default:
        return {
          ...searchState
        }
    }
  }

  
  function submitSearch() {
    setSubDet("");
    var isDataAvailable = false;

    for (const item in searchState) {
      if (searchState[item] && item !== skipToCheck.pageNo && searchState[item].toString().trim() !== "") {
        isDataAvailable = true;
      }
    }

    if (isDataAvailable) {
      localStorage.setItem(commonConst.uwSubmissionData, encryptData(searchState));
      
      setShowLoader(true);
      axios.post(apiUrls.uwSubList, searchState, { headers : headers })
        .then((response) => {
        setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) 
        {
          settableRows([]);
          settableRows(response.data.uwSubmissionsList);
          setRowsCount(response.data.totalRecords);
        }
      })
        .catch(error => {
          setShowLoader(false);
        });
      }

  }

  function onFilterChange(filterModel) {
    if(filterModel.items.length >= 2){
      var check = filterModel.items;
      var columnList = []
      for(var item in check){
        if(!columnList.includes(check[item].columnField)){
          
          columnList.push(check[item].columnField)
        }
          else{ 
            if(check[item].value){
              
            toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
              position: toast.POSITION.TOP_RIGHT
            });
            filterModel.items.pop();
            setFilteredData(filterModel.items);
            return;
            }
          }
        }
    }
    setFilteredData(filterModel.items);
    if (filterModel !== null && filterModel.linkOperator !== "") {
      setLinkOperator(filterModel.linkOperator);
    }
    else {
      setLinkOperator(GridLinkOperator.And);
    }

    var filterData = [];
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: searchUWSubmissionBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });

  }

  // const callFnPageSize = React.useCallback((newPageSize) => {
  //   searchDispatch({ type: searchUWSubmissionBoxProp.pageSize, value: newPageSize });
  // }, []);

  const CallPrintExportEmail = () => {
    let exportData = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.uwSubList, exportData, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setExportData(defaultUWSubmissionListApi);
        if (response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.DataTooLarge) {
          setExportAllowed(false);
          setShowPrintExportEmailModal(true);
        }
        else if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          let totalRecords = [];
          if (response.data.uwSubmissionsList.length > 0) {

            response.data.uwSubmissionsList.map((item, index) => {
              return totalRecords.push({
                underwriterName: item.underwriterName ? item.underwriterName : '',
                totalSubmissions: item.totalSubmissions ? item.totalSubmissions : '',
                totalApprovedWritten: item.totalApprovedWritten ? item.totalApprovedWritten : '',
                totalApproved: item.totalApproved ? item.totalApproved : '',
                totalPending: item.totalPending ? item.totalPending : '',
                totalDeclined: item.totalDeclined ? item.totalDeclined : '',
                totalNotAcquired: item.totalNotAcquired ? item.totalNotAcquired : '',
              });
            });
            setExportData(totalRecords);
            setExportAllowed(true);
            setShowPrintExportEmailModal(true);
          }
          else
          {
            toast.warning(toastNoiticationConfig.noRecordFound, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
      .catch(error => {
        setShowLoader(false);
      });
  }

  const handleClosePrintExportEmailPopup = () => {
    setShowPrintExportEmailModal(false);
  };

  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.uwEmailSubmissionDetails, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }
  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: searchUWSubmissionBoxProp.sortData, value: prop });
    }
  }
  
  function onRowChange(event) {
    
    var filterData = [];
    if(event.row.underwriterName !== "")
    {
    filterData.push({
      ColumnField: searchSubmissionBoxProp.underwriterName,
      OperatorValue: filterOperators.equals,
      Value: event.row.underwriterName
    });
    }
    
    var fromDate = null;
    var toDate = null;
    var todayDate = new Date();
    if(monthValue.key === 0) //YTD
    {
      fromDate = new Date(todayDate.getFullYear(),0,1);
      toDate = new Date(todayDate.getFullYear(),todayDate.getMonth(),todayDate.getDate());
    }
    else if(monthValue.key === 1) //PRIOR YEAR
    {
      fromDate = new Date((todayDate.getFullYear() - 1),0,1);
      toDate = new Date(todayDate.getFullYear(),0,0);
    }
    else if(monthValue.key === 2) //CURRENT MONTH
    {
      fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth() ,1);
      toDate = new Date(todayDate.getFullYear(),(todayDate.getMonth()+1),0);
    }
    else if(monthValue.key === 3) //PRIOR MONTH
    {
      fromDate = new Date(todayDate.getFullYear(),(todayDate.getMonth()-1) ,1);
      toDate = new Date(todayDate.getFullYear(),todayDate.getMonth() ,0);
    }
    else if(monthValue.key === 4) //CUSTOM DATE FILTER
    {
      fromDate = searchState.FromDate;
      toDate = searchState.ToDate;
    }
    if(fromDate !== "")
    {
    filterData.push({
      ColumnField: searchSubmissionBoxProp.submissionDate,
      OperatorValue: filterOperators.onOrAfter,
      Value: moment(fromDate).format('YYYY-MM-DD')
    });
    }
    if(toDate !== "")
    {
    filterData.push({
      ColumnField: searchSubmissionBoxProp.submissionDate,
      OperatorValue: filterOperators.onOrBefore,
      Value: moment(toDate).format('YYYY-MM-DD')
    });
    }

    setSubDet(filterData);
  }

  const CallSeeSubmission = () =>
  {
    var searchSubmissionBox = {
      Search:"",
      PageNumber: 0,
      PageSize: 20,
      FilterData: subDet,
      LinkOperator: GridLinkOperator.And,
    
      Export: false,
      TotalCount: 0,
    
      SortData: searchSubmissionBoxProp.submissionDate,
      AscDesc: searchUWSubmissionBoxProp.desc
    }
    localStorage.setItem(commonConst.submissionData, encryptData(searchSubmissionBox));
    goToPreviousPath();
  }

  const goToPreviousPath = () => {
    history.goBack()
  }

  return(
    <React.Fragment>
    <Typography variant="h5" className="pageHeading">
        C&F Submissions by UW
      </Typography>
      
      <Box className="body-container homePageContainer" noValidate autoComplete="off">
       
        <Grid container spacing={1}>
                        <Grid item xs={3}>
      <Autocomplete clasName="col-5" id="ddlMonth" size="small" options={searchMonthDropdown} value={monthValue} getOptionLabel={(option) => option.value}
                                onChange={(event, value) => { searchDispatch({ type: searchUWSubmissionBoxProp.Month, value: value.key }); setMonthValue({ key: value.key, value: event.target.innerHTML })  }}
                                renderOption={(props, monthItems) => {
                                    return (
                                        <li {...props} key={monthItems.key}>
                                            {monthItems.value}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField size="small" {...params} label="Month" placeholder="Month" />
                                )} />   
          </Grid>
                        <Grid item xs={3}>
          
        { monthValue.key === 4 &&
        <TextField size="small" clasName="col-4" value={searchState.FromDate} id="fromDtSubmitted" label="From Date" type="date" InputLabelProps={{ shrink: true, }}
                        onChange={(event, value) => { searchDispatch({ type: searchUWSubmissionBoxProp.fromDate, value: event.target.value }) }} /> }
        </Grid>
        <Grid item xs={3}>
        { monthValue.key === 4 &&                
        <TextField size="small" clasName="col-4" value={searchState.ToDate} id="toDtSubmitted" label="To Date" type="date" InputLabelProps={{ shrink: true, }}
                        onChange={(event, value) => { searchDispatch({ type: searchUWSubmissionBoxProp.toDate, value: event.target.value }) }} /> }
        </Grid>
        
          <Grid item xs={3}>
            <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallSeeSubmission} disabled={subDet === "" ? true : false}>See Submissions</Button> &nbsp;
            <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallPrintExportEmail}>Export/Email</Button> &nbsp;
            </Grid>
        
        
        </Grid>

        <div className="table-body">
          <Card sx={{ minWidth: 275 }}>
            <div className="tableContainerHome" style={{ width: '100%' }}>
              <DataGridPro className="muiDataGrid" density={dataGridDensity.compact}
                componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
                components={{ Toolbar: GridToolbar, disableExport: true }}
                disableColumnFilter={false}
                //columnVisibilityModel={{id:false}}
                rows={tableRows}
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                rowCount={rowsCount}
                columns={uwSubmissionListHeader}
                //rowsPerPageOptions={[20, 50, 100, 500]}
                // pagination
                // page={searchState.PageNumber}
                // pageSize={searchState.PageSize}
                // paginationMode="server"
                // onPageChange={(data) => {
                //   searchDispatch({ type: searchUWSubmissionBoxProp.pageNumber, value: data });
                // }}
                //onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
                rowHeight={size >= 1400 ? 50 : 20}
                maxRowHeight={size >= 1400 ? 50 : 20}
                minRowHeight={size >= 1400 ? 50 : 20}
                headerHeight={size >= 1400 ? 50 : 22}
                onRowClick={(e) => onRowChange(e)}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterModel={{
                  items: filteredData,
                  linkOperator: linkOperator,
                }}
              />

            </div>
          </Card>
        </div>
      </Box>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.uwSubmissionList} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportUWSubmissionHeader} closePopup={handleClosePrintExportEmailPopup} sendEmail={callSendEmail}></PrintExportEmailModal>
    </React.Fragment>
  )
}
export default SubmissionUW;