
export const bindAgentProp = {
    agencyId: 0,
    agencyName: "",
}

export const reserveModalPopUpScenarioProp = {
    reserveBlock: "",
    singleReserveBond: "",
    maxOpenAllowed: 0,
    reserveMax: 0
}

export const reserveModalPopUpProp = {
    title: "",
    description: "",
    selectAgencyBtn: false,
    cancelBtn: false,
    continueBtn: false,
    overrideBtn: false,
    noOfBonds: false,
    cancelBtnForAgent: false

}
export const reserveModalScenarioByName = {
    singleBondReserve: "singleBondReserve",
    configuredBondReserve: "configuredBondReserve",
    agencyNotAuthorized: "agencyNotAuthorized",
    additionalBondNotAllowed: "additionalBondNotAllowed",
    additionalBondNotAllowedWithOverride: "additionalBondNotAllowedWithOverride",
    configuredBondReserveWithOverride: "configuredBondReserveWithOverride",
    overriderBondReserve: "overriderBondReserve",
    singleBondReserveForAgent: "singleBondReserveForAgent",
    configuredBondReserveForAgent: "configuredBondReserveForAgent",
    agencyNotAuthorizedForAgent: "agencyNotAuthorizedForAgent",
    additionalBondNotAllowedForAgent: "additionalBondNotAllowedForAgent",
    none: "none",
}
export const reserveBondModalDesc =
{
    additionalBondNotAllowedDesc1: "Additional bonds are not allowed to be reserved at this time. Please contact your supervisor for more assistance!",
    singleBondReserveDesc1: "Single bond reserve is set for this Agency",
    configuredBondReserveDesc1: "Number of bonds  to be reserved ",
    configuredBondReserveDesc2: "Current number of bonds in reserved status ",
    agencyNotAuthorizedDesc1: "Agency is not authorized to reserve bonds",
    additionalBondNotAllowedWithOverrideDesc1: "Additional bonds are not allowed to be reserved at this time",
    configuredBondReserveWithOverrideDesc1: "Number of bonds to be reserved ",
    configuredBondReserveWithOverrideDesc2: "Current number of bonds in reserved status ",
    overriderBondReserveDesc1: "Override Bonds",
    additionalBondNotAllowedForAgentDesc1: "Additional bonds are not allowed to be reserved at this time. Please contact your underwriter for more assistance!",
    singleBondReserveForAgentDesc1: "Number of bonds to be reserved ",
    configuredBondReserveForAgentDesc1: "Number of bonds to be reserved ",
    configuredBondReserveForAgentDesc2: "Current number of bonds in reserved status ",
    agencyNotAuthorizedForAgentDesc1: "You are not authorized to reserve bonds at this time. Please contact your underwriter for more assistance!"
}
export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};