import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useReducer, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Button, Typography, Card, Box } from '@mui/material';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { defaultValueApi,searchSubmissionBoxProp, defaultSubmissionListApi,searchSubmissionBox,submissionListHeader, exportSubmissionHeader } from "../../Models/SubmissionModels/listSubmission";
import { headers, apiUrls, apiResponseCode, skipToCheck, componentConst, toastNoiticationConfig, responseCodes, exportFileName, commonConst, dataGridDensity, filterOperators } from "../../Commoncode/constant";
import "../../style/Style.scss";
import PrintExportEmailModal from "../Common/PrintExportEmailModal/PrintExportEmailModal";
import { encryptData,decryptData } from "../../Commoncode/encryptDecrypt";

const ListSubmission = () => {
    const [searchBox, setSearchBox] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    
    const [filteredData, setFilteredData] = useState([]);
    const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
    const [tableRows, settableRows] = useState([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [searchState, searchDispatch] = useReducer(searchReducer, searchSubmissionBox);
  
    const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
    const [exportAllowed, setExportAllowed] = useState(true);
    const [exportData, setExportData] = useState(defaultSubmissionListApi);

    const [subId, setSubId] = useState("");
    
  const size = window.innerWidth;

    useEffect(() => {
        let loggedInUserDetails = UserDetails();
        if (loggedInUserDetails !== false) {
          let submissionData = localStorage.getItem(commonConst.submissionData);
      if (submissionData !== null) {
        var filteredData = decryptData(submissionData);
        setSearchBox(filteredData.Search);
        var filterData = [];
        if (filteredData.FilterData !== null && filteredData.FilterData.length > 0) {
          filteredData.FilterData.map((item, index) => {
            return filterData.push({
              columnField: item.ColumnField,
              operatorValue: item.OperatorValue,
              value: item.Value ? item.Value : null
            });
          });
        }
        setFilteredData(filterData);
        setLinkOperator(filteredData.LinkOperator);
        searchDispatch({ type: searchSubmissionBoxProp.setFilteredData, value: filteredData });
      }
      else {
        searchDispatch({ type: searchSubmissionBoxProp.setData, value: loggedInUserDetails });
      }
            
    }
  }, [])
      
  useEffect(() => {
    submitSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  
  function searchReducer(searchState, action) {
    switch (action.type) {
      case searchSubmissionBoxProp.search:
        return {
          ...searchState, Search: action.value,
        }
      case searchSubmissionBoxProp.pageNumber:
        return {
          ...searchState, PageNumber: action.value,
        }
      case searchSubmissionBoxProp.pageSize:
        return {
          ...searchState, PageNumber: 0, PageSize: action.value,
        }
      case searchSubmissionBoxProp.setData:
        return {
          ...searchState,
          PageSize: 20,
          PageNumber: 0,
          TotalCount: 0,
          SortData: searchSubmissionBoxProp.submissionDate,
          AscDesc: searchSubmissionBoxProp.desc,
          FilterData: [],
          LinkOperator: "",
          Search:"",
          Export: false
        }
      case searchSubmissionBoxProp.setFilteredData:
        return {
          ...searchState,
          PageSize: action.value.PageSize,
          PageNumber: action.value.PageNumber,
          Search: action.value.Search,
          TotalCount: action.value.TotalCount,
          SortData: action.value.SortData,
          AscDesc: action.value.AscDesc,
          FilterData: action.value.FilterData,
          LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator,
          Export: false 
        }
      case searchSubmissionBoxProp.columnFilterData:
        return {
          ...searchState,
          FilterData: action.value,
          LinkOperator: action.operator
        }
      case searchSubmissionBoxProp.sortData:
        return {
          ...searchState,
          SortData: action.value[0].field,
          AscDesc: action.value[0].sort,
          PageNumber: 0
        }
      default:
        return {
          ...searchState
        }
    }
  }

  
  function submitSearch() {
    setSubId("");
    var isDataAvailable = false;

    for (const item in searchState) {
      if (searchState[item] && item !== skipToCheck.pageNo && searchState[item].toString().trim() !== "") {
        isDataAvailable = true;
      }
    }

    if (isDataAvailable) {
      localStorage.setItem(commonConst.submissionData, encryptData(searchState));

      setShowLoader(true);
      axios.post(apiUrls.subList, searchState, { headers: headers })
        .then((response) => {
        setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) 
        {
          settableRows([]);
          settableRows(response.data.submissionListData.submissionListDtls);
          setRowsCount(response.data.submissionListData.totalRecords);
        }
      })
        .catch(error => {
          setShowLoader(false);
        });
      }

  }

  function onFilterChange(filterModel) {
    if(filterModel.items.length >= 2){
      var check = filterModel.items;
      var columnList = []
      for(var item in check){
        if(!columnList.includes(check[item].columnField)){
          
          columnList.push(check[item].columnField)
        }
          else{ 
            if(check[item].value){
              
            toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
              position: toast.POSITION.TOP_RIGHT
            });
            filterModel.items.pop();
            setFilteredData(filterModel.items);
            return;
            }
          }
        }
    }
    setFilteredData(filterModel.items);
    if (filterModel !== null && filterModel.linkOperator !== "") {
      setLinkOperator(filterModel.linkOperator);
    }
    else {
      setLinkOperator(GridLinkOperator.And);
    }

    var filterData = [];
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: searchSubmissionBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });

  }
  
  const callFnPageSize = React.useCallback((newPageSize) => {
    searchDispatch({ type: searchSubmissionBoxProp.pageSize, value: newPageSize });
  }, []);

  const CallPrintExportEmail = () => {
    let exportData = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.subList, exportData, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setExportData(defaultValueApi);
        if (response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.DataTooLarge) {
          setExportAllowed(false);
          setShowPrintExportEmailModal(true);
        }
        else if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          let totalRecords = [];
          if (response.data.submissionListData.submissionListDtls.length > 0) {

            response.data.submissionListData.submissionListDtls.map((item, index) => {
              return totalRecords.push({
                submissionDate: item.submissionDate ? item.submissionDate : '',
                underwriterName: item.underwriterName ? item.underwriterName : '',
                regionName: item.regionName ? item.regionName : '',
                accountName: item.accountName ? item.accountName : '',
                agencyName: item.agencyName ? item.agencyName : '',
                brokerName: item.brokerName ? item.brokerName : '',
                accountTypeName: item.accountTypeName ? item.accountTypeName : '',
                statusName: item.statusName ? item.statusName : '',
                program: item.program ? item.program : '',
                comments: item.comments ? item.comments : '',
                lastUpdateBy: item.lastUpdateBy ? item.lastUpdateBy : '',
                lastUpdate: item.lastUpdate ? item.lastUpdate : ''
              });
            });
            setExportData(totalRecords);
            setExportAllowed(true);
            setShowPrintExportEmailModal(true);
          }
          else
          {
            toast.warning(toastNoiticationConfig.noRecordFound, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
      .catch(error => {
        setShowLoader(false);
      });
  }

  const handleClosePrintExportEmailPopup = () => {
    setShowPrintExportEmailModal(false);
  };

  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.emailSubmissionDetails, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }
  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: searchSubmissionBoxProp.sortData, value: prop });
    }
  }
  
  function onRowChange(event) {
    setSubId(event.row.id);
  }

  function callReset()
  {
    setFilteredData([]);
    setLinkOperator(GridLinkOperator.And);
    setSearchBox("");
    searchDispatch({ type: searchSubmissionBoxProp.setData, value: "" }); 
  }

  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        C&F Submission System
      </Typography>

      <Box className="body-container homePageContainer" noValidate autoComplete="off">
        <div className="table-header">
        <div className="table-header-left">
            <Paper className="paperStyleHome" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >

              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} value={searchBox}
                onKeyPress={(e) => e.key === 'Enter' && searchDispatch({ type: searchSubmissionBoxProp.search, value: e.target.value })} onChange={(e) => { setSearchBox(e.target.value) }} />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => { searchDispatch({ type: searchSubmissionBoxProp.search, value: searchBox }); }}>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Button type="button" variant="contained" className="btnStyling" onClick={(e) => { callReset() }}>Clear Filter</Button> 
            </Paper>

          </div>
          <div className="table-header-right">
            <NavLink style={{ textDecoration: 'none' }} to={componentConst.createSubmission}>  <Button type="button" variant="contained" className="btnStyling">New Submission</Button></NavLink> &nbsp;
            <NavLink style={{ textDecoration: 'none' }} to={subId === "" ? componentConst.submission : componentConst.createSubmission + "?sub=" + subId}>  <Button type="button" variant="contained" className="btnStyling" disabled={subId === "" ? true : false}>Edit Submission</Button> </NavLink>  &nbsp;
            <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallPrintExportEmail}>Export/Email</Button> &nbsp;

          </div>
        </div>

        <div className="table-body">
          <Card sx={{ minWidth: 275 }}>
            <div className="tableContainerHome" style={{ width: '100%' }}>
              <DataGridPro className="muiDataGrid" density={dataGridDensity.compact}
                componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
                components={{ Toolbar: GridToolbar, disableExport: true }}
                disableColumnFilter={false}
                //columnVisibilityModel={{id:false}}
                rows={tableRows}
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                rowCount={rowsCount}
                columns={submissionListHeader}
                rowsPerPageOptions={[20, 50, 100, 500]}
                pagination
                page={searchState.PageNumber}
                pageSize={searchState.PageSize}
                paginationMode="server"
                onPageChange={(data) => {
                  searchDispatch({ type: searchSubmissionBoxProp.pageNumber, value: data });
                }}
                onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
                rowHeight={size >= 1400 ? 50 : 20}
                maxRowHeight={size >= 1400 ? 50 : 20}
                minRowHeight={size >= 1400 ? 50 : 20}
                headerHeight={size >= 1400 ? 50 : 22}
                onRowClick={(e) => onRowChange(e)}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterModel={{
                  items: filteredData,
                  linkOperator: linkOperator,
                }}
              />

            </div>
          </Card>
        </div>
      </Box>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.submissionList} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportSubmissionHeader} closePopup={handleClosePrintExportEmailPopup} sendEmail={callSendEmail}></PrintExportEmailModal>
    </React.Fragment>
  )

}
export default ListSubmission;