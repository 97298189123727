import React from "react";
import { Modal, Box, Button, Typography } from '@mui/material';
import "./ConfirmModel.css";
import "../../../style/Style.scss";
import { popUpStyle } from "../../shared/common";


function ConfirmModel(props) {

    return (
        <>
            <Modal aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                open={props.showConfirmModal}
                keyboard={false}>
                <Box sx={popUpStyle} className="modalScrollable" closeButton>
                    <Typography id="modal-modal-description" variant="h6" className="fontH6" style={{ textAlign: 'center', marginTop: "2%" }} >
                        {props.message}
                    </Typography>
                    <Typography id="modal-continue" sx={{ mt: 2 }} style={{ textAlign: 'center' }} >
                        <Button variant="contained" className="btnStyling" color="success" onClick={() => props.confirmAction(true)}>{props.actionButtonName}</Button>
                        <Button variant="contained" className="btnStyling ml1" color="info" onClick={() => props.closeConfirmModal(false)}>{props.cancelButtonName}</Button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default ConfirmModel;