import React,{useState,useEffect} from "react";
import "./SystemAccess.css";
import "../shared/Header/Header.scss";
import Button from '@mui/material/Button';
import "../../style/Style.scss";
import { componentConst,commonConst,systemAccess } from "../../Commoncode/constant";
import { encryptData} from "../../Commoncode/encryptDecrypt";
import { decryptData } from "../../Commoncode/encryptDecrypt";
import UserDetails from "../../Hooks/UserDetails";

function SystemAccess() {
  const [hasReservationAccess, setHasReservationAccess] = useState(false);  
  const [hasSubmissionAccess, setHasSubmissionAccess] = useState(false);  
  const [hasProjectStatus, setHasProjectStatus] =useState(false);
  useEffect(() => {
    let loggedInUserDetails = UserDetails();
    if (loggedInUserDetails !== false) {
      if(loggedInUserDetails.userRoleSystem !== null)
        {
            var bondSystem = loggedInUserDetails.userRoleSystem.filter(m=>m.systemId === systemAccess.reservation);
            if(bondSystem != null && bondSystem.length > 0)
            {
              setHasReservationAccess(true);
            }
            var submissionSystem = loggedInUserDetails.userRoleSystem.filter(m=>m.systemId === systemAccess.submission);
            if(submissionSystem != null && submissionSystem.length > 0)
            {
              setHasSubmissionAccess(true);
            }
            var projectStatusSystem = loggedInUserDetails.userRoleSystem.filter(m=>m.systemId === systemAccess.projectStatus);
            if(projectStatusSystem != null && projectStatusSystem.length > 0)
            {
              setHasProjectStatus(true);
            }
        }
    }
},[])

    const reservationClick = () => {
        var user = UserDetails();
        if(user.userRoleSystem !== null)
        {
            var system = user.userRoleSystem.filter(m=>m.systemId === systemAccess.reservation);
            if(system != null && system.length >0)
            {
              user.userRoleName = system[0].roleName;
              user.userRoleCode = system[0].roleCode;
              user.userSystem = system[0].systemId;
            }
        }
        localStorage.setItem(commonConst.userInfo, encryptData(user));
        window.location.assign(componentConst.home);
      }
      const submissionClick = () => {
        var user = decryptData(localStorage.getItem(commonConst.userInfo));
        if(user.userRoleSystem !== null)
        {
            var system = user.userRoleSystem.filter(m=>m.systemId === systemAccess.submission);
            if(system != null && system.length >0)
            {
              user.userRoleName = system[0].roleName;
              user.userRoleCode = system[0].roleCode;
              user.userSystem = system[0].systemId;
            }
        }
        localStorage.setItem(commonConst.userInfo, encryptData(user));
        window.location.assign(componentConst.submission);
      }
        const projectStatusClick = () => {
          var user = decryptData(localStorage.getItem(commonConst.userInfo));
          if(user.userRoleSystem !== null)
          {
              var system = user.userRoleSystem.filter(m=>m.systemId === systemAccess.projectStatus);
              if(system != null && system.length >0)
              {
                user.userRoleName = system[0].roleName;
                user.userRoleCode = system[0].roleCode;
                user.userSystem = system[0].systemId;
              }
          }
        localStorage.setItem(commonConst.userInfo, encryptData(user));
        window.location.assign(componentConst.projectStatus);
      }

    return (
        <React.Fragment>
        
        <div className="row justify-content-center home-container">
          <div className="col-md-4 l-middle-container">
            <div className="row">
              <h2 className="d-flex justify-content-center">
                C&F Surety System
              </h2>
            </div>
            {hasReservationAccess && <div className="row">
              <div className="col-md-12 d-flex justify-content-center -option-btn-container">
              <Button variant="contained" className="login-option-btn btnStyling mt1" onClick={reservationClick}>Bond Reservation System</Button>
              </div>
            </div>}

            {hasSubmissionAccess && <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
              <Button variant="contained" className="login-option-btn btnStyling mt1" onClick={submissionClick}>Submission System</Button>

              </div>
            </div>}
            
            {hasProjectStatus && <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
              <Button variant="contained" className="login-option-btn btnStyling mt1" onClick={projectStatusClick}>Project Status System<br/>(Stat Tracker)</Button>

              </div>
            </div>}
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center" >
                &nbsp;
              </div>
            </div>
          </div>
        </div>

        </React.Fragment>
    
      );
}

export default SystemAccess;
