import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { filterOperators } from "../../Commoncode/constant";

export const searchSubmissionBox = {
    Search:"",
    PageNumber: 0,
    PageSize: 0,
    FilterData: [],
    LinkOperator: "",
  
    Export: "",
    TotalCount: 0,
  
    SortData: "",
    AscDesc: ""
  }
  
export const searchSubmissionBoxProp = {
    search: "search",
    pageNumber: "pageNumber",
    pageSize: "pageSize",
    columnFilterData: "columnFilterData",
  
    accountName: "accountName",
    agencyName: "agencyName",
    brokerName: "brokerName",
    underwriterId: "underwriterId",
    statusId: "statusId",
    accountId: "accountId",
    program: "program",
    submissionDate: "submissionDate",
    comment: "comment",
    lastUpdateBy: "lastUpdateBy",
    lastUpdate: "lastUpdate",
  
    contains: "contains",
    equals: "equals",
    startsWith: "startsWith",
    endsWith: "endsWith",
    isEmpty: "isEmpty",
    isNotEmpty: "isNotEmpty",
    isAnyOf: "isAnyOf",
  
    setData: "setData",
    setFilteredData: "setFilteredData",
    TotalCount: "TotalCount",
    sortData: "sortData",
    asc: "asc",
    desc: "desc",

    underwriterName:"underwriterName"
  }
  export const defaultSubmissionListApi = [{
    agencyNo: "",
    agencyName: "",
    regionName: "",
    agencyVarianceRessolved:false,
    userFullName: "",
    dateReserved: "",
    dateBooked: "",
    status: "",
    insured: "",
    internalComment:"",
    profitCenter: "",
    department: "",
    product: "",
    comment: "",
    lastUpdateBy: "",
    lastUpdate: ""
  }]

  export const exportSubmissionHeader =
  [
    { key: 'submissionDate', label: 'Submission Date' },
    { key: 'underwriterName', label: 'Underwriter', width: 205 },
    { key: 'regionName', label: 'Region', width: 100 },
    { key: 'accountName', label: 'Account Name', width: 200 },
    { key: 'accountAddress', label: 'Account Address', width: 220 },
    { key: 'agencyName', label: 'Agency/Broker Name', width: 200 },
    { key: 'accountTypeName', label: 'Account Type', width: 205 },
    { key: 'currentSurety', label: 'Current Surety', width: 205 },
    { key: 'statusName', label: 'Status' },
    { key: 'program', label: 'Program' },
    { key: 'comments', label: 'Comments' },
    { key: 'lastUpdateBy', label: 'Last Update By', width: 205 },
    { key: 'lastUpdate', label: 'Last Update', width: 200 }
  ]
  export const defaultValueApi = [{
    id: "",
    accountName: "",
    agencyName: "",
    brokerName:"",
    underwriterName: "",
    regionName:"",
    accountTypeName: "",
    statusName: "",
    program: "",
    submissionDate: "",
    comments:"",
    lastUpdateBy: "",
    lastUpdate: ""
  }]
  
  export const submissionListHeader =
  [
    
    {
      field: 'id', headerName: 'Log Id', sortable: false,  filterable: false,hideable: false,hide: true
    },
    {
      field: 'submissionDate', headerName: 'Submission Date', type: 'date', sortable: true, width: 150, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.onOrBefore || operator.value === filterOperators.onOrAfter,
      )
    },
    {
      field: 'underwriterName', headerName: 'Underwriter', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'regionName', headerName: 'Region', sortable: true, width: 200, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'accountName', headerName: 'Account Name', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'accountAddress', headerName: 'Account Address', sortable: true, width: 360, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'agencyName', headerName: 'Agency/Broker Name', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'accountTypeName', headerName: 'Account Type', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'currentSurety', headerName: 'Current Surety', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'statusName', headerName: 'Status', sortable: true, width: 200, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'program', headerName: 'Program', sortable: true, width: 287, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'comments', headerName: 'Comments', sortable: true, width: 450, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'lastUpdateBy', headerName: 'Last Updated By', sortable: true, width: 200, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'lastUpdate', headerName: 'Last Update', type: 'date', sortable: true, width: 150, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after,
      )
    }
  ]