import React,{useState} from "react";
import { CSVLink } from "react-csv";
import { Modal, Box, Button, Typography } from '@mui/material';
import "../../../style/Style.scss";
import { popUpStyle } from "../../shared/common";
import { printEmailExportHeading } from "../../../Commoncode/constant";

function PrintExportEmailModal(props) {

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        props.closePopup(false);
    };

    const handleCloseAlert=()=>{
        setShowModal(false);
    }

    const callExportAlert = () =>
    {
        setShowModal(true);
    }

    const sendEmailByExport = () =>
    {
        setShowModal(false);
        document.getElementById("btnEmail").click();
    }

    return (
        <>
            <Modal aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                open={props.showDialogModal}
                onClose={handleClose}
                keyboard={false}>
                <Box sx={popUpStyle} className="modalScrollable" closeButton>
                    <Typography id="modal-modal-title" variant="h6" className="fontH6" component="h2" style={{ textAlign: 'center' }}>
                        {printEmailExportHeading.heading}
                    </Typography>
                    <Typography id="modal-continue" sx={{ mt: 2 }} style={{ textAlign: 'center' }} >
                       {props.isExportValid && <CSVLink filename={props.filename} style={{ textDecoration: 'none' }} data={props.data} headers={props.headers}> <Button variant="contained" color="success" className="btnStyling ml1" onClick={props.closeExportPopup}>Export</Button> </CSVLink>}
                       {!props.isExportValid && <Button variant="contained" className="btnStyling ml1" color="success" onClick={callExportAlert}>Export</Button>}
                        <Button id="btnEmail" variant="contained" className="btnStyling ml1" color="success" onClick={props.sendEmail}>Email</Button>
                        <Button variant="contained" className="btnStyling ml1" color="info" onClick={props.closePopup}>Cancel</Button>
                    </Typography>
                </Box>
            </Modal>

            <Modal aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                open={showModal}
                onClose={handleCloseAlert}
                keyboard={false}>
                <Box sx={popUpStyle} className="modalScrollable" closeButton>
                    <Typography id="modal-modal-title" variant="h6" className="fontH6" component="h2" style={{ textAlign: 'center' }}>
                        {printEmailExportHeading.dataTooLarge}
                    </Typography>
                    <Typography id="modal-continue" sx={{ mt: 2 }} style={{ textAlign: 'center' }} >
                        <Button variant="contained" className="btnStyling ml1" color="success" onClick={sendEmailByExport}>Email</Button>
                        <Button variant="contained" className="btnStyling ml1" color="info" onClick={handleCloseAlert}>Cancel</Button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default PrintExportEmailModal;