import React from "react";
import "./LoginOption.css";
import logo from '../../assets/logo.png';
import "../shared/Header/Header.scss";
import Button from '@mui/material/Button';
import { apiUrls } from "../../Commoncode/constant";
import "../../style/Style.scss";
import { commonConst } from "../../Commoncode/constant";
import { decryptData } from "../../Commoncode/encryptDecrypt";


function LoginOption() {

  const handleClick = () => {
    window.open(apiUrls.getAgentLoginApiUrl, "_self");
  }
  const userHandleClick = () => {
    window.open(apiUrls.getInternalUserLoginApiUrl, "_self");
  }

  let LockUserMsg = localStorage.getItem(commonConst.msg) === null ? null : decryptData(localStorage.getItem(commonConst.msg));
  if (LockUserMsg !== "") {
    localStorage.removeItem(commonConst.msg);

  }
  return (
    <React.Fragment>
      <div className="bodyLogin">
        <div className="top-container">
          <div className="navbar-wrapper">
            <div className="logo">
              <img src={logo} alt="" className="img-logo" />
            </div>
          </div>
          <div className="navbar-collapse">
          </div>
        </div>


        <div className="row justify-content-center home-container">
          <div className="col-md-4 l-middle-container">
            <div className="row">
              <h2 className="d-flex justify-content-center">
                C&F Surety System
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center -option-btn-container">
                <Button variant="contained" className="login-option-btn btnStyling mt1" onClick={userHandleClick}>C&F Login</Button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <Button variant="contained" className="login-option-btn btnStyling mt1" onClick={handleClick}>Agent Login</Button>

              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center" >
                &nbsp;
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center lockUser" >
                <p id="msg" >{LockUserMsg}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
}
export default LoginOption;
