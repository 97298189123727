import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { componentConst,filterOperators } from "../Commoncode/constant";
import { NavLink } from "react-router-dom";
import { Button } from '@mui/material';

export const tableHeader =
  [
    {
      field: 'bondNo', headerName: 'Bond', width:146, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
      ,renderCell: (params) => 
         (<NavLink to={componentConst.updateBond + "?bondnum=" + params.row.bondNo}>  <Button type="button">{params.row.bondNo}</Button> </NavLink>)
    },
    {
      field: 'dateReserved', headerName: 'Date Reserved', width: 115, sortable: true, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after,
      )
    },
    {
      field: 'dateBooked', headerName: 'Date Booked', width: 115, sortable: true, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'bookedAgency', headerName: 'Agency Booked', width: 280, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'reservedAgency', headerName: 'Agency Reserved', width: 280, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'profitCenter', headerName: 'Profit Center', width: 280, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    }
  ]

export const requestProp = {
  UserName: "",
  UserRole: 0,
  PageNumber: 0,
  PageSize: 20,
  Export: false,

  SortData: "",
  AscDesc: "",

  FilterData: [],
  LinkOperator: ""
}
export const requestPropCheck = {

  pageNumber: "pageNumber",
  pageSize: "pageSize",
  sortData: "sortData",
  ascDesc: "ascDesc",
  setData: "setData",
  setFilteredData: "setFilteredData",
  columnFilterData: "columnFilterData"
}

export const responseProp = {
  bondNo: "",
  dateReserved: "",
  dateBooked: "",
  bookedAgency: "",
  reservedAgency: "",
  profitCenter: "",
  totalCount: 0,
}
export const exportHeader = [
  { key: 'bondNo', label: 'Bond No', width: 150 },
  { key: 'dateReserved', label: 'Date Reserved', width: 150 },
  { key: 'dateBooked', label: 'Date Booked', width: 150 },
  { key: 'bookedAgency', label: 'Agency Booked', width: 150 },
  { key: 'reservedAgency', label: 'Agency Reserved', width: 150 },
  { key: 'profitCenter', label: 'Profit Center', width: 150 }
]
export const defaultExportValues = [{
  bondNo: "",
  dateReserved: "",
  dateBooked: "",
  bookedAgency: "",
  reservedAgency: "",
  profitCenter: ""
}]