import { read, utils } from 'xlsx';
export function downloadCSVFile(content, fileName,mimeType,fileInputType) {

    const linkSource = `data:${mimeType};${fileInputType},${content}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click(); 
}

export const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\r\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");
    csvRows.pop();
    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    return array;
  };

export  const arrayToCSVFile = (inputArray,headerString="") =>{
    var csv = inputArray.map(function(d){
      return JSON.stringify(Object.values(d));
      })
        .join('\n') 
        .replace(/(^\[)|(\]$)/mg, '');
        if(headerString !== ""){
          csv = headerString+"\n"+csv;
        }
      return csv;
  }

  export const xlsxToArray = (string,rangeStart ="A",rangeEnd="")=>{
    const wb = read(string,{type:'binary',cellText:false,cellDates:true});
    const ws = wb.Sheets[wb.SheetNames[0]];
    var range = utils.decode_range(ws['!ref']);
    range.s.c = utils.decode_col(rangeStart)
    if(rangeEnd !=""){
      range.e.c = utils.decode_col(rangeEnd);
    }
    var new_range = utils.encode_range(range);
    const data = utils.sheet_to_json(ws,{header:1,range:new_range,raw:false,dateNF: 'yyyy"-"MM"-"dd'});
    return data;
}