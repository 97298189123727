import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { filterOperators } from "../../Commoncode/constant";

export const defaultMonthDropdown=
  {key:0,value:"YTD"}


export const searchMonthDropdown=[
  {key:0,value:"YTD"},
  {key:1,value:"Prior Year"},
  {key:2,value:"Current Month"},
  {key:3,value:"Prior Month"},
  {key:4,value:"Custom Date Filter"}
]

export const searchUWSubmissionBox = {
    Search:"",
    PageNumber: 0,
    PageSize: 0,
    FilterData: [],
    LinkOperator: "",
  
    Export: "",
    TotalCount: 0,
  
    SortData: "",
    AscDesc: "",

    Month:0,
    FromDate :"",
    ToDate:""
  }
  export const searchUWSubmissionBoxProp = {
    search: "search",
    pageNumber: "pageNumber",
    pageSize: "pageSize",
    columnFilterData: "columnFilterData",
  
    underwriterName: "underwriterName",
    totalSubmissions: "totalSubmissions",
    totalApprovedWritten: "totalApprovedWritten",
    totalApproved: "totalApproved",
    totalPending: "totalPending",
    totalDeclined: "totalDeclined",
    totalNotAcquired: "totalNotAcquired",
  
    contains: "contains",
    equals: "equals",
    startsWith: "startsWith",
    endsWith: "endsWith",
    isEmpty: "isEmpty",
    isNotEmpty: "isNotEmpty",
    isAnyOf: "isAnyOf",
  
    setData: "setData",
    setFilteredData: "setFilteredData",
    TotalCount: "TotalCount",
    sortData: "sortData",
    asc: "asc",
    desc: "desc",

    Month:"Month",
    fromDate:"fromDate",
    toDate:"toDate",
  }
  export const defaultUWSubmissionListApi = [{
    underwriterName: "",
    totalSubmissions: 0,
    totalApprovedWritten: 0,
    totalApproved: 0,
    totalPending: 0,
    totalDeclined: 0,
    totalNotAcquired: 0,
  }]
  export const exportUWSubmissionHeader =
  [
    { key: 'underwriterName', label: 'Underwriter', width: 205 },
    { key: 'totalSubmissions', label: 'Total Submission', width: 200 },
    { key: 'totalApprovedWritten', label: 'Total Approved Written', width: 220 },
    { key: 'totalApproved', label: 'Total Approved', width: 200 },
    { key: 'totalPending', label: 'Total Pending', width: 205 },
    { key: 'totalDeclined', label: 'Total Declined' },
    { key: 'totalNotAcquired', label: 'Total Not Acquired' }
  ]
  export const uwSubmissionListHeader =
  [
    {
      field: 'underwriterName', headerName: 'Underwriter', sortable: true, width: 250, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    { field: 'totalSubmissions', headerName: 'Total Submission', sortable: true,width: 250,  filterable: false },
    { field: 'totalApprovedWritten', headerName: 'Total Approved Written', sortable: true,width: 200,  filterable: false },
    { field: 'totalApproved', headerName: 'Total Approved', sortable: true, width: 200, filterable: false },
    { field: 'totalPending', headerName: 'Total Pending', sortable: true,width: 200,  filterable: false },
    { field: 'totalDeclined', headerName: 'Total Declined', sortable: true,width: 200,  filterable: false },
    { field: 'totalNotAcquired', headerName: 'Total Not Acquired', sortable: true,width: 200,  filterable: false },
]


  
