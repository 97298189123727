import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import home from "../src/component/home/Home";
import LoginOption from "./component/loginOption/LoginOption";
import LoginMiddleware from "./component/login/LoginMiddleware";
import SystemAccess from "./component/systemAccess/SystemAccess";
import ProtectedRoute from "./authentication/ProtectedRoute";
import './App.css';
import "../src/component/shared/common.scss";
import reservebond from '../src/component/reserveBond/ReserveBond';
import { componentConst, licenseKey } from '../src/Commoncode/constant';
import DisplayReserveBond from '../src/component/displayReserveBond/DisplayReserveBond';
import UpdateBond from '../src/component/updateBond/UpdateBond';
import BondThirtyDaysReport from '../src/component/bondThirtyDaysReport/BondThirtyDaysReport';
import AgencyVariance from '../src/component/agencyVariance/AgencyVariance';
import { LicenseInfo } from '@mui/x-license-pro';
import Utility from './component/utility/Utility';
import Dashboard from './component/utility/agencyUtility/Dashboard';
import AgentDashboard from './component/utility/agentUtility/AgentDashboard';
import AddUpdateAgency from './component/utility/agencyUtility/AddUpdateAgency';
import AddUpdateAgent from './component/utility/agentUtility/AddUpdateAgent';
import BondStatusKey from './component/utility/BondStatusKey';
import CreateSubmission from "./component/createSubmission/CreateSubmission";
import ListSubmission from "./component/listSubmission/ListSubmission";
import SubmissionUW from "./component/submissionUW/SubmissionUW";
import ProjectStatusHome from './component/projectStatus/Home/ProjectStatusHome';
import AddUpdateProjectStatus from './component/projectStatus/AddUpdateProjectStatus';
import UploadProjectStatus from './component/projectStatus/UploadProjectStatus';
import BondUploadDashBoard from './component/utility/bondUpload/BondUploadDashboard';
import UserDashboard from './component/utility/user/UserDashboard';
import AddUpdateUser from './component/utility/user/AddUpdateUser';
LicenseInfo.setLicenseKey(licenseKey.key);

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path={componentConst.loginOption} component={LoginOption} />
          <Route path={componentConst.loginMiddleware} component={LoginMiddleware} />
          <ProtectedRoute path={componentConst.home} exact component={home} auth={true} />
          <ProtectedRoute path={componentConst.reserveBond} component={reservebond} auth={true} />
          <ProtectedRoute path={componentConst.displayReserveBond} component={DisplayReserveBond} auth={true} />
          <ProtectedRoute path={componentConst.updateBond} component={UpdateBond} auth={true} />
          <ProtectedRoute path={componentConst.agencyVarianceReport} component={AgencyVariance} auth={true} />
          <ProtectedRoute path={componentConst.bondThirtyDaysReport} component={BondThirtyDaysReport} auth={true} />
          <ProtectedRoute path={componentConst.utility} component={Utility} auth={true} />
          <ProtectedRoute path={componentConst.agency} component ={Dashboard} auth ={true}/>
          <ProtectedRoute path={componentConst.addUpdateAgency} component ={AddUpdateAgency} auth ={true}/>
          <ProtectedRoute path={componentConst.addUpdateAgent} component ={AddUpdateAgent} auth ={true}/>
          <ProtectedRoute path={componentConst.key} component ={BondStatusKey} auth ={true}/>
          <ProtectedRoute path={componentConst.systemAccess} component ={SystemAccess} auth ={true}/>
          <ProtectedRoute path={componentConst.createSubmission} component ={CreateSubmission} auth ={true}/>
          <ProtectedRoute path={componentConst.submission} component ={ListSubmission} auth ={true}/>
          <ProtectedRoute path={componentConst.uwsubmission} component ={SubmissionUW} auth ={true}/>
          <ProtectedRoute path={componentConst.agent} component ={AgentDashboard} auth ={true}/>
          <ProtectedRoute path={componentConst.projectStatus} component={ProjectStatusHome} auth={true}/>
          <ProtectedRoute path={componentConst.addUpdateProjectStatus} component={AddUpdateProjectStatus} auth={true}/>
          <ProtectedRoute path ={componentConst.uploadProjectStatus} component={UploadProjectStatus} auth={true}/>
          <ProtectedRoute path={componentConst.bondUpload} component ={BondUploadDashBoard} auth ={true}/>
          <ProtectedRoute path={componentConst.users} component ={UserDashboard} auth ={true}/>
          <ProtectedRoute path={componentConst.addUpdateUser} component ={AddUpdateUser} auth ={true}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
