import axios from "axios";
import React,{ useEffect,useState,useReducer  } from "react";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography,Card, Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LoaderComponent from "../../Common/Loader/LoaderComponent";
import {agencyTableHeader,searchBoxProp} from "../../../Models/agencyUtility";
import Button from '@mui/material/Button';
import { headers, apiUrls,responseCodes,apiResponseCode,componentConst,skipToCheck,filterOperators,dataGridDensity,toastNoiticationConfig} from "../../../Commoncode/constant"
const Dashboard = ({location}) =>{
    const [showLoader, setShowLoader] = useState(false);
    const [searchBox, setSearchBox] = useState("");
    const [agencyList,setAgencyList] = useState([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
    const [filteredData, setFilteredData] = useState([]);
    const [searchState, searchDispatch] = useReducer(searchReducer, searchBox);
    const size = window.innerWidth;    

    useEffect(()=>{
      var result =JSON.parse(window.localStorage.getItem('filterModel'));
      if(result){
        var filterData = [];
        setSearchBox(result.Search);
        if (result.FilterData !== null && result.FilterData.length > 0) {
          result.FilterData.map((item, index) => {
            return filterData.push({
              columnField: item.ColumnField,
              operatorValue: item.OperatorValue,
              value: item.Value ? item.Value : null
            });
          });
        }
        setFilteredData(filterData);
        setLinkOperator(result.LinkOperator);
        searchDispatch({ type: searchBoxProp.setFilteredData, value: result });
      }else {
        searchDispatch({ type: searchBoxProp.setData});
      }
      if(location.state != null && location.state !== ""){
        toast.success(location.state, {
          position: toast.POSITION.TOP_RIGHT
        });
      }    
    },[]);

    useEffect(() => {
      getAgencyValue();
    }, [searchState])

    function getAgencyValue(){
      var isDataAvailable = false;
  
      for (const item in searchState) {
        if (searchState[item] && item !== skipToCheck.pageNo && searchState[item].toString().trim() !== "") {
          isDataAvailable = true;
        }
      }
  
      if (isDataAvailable) {
        window.localStorage.setItem('filterModel', JSON.stringify(searchState));
        setShowLoader(true);

      axios.post(apiUrls.getAgencyDetails,searchState, { headers: headers })
        .then((response) => {
            setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
        {
          var result = response.data.agencyDetail;
          setAgencyList(result);
          setRowsCount(response.data.totalCount);
        }
      })
        .catch(error => {
          setShowLoader(false);
        });
      }
    }

    function searchAgency(){
        getAgencyValue();
    }
    function searchReducer(searchState, action) {
      switch (action.type) {
        case searchBoxProp.search:
          return {
            ...searchState, Search: action.value,
          }
        case searchBoxProp.pageNumber:
          return {
            ...searchState, PageNumber: action.value,
          }
        case searchBoxProp.pageSize:
          return {
            ...searchState, PageNumber: 0, PageSize: action.value,
          }
        case searchBoxProp.setData:
          return {
            ...searchState,
            PageSize: 20,
            PageNumber: 0,
            Search: "",
            TotalCount: 0,
            SortData: searchBoxProp.adNumber,
            AscDesc: searchBoxProp.desc,
            FilterData: [],
            LinkOperator: ""
          }
        case searchBoxProp.setFilteredData:
          return {
            ...searchState,
            PageSize: action.value.PageSize,
            PageNumber: action.value.PageNumber,
            Search: action.value.Search,
            TotalCount: action.value.TotalCount,
            SortData: action.value.SortData,
            AscDesc: action.value.AscDesc,
            FilterData: action.value.FilterData,
            LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator
          }
        case searchBoxProp.columnFilterData:
          return {
            ...searchState,
            FilterData: action.value,
            LinkOperator: action.operator
          }
        case searchBoxProp.sortData:
          return {
            ...searchState,
            SortData: action.value[0].field,
            AscDesc: action.value[0].sort,
            PageNumber: 0
          }
        default:
          return {
            ...searchState
          }
      }
    }
    
    function changePageSize(newPageSize){
      searchDispatch({ type: searchBoxProp.pageSize, value: newPageSize });
    }
    function onFilterChange(filterModel) {
      if(filterModel.items.length >= 2){
        var check = filterModel.items;
        var columnList = []
        for(var item in check){
          if(!columnList.includes(check[item].columnField)){
            
            columnList.push(check[item].columnField)
          }
            else{ 
              if(check[item].value){
                
              toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
                position: toast.POSITION.TOP_RIGHT
              });
              filterModel.items.pop();
              setFilteredData(filterModel.items);
              return;
              }
            }
          }
      }
      setFilteredData(filterModel.items);
      if (filterModel !== null && filterModel.linkOperator !== "") {
        setLinkOperator(filterModel.linkOperator);
      }
      else {
        setLinkOperator(GridLinkOperator.And);
      }
  
      var filterData = [];
      if (filterModel.items !== null && filterModel.items.length > 0) {
        filterModel.items.map((item, index) => {
          if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
            return filterData.push({
              ColumnField: item.columnField,
              OperatorValue: item.operatorValue,
              Value: item.value ? item.value : null
            });
          }
          else {
            return filterData;
          }
        });
      }
      searchDispatch({ type: searchBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });
    }
    const handleSortModelChange = (prop) => {
      if (prop != null && prop.length > 0) {
        searchDispatch({ type: searchBoxProp.sortData, value: prop });
      }
    }
    return (
        <React.Fragment>
          <Typography variant="h5" className="pageHeading">
            Agency
          </Typography>
          <Box className="body-container homePageContainer" noValidate autoComplete="off" >
            <div className="table-header">
              <div className="table-header-left">
                <Paper className="paperStyleHome" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >
                <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} value={searchBox}
                onKeyPress={(e) => e.key === 'Enter' && searchDispatch({ type: searchBoxProp.search, value: e.target.value })} onChange={(e) => { setSearchBox(e.target.value) }} />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => { searchDispatch({ type: searchBoxProp.search, value: searchBox }); }}>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
          </div>
          
        <div className="table-header-right">
          <NavLink to={{pathname:componentConst.addUpdateAgency}}><Button type="button" variant="contained" className="btnStyling">Add</Button></NavLink>&nbsp;

        </div>
        </div>
        <div className="table-body">
          <Card sx={{ minWidth: 275 }}>
            <div className="tableContainerHome" style={{ width: '100%' }}>
            
              <DataGridPro 
                getRowId={(row) => row.adId}
                density={dataGridDensity.compact}
                componentsProps={
                                  { toolbar: 
                                    { printOptions: 
                                      { disableToolbarButton: true }, 
                                      csvOptions: { disableToolbarButton: true } 
                                    } 
                                  } 
                                }
                components={{ Toolbar: GridToolbar, disableExport: true }}
                className="muiDataGrid" 
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                disableColumnFilter={false}
                columns = {agencyTableHeader}
                rows={agencyList}
                rowsPerPageOptions={[20, 50, 100, 500]}
                rowCount={rowsCount}
                rowHeight={size >= 1400 ? 50 : 20}
                maxRowHeight={size >= 1400 ? 50 : 20}
                minRowHeight={size >= 1400 ? 50 : 20}
                headerHeight={size >= 1400 ? 50 : 22}
                page={searchState.PageNumber}
                pageSize={searchState.PageSize}
                paginationMode="server"
                disableSelectionOnClick ={true}
                onPageChange ={(data) => {
                  searchDispatch({ type: searchBoxProp.pageNumber, value: data });
                }}
                onPageSizeChange={(newPageSize) =>changePageSize(newPageSize)}
                pagination
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterModel={{
                  items: filteredData,
                  linkOperator: linkOperator,
                }}
              />
            </div>
          </Card>
        </div>
          </Box>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
      )
}
export default Dashboard;