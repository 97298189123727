import React from "react";
import IsUserAuthenticated from "../../Hooks/IsUserAuthenticated";
import axios from "axios";
import { apiUrls, apiResponseCode, headers, commonConst, componentConst, errorMessageConst, responseCodes,systemAccess } from "../../Commoncode/constant";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import {setDateTimeZone} from "../../Hooks/DateUtility";
import { encryptData} from "../../Commoncode/encryptDecrypt";
import { decryptData } from "../../Commoncode/encryptDecrypt";

function LoginMiddleware(props) {
    let isUserLoggedin = IsUserAuthenticated();
    let id_token = null;
    let token_expires_in = null;

    if (props.location.hash) {
        const hash = props.location.hash.replace(/#/g, "?");
        const params = new URLSearchParams(hash);
        id_token = params.get(commonConst.idToken);
        token_expires_in = params.get(commonConst.expiresIn);
        localStorage.setItem(commonConst.idToken, id_token);

    }

    if (id_token) {
        headers.Authorization = id_token;
        axios.post(apiUrls.getUserInfoApiUrl, {}, {
            headers: headers
        })
            .then((response) => {
                if (response.data.responseMessages[0].responseMessage.length > 0 && response.data.responseMessages[0].responseCode === apiResponseCode.LockedUser) {
                    localStorage.setItem(commonConst.msg, encryptData(errorMessageConst.blockedUser));
                    window.location.assign(componentConst.loginOption);
                }
                else if (response.data.responseMessages[0].responseMessage.length > 0 && response.data.responseMessages[0].responseCode === apiResponseCode.UserNotAuthorize) {
                    localStorage.setItem(commonConst.msg, encryptData(errorMessageConst.internalServerError));
                    window.location.assign(componentConst.loginOption);
                }
                else if (response.status === responseCodes.success && response.data.responseMessages[0].responseMessage.length > 0 && response.data.responseMessages[0].responseCode === apiResponseCode.SuccessRequest) {
                    var user = {
                        userName: response.data.userDetails.userName,
                        userFirstName: response.data.userDetails.userFirstName,
                        userLastName: response.data.userDetails.userLastName,
                        userEmail: response.data.userDetails.userEmail,
                        userRoleName: "",
                        userRoleCode: 0,
                        userSystem:0,
                        userRoleSystem: response.data.userDetails.userRoleSystem,
                        userIsReserveBondDisabled: response.data.userDetails.userIsReserveBondDisabled,
                        userRolePrivileges: response.data.userDetails.userRolePrivileges,
                        userAgencyName: response.data.userDetails.userAgencyName,
                        userAgencyNumber: response.data.userDetails.userAgencyNumber,
                        token: id_token,
                        validTime: token_expires_in,
                        createTime: setDateTimeZone(new Date()).toString()
                    };
                    if(user.userRoleSystem !== null && user.userRoleSystem.length === 1)
                    {
                        user.userRoleName = user.userRoleSystem[0].roleName;
                        user.userRoleCode = user.userRoleSystem[0].roleCode;
                        user.userSystem = user.userRoleSystem[0].systemId;

                        localStorage.setItem(commonConst.userInfo, encryptData(user));
                        localStorage.setItem(commonConst.lastMovement, encryptData(new Date()));
                        isUserLoggedin = true;

                        if(systemAccess.submission === user.userSystem)
                        {
                            window.location.assign(componentConst.submission);
                        }
                        if(systemAccess.projectStatus === user.userSystem)
                        {
                            window.location.assign(componentConst.projectStatus);
                        }
                        if(systemAccess.reservation === user.userSystem)
                        {
                            window.location.assign(componentConst.home);
                        }
                    }
                    else
                    {
                        localStorage.setItem(commonConst.userInfo, encryptData(user));
                        localStorage.setItem(commonConst.lastMovement, encryptData(new Date()));
                        isUserLoggedin = true;

                        window.location.assign(componentConst.systemAccess);
                    }
                }
                else {
                    window.location.assign(componentConst.loginOption);
                }
            }, (error) => {
                console.log(error);
                localStorage.setItem(commonConst.msg, encryptData(errorMessageConst.internalServerError));
                window.location.assign(componentConst.loginOption);
            });

    }
    if (isUserLoggedin) {
        let user = localStorage.getItem(commonConst.userInfo) === null ? null : decryptData(localStorage.getItem(commonConst.userInfo));
        if(user.userSystem === systemAccess.reservation)
        {
            window.location.assign(componentConst.home);
        }
        else if(user.userSystem === systemAccess.submission)
        {
            window.location.assign(componentConst.submission);
        }else if(user.userSystem === systemAccess.projectStatus)
        {
            window.location.assign(componentConst.projectStatus);
        }

        localStorage.removeItem(componentConst.msg);
        localStorage.removeItem(commonConst.homeData);
        localStorage.removeItem(errorMessageConst.blockedUser);
        localStorage.removeItem('filterModel');
    }
    else if (!id_token) {
        localStorage.removeItem(commonConst.userInfo);
        localStorage.removeItem(commonConst.refrenceData);
        localStorage.removeItem(commonConst.homeData);
        localStorage.removeItem(errorMessageConst.blockedUser);
        window.location.assign(componentConst.loginOption);
        localStorage.removeItem('filterModel');
    }
    else {
        return (<LoaderComponent show={true}></LoaderComponent>);
    }
}
export default LoginMiddleware;