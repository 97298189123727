export const licenseKey = {
    key: process.env.REACT_APP_MIUIKey
}
export let headers = {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('id_token')
}
export const commonConst = {

    idToken: 'id_token',
    userInfo: 'userInfo',
    expiresIn: 'expires_in',
    refrenceData: 'refrenceData',
    msg: 'msg',
    homeData: "homeData",
    lastMovement: "lastMovement",
    currentSystemAccess: "currentSystemAccess",
    submissionData: "submissionData",
    uwSubmissionData: "uwSubmissionData",
    projectStatusData:"projectStatusData"
}
export const apiUrls = {
    getAgentLoginApiUrl: process.env.REACT_APP_LOGIN_URL_AGENT,
    getAgentLogOutApiUrl: process.env.REACT_APP_LOGOUT_URL_AGENT,
    getInternalUserLoginApiUrl: process.env.REACT_APP_LOGIN_URL_INTERNAL_USER,
    getInternalUserLogOutApiUrl: process.env.REACT_APP_LOGOUT_URL_INTERNAL_USER,
    getUserInfoApiUrl: process.env.REACT_APP_API_BASE_URL + 'User/GetInfo',
    getHomePageApiUrl: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/gethomepagedetails',
    getAgentList: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getagencylist',
    getReserveBondScenarioDetails: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getscenariodetails',
    getReserveBondInformation: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/genearategetbonddetails',
    getReserveBondInformationNyRequestId: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getbonddetails',
    getBondUpdateReservationDetails: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getupdatebonddetails',
    postBondUpdateReservationDetails: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/updatebonddetails',
    bindDepartmentByProfitCenter: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getdepartmentbonddetails',
    bindProductByDepartment: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getproductbonddetails',
    emailHomeDetails: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getemailbonddetails',
    agencyVarianceReport: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getagencyvariancereportdetails',
    emailAgencyVarianceReport: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getagencyvarianceemailsdetails',
    emailReserveBondInformation: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getdisplaybondemaildetails',
    get30DaysReportApiUrl: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getthirtydaysreportdetails',
    email30DaysReport: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/getthirtydaysreportemaildetails',
    getUtilityDetails: process.env.REACT_APP_SERVERLESS_API_BASE_URL_HOME + 'bond/GetUtilityDetails',
    getAgencyDetails: process.env.REACT_APP_API_Utility_URL + 'Utility/GetAgencyDetails',
    insertAgencyDetails: process.env.REACT_APP_API_Utility_URL + 'Utility/InsertAgencyDetails',
    getAgentUserList: process.env.REACT_APP_API_Utility_URL + 'Agent/GetUserList',
    getUserRolesList: process.env.REACT_APP_API_Utility_URL + 'Agent/GetUserRoles',
    getSystemAcessList:process.env.REACT_APP_API_Utility_URL + 'Agent/GetSystemAccessList',
    addAgent: process.env.REACT_APP_API_Utility_URL + 'Agent/AddAgent',
    addUser: process.env.REACT_APP_API_Utility_URL + 'Agent/AddUser',
    updateAgent: process.env.REACT_APP_API_Utility_URL + 'Agent/UpdateAgentDetails',
    updateUser: process.env.REACT_APP_API_Utility_URL + 'Agent/UpdateUserDetails',
    getBondStatusKey: process.env.REACT_APP_API_Utility_URL + 'Utility/GetBondStatusKeys',
    validateBondDetails: process.env.REACT_APP_API_Utility_URL +'Utility/ValidateBondDetails',
    submitBondDetails: process.env.REACT_APP_API_Utility_URL +'Utility/SubmitBondDetails',
    getUserDetails:process.env.REACT_APP_API_SUBMISSION_URL + 'Utility/GetUserDetails',
    subGetMasterList: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/bindlist',
    subCreateDetails: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/createsubmissiondetails',
    subUpdateDetails: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/updatesubmissiondetails',
    subList: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/submissionlist',
    subDetails: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/getsubmissiondetails',
    emailSubmissionDetails: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/getemailsubmissiondetails',
    uwSubList: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/GetUwSubmission',
    uwEmailSubmissionDetails: process.env.REACT_APP_API_SUBMISSION_URL + 'submission/emailuwsubmission',
    getUAList:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/GetUa',
    getUnderWriterListUrl:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/GetUnderWriter',
    getPSStatusList:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/GetPsStatus',
    insertProjectStatusDetails:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/AddProjectStatus',
    updateProjectStatusDetails:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/UpdateProjectStatus',
    getProjectStatusDetails:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/GetProjectStatus',
    validateProjectStatusDetails:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/ValidateProjectStatus',
    deleteProjectStatusDetails:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/DeleteProjectStatus',
    emailProjectStatus:process.env.REACT_APP_API_PROJECTSTATUS_URL+'ProjectStatus/SendProjectStatusEmail'
}

export const eventsConst = {
    onChange: "onChange",
    onBlur: "onBlur"
}

export const errorMessageConst = {
    emptyTextBox: "Please enter value",
    unselectedDropdown: "Please select an option",
    unselectedDate: "Please select date",
    unselectedRadio: "Please select an option",
    invalidEmailId: "Please enter a valid email address",
    minimumPolicyLength: "Minimum Policy Length is 9",
    blockedUser: "User has been locked. Please check with your administrator",
    internalServerError:"Internal server error"
}


export const confirmModalMessage = {
    deleteMessage: "Are you sure want to delete this record?",
    successMessage: "Are you sure want to save the changes?",
    updateMessage: "Are you sure want to update the changes?",
    cancelMessage: "Are you sure want to discard the changes?",
    actionNameDelete: "Delete",
    actionNameSave: "Save",
    actionNameYes: "Yes",
    actionNameNo: "No",
    actionNameUpdate: "Update",
    logOutMessage: "Are you sure you want to log out?",
    okBtn: "OK",
    cancelBtn: "Cancel",
    modifyBondDetails: "Are you sure you want to modify bond details?"
}



export const toastNoiticationConfig = {
    success: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
        theme: "light"
    },

    error: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
        theme: "light"
    },
    commentRequired: "Please add comment.",
    bondUpdatedSuccessfully: "Bond updated successfully.",
    bookingDateRequired: "Date booked is required.",
    bookingAgencyRequired: "Booked agency is required.",
    emailSentSuccessfully: "Email sent successfully.",
    emailNotSentSuccessfully: "Email not sent successfully.",
    agencyRequired: "Please select agency.",
    exportSuccessfully: "File exported successfully.",
    noRecordFound: "No record found.",
    internalServerError: "Internal server error.",
    recordCreatedSuccessfully: "Record created successfully.",
    recordUpdatedSuccessfully: "Record updated successfully.",
    recordDeletedSuccessfully: "Record deleted successfully.",
    duplicateGridFilterMessage: "Same Filter can't be selected.",
    projectStatusHeaderCheckMessage:"File Header is incorrect",
    bondUploadHeaderCheckMessage:"Csv header are Incorrect.Please download correct csv format.",
    duplicateSystemNotAllowed:"Same System cannot be selected"
}


export const privileges = {
    search: "search",
    read: "read",
    create: "create",
    edit: "edit",
    delete: "delete",
    assignRoles: "assign roles",
    generateReports: "generate reports"
}

export const validationConst = {
    minEffectiveDate: "2019-01-01"
}

export const skipToCheck = {
    isFormUpdated: 'isFormUpdated',
    pageNo: 'pageNo'
};

export const roleName = {
    agent: "Agent",
    ua: "UA",
    underwriter: "Underwriter",
    uaSupervisor: "UASupervisor",
    admin: "Admin",
    inquiry: "Inquiry"
};

export const apiResponseCode = {
    UnhandledException: "SRB999",
    SuccessRequest: "SRG001",
    ValidationErrors: "SRG002",
    RecordNotFound: "SRD404",
    UserNotAuthorize: "SRG402",
    LockedUser: "SRG423",
    DataTooLarge: "SRG424"
}

export const componentConst = {
    loginOption: "/login",
    loginMiddleware: "/Authorization",
    home: "/",
    reserveBond: "/reservebond",
    displayReserveBond: "/displayreservebond",
    updateBond: "/updatebond",
    agencyVarianceReport: "/agencyvariancereport",
    bondThirtyDaysReport: "/bondthirtydaysreport",
    utility: "/utility",
    agency: "/agencyUtility",
    agent: "/agentutility",
    addUpdateAgency : "/addUpdateAgency",
    addUpdateAgent : "/addUpdateAgent",
    key: "/key",
    submission: "/submission",
    createSubmission : "/createsubmission",
    systemAccess:"/systemaccess",
    uwsubmission: "/submissionuw",
    projectStatus:"/projectStatusHome",
    addUpdateProjectStatus:"/addUpdateProjectStatus",
    uploadProjectStatus:"/uploadProjectStatus",
    bondUpload:"/bondUpload",
    users:"/users",
    addUpdateUser:"/addUpdateUser"
}

export const bondStatusCode = {
    reserved: "R",
    booked: "B",
    void: "V",
    agtClosed:"A",
    over36:"O"
}

export const numeric = new RegExp(
    '^[0-9,]*$'
);

export const printEmailExport = {
    print: "print",
    email: "email",
    export: "export"
}
export const printEmailExportHeading = {
    heading: "You can export or e-mail report.",
    dataTooLarge: "Dataset is too large for immediate download, data will be sent via email."
}

export const exportFileName = {
    reserveBondReport: "C&F Reserved Bonds Report.csv",
    agencyVarianceReport: "C&F Agency Variance Report.csv",
    bondNumberInformation: "C&F Bond Number Information.csv",
    reserveBondOverThirtyDays: "C&F Over 90 day Reserve Report.csv",
    submissionList: "C&F Submission Report.csv",
    uwSubmissionList: "C&F Submissions By UW.csv",
    projectSatusReport:"C&F Project Status Report.csv"
}

export const responseCodes = {
    success: 200
}
export const menuListKey = {
    tab: "Tab",
    escape: "Escape"
}
export const dataGridDensity = {
    comfortable: "comfortable",
    compact: "compact",
    standard: "standard"
}
export const filterOperators = {
    contains: "contains",
    before: "before",
    after: "after",
    is: "is",
    isEmpty: "isEmpty",
    isNotEmpty: "isNotEmpty",
    equals:"equals",
    onOrAfter:"onOrAfter",
    onOrBefore:"onOrBefore"
}
export const idealTime = {
    maxIdealTime:900
}
export const note = {
    voidEvidenceForInternalUser: "Please remember to post voided bond evidence in Image Right",
    voidEvidenceForAgent: "Please send all documentation on voided bonds to the C&F Underwriter or Underwriting Assistant as soon as possible for C&F records. Once voided, you will NOT be able to reuse this bond number."
}
export const utilityDetails ={
    "Current Prefix": 0,
    "Total bond numbers": 0,
    "Last used bond number":0,
    "Bond Numbers left": 0
}


export const usState =[
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]


export const systemAccess = {
    reservation: 1,
    submission: 2,
    projectStatus:3
};
export const mimeType ={
    csv : 'text/csv'
}


export const bondUploadFile ={
    fileName :'BondUpload',
    contentValue:'Ym9uZF9OdW1iZXIsYWdlbmN5X0NvZGUsYm9uZF9SZXNlcnZlX0RhdGUsdXNlcl9JZAo='
}
export const agencyDetails ={
    fileName: 'AgencyDetails'
}
export const fileInputType ={
    base64 :'base64',
    textCSV :'charset=utf-8'
}

export const pSStatus = {
    letterResent:"Letter Resent",
    letterSent:"Letter Sent",
    projectOpen:"Project Open",
    projectClosed:"Project Closed"
}