export const eventsConst = {
    onChange: "onChange",
    onBlur: "onBlur"
}

export const toastNoiticationConfig = {
    success: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
        theme: "light"
    },
    warning: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
        theme: "light"
    },
    error: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
        theme: "light"
    },
    errorMessage: "Something went wrong! Please try again after sometime...",
    fillSearchFields: "Please enter search value!",
    fillAddFields: "Please fill the form!",


}

export let headers = {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('id_token')
}

export const numeric = new RegExp(
    '^[0-9,]*$'
);

export const decimal_11_2 = new RegExp(
    '^[0-9,]{0,11}(\\.[0-9]{1,2})?$'
);

export const decimal_7_5 = new RegExp(
    '^[0-9,]{0,7}(\\.[0-9]{1,5})?$'
);

export const decimal_9_4 = new RegExp(
    '^[0-9,]{0,9}(\\.[0-9]{1,4})?$'
);

export const decimal_8_5 = new RegExp(
    '^[0-9,]{0,8}(\\.[0-9]{1,5})?$'
);

export const decimal_negative_11_2 = new RegExp(
    '^[+-]?([0-9,]{0,11}(\\.[0-9]{1,2})?)$'
);

export const decimal_negative_7_5 = new RegExp(
    '^[+-]?([0-9,]{0,7}(\\.[0-9]{1,5})?)$'
);

export const decimal_negative_9_4 = new RegExp(
    '^[+-]?([0-9,]{0,9}(\\.[0-9]{1,4})?)$'
);

export const removeDotLast = /\.+$/g;

export const intMaxValue = 2147483647;

export const skipToCheck = {
    isFormUpdated: 'isFormUpdated',
    pageNo: 'pageNo'
};


export const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '41%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export const displayReserveBondUrlPram = {
    requestId: "requestId"
}