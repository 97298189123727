
export const exportHeader =
  [
    { label: 'Date Reserved', key: 'dateReserved' },
    { label: 'Bond Numbers', key: 'bondNumber' },
    { label: 'Agency Number', key: 'agencyNumber' },
    { label: 'Agency Name', key: 'agencyName' },
    { label: 'Bond Amount', key: 'bondAmount' },
    { label: 'Principal', key: 'principal' },
    { label: 'Obligee', key: 'obligee' },
    { label: 'Execution Date', key: 'executionDate' }
  ]
  


export const tableHeader =
  [
    { field: 'bondNumber', headerName: 'Bond Numbers', sortable: true, flex: 1 }

  ]