import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { filterOperators } from "../Commoncode/constant";

export const searchBox = {
  UserName: "",
  UserRole: 0,
  Search: "",
  PageNumber: 0,
  PageSize: 20,
  FilterData: [],
  LinkOperator: "",

  Export: false,
  TotalCount: 0,

  SortData: "",
  AscDesc: ""
}
export const searchBoxProp = {
  search: "search",
  pageNumber: "pageNumber",
  pageSize: "pageSize",
  columnFilterData: "columnFilterData",

  contains: "contains",
  equals: "equals",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  isAnyOf: "isAnyOf",

  setData: "setData",
  setFilteredData: "setFilteredData",
  TotalCount: "TotalCount",
  sortData: "sortData",
  asc: "asc",
  desc: "desc"
}

export const defaultValueApi = [{
  bondNo: "",
  agencyNo: "",
  agencyName: "",
  userFullName: "",
  dateReserved: "",
  dateBooked: "",
  status: "",
  insured: "",
  profitCenter: "",
  department: "",
  product: "",
  comment: "",
  daysReserved:""
}]

export const exportAgentHeader =
  [
    { key: 'bondNo', label: 'Bond', width: 150 },
    { key: 'dateBooked', label: 'Date Booked', width: 200 },
    { key: 'dateReserved', label: 'Date Reserved', width: 200 },
    { key: 'daysReserved', label: '#Days Reserved' },
    { key: 'status', label: 'Status', width: 220 },
    { key: 'userFullName', label: 'User Name', width: 205 },
    { key: 'insured', label: 'Principal Name', width: 205 },
    { key: 'comment', label: 'Comments' }
  ]

export const exportInternalUserHeader =
  [
    { label: 'Bond', key: 'bondNo' },
    { label: 'Date Booked', key: 'dateBooked' },
    { label: 'Date Reserved', key: 'dateReserved' },
    { label: '#Days Reserved', key: 'daysReserved' },
    { label: 'Status', key: 'status' },
    { label: 'Agency', key: 'agencyNo' },
    { label: 'Agency Name', key: 'agencyName' },
    { label: 'User Name', key: 'userFullName' },
    { label: 'Principal Name', key: 'insured' },
    { label: 'Profit Center', key: 'profitCenter' },
    { label: 'Department', key: 'department' },
    { label: 'Product', key: 'product' },
    { label: 'Comments', key: 'comment' }

  ]

export const agentTableHeader =
  [
    {
      field: 'bondNo', headerName: 'Bond', sortable: true, width: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'dateBooked', headerName: 'Date Booked', type: 'date', sortable: true, width: 150, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'dateReserved', headerName: 'Date Reserved', type: 'date', sortable: true, width: 150, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after,
      )
    },
    {
      field: 'daysReserved', headerName: '#Days Reserved', sortable: true, width: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.equals,
      )
    },
    {
      field: 'status', headerName: 'Status', sortable: true, width: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'userFullName', headerName: 'User Name', sortable: true, width: 287, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'insured', headerName: 'Principal Name', sortable: true, width: 300, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'comment', headerName: 'Comments', sortable: true, width: 400, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    }
  ]

export const internalUserTableHeader =
  [
    {
      field: 'bondNo', headerName: 'Bond', width: 120, type: 'string', sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'dateBooked', headerName: 'Date Booked', type: 'date', width: 130, sortable: true, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'dateReserved', headerName: 'Date Reserved', width: 130, sortable: true, filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === filterOperators.is || operator.value === filterOperators.before || operator.value === filterOperators.after,
      )
    },
    {
      field: 'daysReserved', headerName: '#Days Reserved', sortable: true, width: 150, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.equals,
      )
    },
    {
      field: 'status', headerName: 'Status', width: 100, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals,
      )
    },
    {
      field: 'agencyNo', headerName: 'Agency', width: 100, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'agencyName', headerName: 'Agency Name', width: 220, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'userFullName', headerName: 'User Name', width: 180, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'insured', headerName: 'Principal Name', width: 200, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'profitCenter', headerName: 'Profit Center', width: 250, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'department', headerName: 'Department', width: 260, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'product', headerName: 'Product', width: 200, sortable: true, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    },
    {
      field: 'comment', headerName: 'Comments', sortable: true, width: 400, filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === filterOperators.contains || operator.value === filterOperators.equals || operator.value === filterOperators.isEmpty || operator.value === filterOperators.isNotEmpty,
      )
    }
  ]
