import React,{useState,useEffect} from "react";
import { NavLink, useLocation } from "react-router-dom";
import { faHome,faGear,faKey,faBuilding,faMoneyBill,faPaperPlane,faUsers,faUpload,faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Sidebar.scss";
import logo from '../../../assets/logo.png';
import logoSmall from '../../../assets/logo-small.png';
import { componentConst,roleName,systemAccess } from "../../../Commoncode/constant";
import UserDetails from "../../../Hooks/UserDetails";
import Tooltip from '@mui/material/Tooltip';
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Sidebar = () => {
    const [userRole, setUserRole] = useState(roleName.inquiry);
    const location = useLocation();
    const [open, setOpen] = useState(false);  
    const [currentSystem, setCurrentSystem] = useState(0);  
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    useEffect(() => {
        let loggedInUserDetails = UserDetails();
        if (loggedInUserDetails !== false) {
            setUserRole(loggedInUserDetails.userRoleName);
        }
        setCurrentSystem(loggedInUserDetails.userSystem);
    },[])
    return (
        <React.Fragment>
            <div className="sidebar">
                <div className="logo">
                    <img src={logo} alt="" className="img-logo" />
                    <img src={logoSmall} alt="" className="small-logo" />
                </div>
                <div className="sidebar-wrapper">
              { currentSystem === systemAccess.reservation && <>
               <Tooltip title="Home" placement="left-end">
                    <ul className="nav">
                        <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}>
                            <li className={location.pathname.toUpperCase() === componentConst.home.toUpperCase() ? "nav-item active" : "nav-item"} id="liHome">
                                <FontAwesomeIcon icon={faHome} />
                                <p>Home</p>
                            </li>
                        </NavLink>
                    </ul>
                </Tooltip>
                    
                            <ul className="nav">
                                <li id="liAgency" onClick={handleClick}>
                                    <FontAwesomeIcon icon={faGear} />
                                    <p>Utilities</p>
                                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </li>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                {userRole !== roleName.agent &&
                                <Tooltip title="Bond Details" placement="left-end"> 
                                    <ul className="nav" >
                                        <NavLink style={{ textDecoration: 'none',paddingLeft:'11px' }} to={componentConst.utility} >
                                            <li className={location.pathname.toUpperCase() === componentConst.utility.toUpperCase() ? "nav-item active" : "nav-item"} id="liAgency" >
                                                <FontAwesomeIcon icon={faMoneyBill} /> 
                                                <p>Bond Details</p>
                                            </li>
                                        </NavLink>
                                    </ul>
                                </Tooltip>}
                                {userRole === roleName.admin  &&
                                <Tooltip title="Agency" placement="left-end"> 
                                    <ul className="nav" >
                                        <NavLink style={{ textDecoration: 'none',paddingLeft:'11px' }} to={componentConst.agency}>
                                            <li className={location.pathname.toUpperCase() === componentConst.agency.toUpperCase() ? "nav-item active" : "nav-item"} id="liAgency" sx={{ pl: 4 }}>
                                                <FontAwesomeIcon icon={faBuilding} />
                                                <p>Agency</p>
                                            </li>
                                        </NavLink>
                                    </ul>
                                </Tooltip>}
                                {userRole === roleName.admin  &&
                                <Tooltip title="Agent" placement="left-end"> 
                                    <ul className="nav" >
                                        <NavLink style={{ textDecoration: 'none',paddingLeft:'11px' }} to={componentConst.agent}>
                                            <li className={location.pathname.toUpperCase() === componentConst.agent.toUpperCase() ? "nav-item active" : "nav-item"} id="liAgent" sx={{ pl: 4 }}>
                                                <FontAwesomeIcon icon={faUsers} />
                                                <p>Agents</p>
                                            </li>
                                        </NavLink>
                                    </ul>
                                </Tooltip>}
                                {userRole === roleName.admin  &&
                                <Tooltip title="Bond Upload" placement="left-end"> 
                                    <ul className="nav" >
                                        <NavLink style={{ textDecoration: 'none',paddingLeft:'11px' }} 
                                                 to={componentConst.bondUpload}>
                                            <li className={location.pathname.toUpperCase() === componentConst.bondUpload.toUpperCase() ? "nav-item active" : "nav-item"} id="libondUpload" sx={{ pl: 4 }}>
                                                <FontAwesomeIcon icon={faUpload} />
                                                <p>Bond Upload</p>
                                            </li>
                                        </NavLink>
                                    </ul>
                                </Tooltip>}
                                {userRole === roleName.admin  &&
                                <Tooltip title="User" placement="left-end"> 
                                    <ul className="nav" >
                                        <NavLink style={{ textDecoration: 'none',paddingLeft:'11px' }} 
                                                 to={componentConst.users}>
                                            <li className={location.pathname.toUpperCase() === componentConst.users.toUpperCase() ? "nav-item active" : "nav-item"} id="liuser" sx={{ pl: 4 }}>
                                                <FontAwesomeIcon icon={faUser} />
                                                <p>User</p>
                                            </li>
                                        </NavLink>
                                    </ul>
                                </Tooltip>}
                                </Collapse>
                            </ul>
                <Tooltip title="Status Key" placement="left-end"> 
                    <ul className="nav">
                        <NavLink style={{ textDecoration: 'none' }} to={componentConst.key}>
                            <li className={location.pathname.toUpperCase() === componentConst.key.toUpperCase() ? "nav-item active" : "nav-item"} id="liAgency">
                            <FontAwesomeIcon icon={faKey} /> 
                               <p>Status Key</p>
                            </li>
                        </NavLink>
                    </ul>
                </Tooltip>
                </>}

                { currentSystem === systemAccess.submission && <>
               <Tooltip title="Home" placement="left-end">
                    <ul className="nav">
                        <NavLink style={{ textDecoration: 'none' }} to={componentConst.submission}>
                            <li className={location.pathname.toUpperCase() === componentConst.submission.toUpperCase() ? "nav-item active" : "nav-item"} id="lisubmission">
                                <FontAwesomeIcon icon={faHome} />
                                <p>Home</p>
                            </li>
                        </NavLink>
                    </ul>
                </Tooltip>
                    
                <Tooltip title="Submissions By UW" placement="left-end"> 
                    <ul className="nav">
                        <NavLink style={{ textDecoration: 'none' }} to={componentConst.uwsubmission}>
                            <li className={location.pathname.toUpperCase() === componentConst.uwsubmission.toUpperCase() ? "nav-item active" : "nav-item"} id="liuwsubmission">
                            <FontAwesomeIcon icon={faPaperPlane} /> 
                               <p>Submissions By UW</p>
                            </li>
                        </NavLink>
                    </ul>
                </Tooltip>
                </>}
                
                { currentSystem === systemAccess.projectStatus && <>
               <Tooltip title="Home" placement="left-end">
                    <ul className="nav">
                        <NavLink style={{ textDecoration: 'none' }} to={componentConst.projectStatus}>
                            <li className={location.pathname.toUpperCase() === componentConst.projectStatus.toUpperCase() ? "nav-item active" : "nav-item"} id="liprojectStatus">
                                <FontAwesomeIcon icon={faHome} />
                                <p>Home</p>
                            </li>
                        </NavLink>
                    </ul>
                </Tooltip>
                    
                </>}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar;