import axios from "axios";
import React,{ useEffect, useState  } from "react";
import 'react-toastify/dist/ReactToastify.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography,Box} from '@mui/material';
import Paper from '@mui/material/Paper';
import { headers,apiUrls, apiResponseCode,responseCodes } from "../../Commoncode/constant";
import LoaderComponent from "../Common/Loader/LoaderComponent";
const BondStatusKey = ()=>{
    const [showLoader, setShowLoader] = useState(false);
    const [bondStatusKeyData,setBondStatusKeyData] = useState([]);
    useEffect(() =>{
        setShowLoader(true);
        axios.get(apiUrls.getBondStatusKey, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setBondStatusKeyData(response.data.statusMaster);
                }
                else {
                    setShowLoader(false);
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    },bondStatusKeyData);

    return(
        <React.Fragment>
            <Typography variant="h5" className="pageHeading" style={{ textAlign: 'center' }}>
            Status Definitions 
            </Typography>
            <Box className="body-container">
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Status</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Descriptions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {bondStatusKeyData.map((row) => (
                        <TableRow
                            key={row.bondKey}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
              <TableCell component="th" scope="row">
                {row.bondKey}
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Box>
            <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
    )
}

export default BondStatusKey;