import React,{useEffect, useState  } from "react";
import { Typography,Button, Box } from '@mui/material';
import LoaderComponent from "../Common/Loader/LoaderComponent";
import { isCheckDate } from "../../Hooks/Validation";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@mui/material';
import {downloadCSVFile,arrayToCSVFile,xlsxToArray} from "../../Commoncode/fileUtility";
import {mimeType,fileInputType,agencyDetails,headers, 
        apiUrls,responseCodes,apiResponseCode,toastNoiticationConfig} from "../../Commoncode/constant";
import { intMaxValue } from "../shared/common";
import axios from "axios";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {projectStatusName} from "../../Models/ProjectStatus/homeModel";
import UserDetails from "../../Hooks/UserDetails";
const UploadProjectStatus = () =>{ 
  const columns = [
    {
        Current_Date:"",
        Respond_by:"",
        INS_RQMT_PRODCT_NO:"",
        Policy_Number:"",
        PRODUCER_NAME:"",
        PRODUCER_NO:"",
        ACCOUNT_NAME:"",
        Insured_Name:"",
        UNDERWRITER_NAME:"",
        Effective_Date:"",
        PRODCT_EXP_DT:"",
        Bond_Amount:""
    }
  ];
  
  const [showLoader, setShowLoader] = useState(false);
  const [array, setArray] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [validateButton,setValidateButton]= useState(true);
  const [submitButton,setSubmitButton]= useState(true);
  
 
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if(file){
    reader.onload = function (event) {
      const { result } = event.target;
      var uName =UserDetails().userName;
      var outputArray =xlsxToArray(result,"A","L");
      outputArray.shift(1);
      var obj = outputArray.map(x=>({
        "currDate": x[0],
        "respondBy": x[1],
        "insRqmtProdctNo": x[2],
        "policyNumber": x[3],
        "accountName": x[6],
        "insuredName": x[7],
        "effDate": x[9],
        "prodctExpDt": x[10],
        "bondAmount": x[11],
        "underwriterName": x[8],
        "producerName": x[4],
        "producerNo": x[5],
        "psCreateUserid":uName,
        "error":''
      }));
      var out = checkData(obj);
      setErrorList(out);
      if(out.length ===0){
        setArray(obj);
        setValidateButton(false);
      }
      
      //}
    }
   reader.readAsBinaryString(file);
    }
  };
  const checkData =(inputArray) =>{
    var errorArray = [];
    for(var item in inputArray){

      if(!isCheckDate(inputArray[item].currDate)){
        inputArray[item].error ='Invalid Current Date';
      }

      if(!isCheckDate(inputArray[item].respondBy)){
        if(inputArray[item].error === ''){
          inputArray[item].error ='Invalid Respond Date';
        }else{
          inputArray[item].error +=', Invalid Respond Date';
        }
      }

      if(!isCheckDate(inputArray[item].effDate)){
        if(inputArray[item].error === ''){
          inputArray[item].error ='Effective Respond Date';
        }else{
          inputArray[item].error +=', Effective Respond Date';
        }
      }

      if(!isCheckDate(inputArray[item].prodctExpDt)){
        if(inputArray[item].error === ''){
          inputArray[item].error ='Product expiry Date';
        }else{
          inputArray[item].error +=', Product expiry Date';
        }
      }

      if(inputArray[item].policyNumber.length !== 10 || Number.isNaN(inputArray[item].policyNumber)){
        if(inputArray[item].error === ''){
          inputArray[item].error ='Invalid policy number';
        }else{
          inputArray[item].error +=', Invalid policy number';
        }
      }

      const validated = (/^(\d{0,8})(\.[0-9]{0,2})?$/).test(inputArray[item].bondAmount);
      if (!validated) {
        if(inputArray[item].error === ''){
          inputArray[item].error ='Invalid bond amount';
        }else{
          inputArray[item].error +=', Invalid bond amount';
        }
      }

      if(inputArray[item].error !==''){
        errorArray.push(inputArray[item]); 
      }
     }
     
    return errorArray;
    }
  const resetValue =({currentTarget})=>{
    currentTarget.value = '';
    setErrorList(columns); 
    setArray(columns);
    setSubmitButton(true);
    setValidateButton(true);
  }
  const validateProjectStatusDetails = inputArray =>{
    setShowLoader(true);
    var validateProjectStatus = JSON.stringify(inputArray);
    axios.post(apiUrls.validateProjectStatusDetails,validateProjectStatus, { headers: headers })
        .then((response) => {
          if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
            {
              if(response.data.projectStatusValidation.length>0)
                setErrorList(response.data.projectStatusValidation);
              else
                {
                  setErrorList(columns); 
                  setSubmitButton(false);
                }
                setShowLoader(false);
            }
        }).catch(error => {
          setShowLoader(false);
        });
  }

  const SubmitprojectStatusDetails = inputArray =>{
    setShowLoader(true);
    var projectStatusDetails = JSON.stringify(inputArray);
    axios.post(apiUrls.insertProjectStatusDetails,projectStatusDetails, { headers: headers })
        .then((response) => {
          if (response.status === responseCodes.success && response.data.responseMessage.length > 0 && response.data.responseCode === apiResponseCode.SuccessRequest) 
            {
              setShowLoader(false);
              toast.success(toastNoiticationConfig.recordCreatedSuccessfully , {position: toast.POSITION.TOP_RIGHT});
              resetValue();
            }
        }).catch(error => {
          setShowLoader(false);
        });
  }
  
  const getAgencyDetails =() =>{
    var search = {
      "PageSize": intMaxValue,
      "PageNumber": 0,
      "Search": "",
      "TotalCount": 0,
      "SortData": "adNumber",
      "AscDesc": "desc",
      "FilterData": [
          {
              "ColumnField": "adActive",
              "OperatorValue": "equals",
              "Value": "true"
          }
      ],
      "LinkOperator": "and"
   }
   setShowLoader(true);
    axios.post(apiUrls.getAgencyDetails,search, { headers: headers })
        .then((response) => {
            setShowLoader(false);
        if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) 
        {
          var result = response.data.agencyDetail;
          if(result.length>0){
            result = result.map(x=>[x.adNumber,x.adName])
            var headerString = '"Agency Code","Agency Name"\n';
            var csv =arrayToCSVFile(result,headerString);
         downloadCSVFile(csv,agencyDetails.fileName,mimeType.csv,fileInputType.textCSV);
          }

        }
      })
        .catch(error => {
          setShowLoader(false);
        });
  }

  const tableRows = errorList.map((element) => {
    return (
      <TableRow
      key={element.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {element.currDate}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.respondBy}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.insRqmtProdctNo}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.policyNumber}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.producerNo}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.producerName}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.accountName}
          </TableCell>
          
          <TableCell component="th" scope="row">
            {element.insuredName}
          </TableCell>
          
          <TableCell component="th" scope="row">
            {element.underwriterName}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.effDate}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.prodctExpDt}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.bondAmount}
          </TableCell>
          <TableCell component="th" scope="row">
            {element.error}
          </TableCell>
        </TableRow>
    );
});

  return (
        <React.Fragment>
          <Typography variant="h5" className="pageHeading">
            Project Status Upload
          </Typography>
          <Box className="body-container">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450}} size="small"  aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                          <TableCell style={{fontWeight: 'bold'}}>Instruction</TableCell>
                          <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                          
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            1. Upload the .xlsx file with upload button
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button component="label" variant="contained" className="btnStyling">
                              Upload
                              <input type="file" accept=".xlsx" hidden onClick ={resetValue} onChange={handleFileUpload} />
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                          2. Once the file is uploaded ,click on validate button for validating data from database.
                          </TableCell>
                          <TableCell component="th" scope="row">
                          <Button type="button" variant="contained" className="btnStyling" 
                                  disabled ={validateButton} onClick={()=>validateProjectStatusDetails(array)}>
                            Validate
                          </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            3. Once all data is validated from database, then submit the details with submit button</TableCell>
                          <TableCell component="th" scope="row">
                          <Button
                            type="button" 
                            variant="contained" 
                            className="btnStyling"
                            disabled ={submitButton}
                            onClick={()=>SubmitprojectStatusDetails(array)}
                          >
                            Submit
                          </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                          Please use download agency button for Agency code.
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button type="button" variant="contained" className="btnStyling" onClick={()=>getAgencyDetails()}>
                                Download Agency details
                            </Button>
                          </TableCell>
                          </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450}} size="small" aria-label="a dense table">
                <TableHead>
                        <TableRow>                            
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.currentDate}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.respondBy}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.insRqmtProdctNo}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.policyNumber}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.producerNo}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.producerName}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.accountName}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.insuredName}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.underWriterName}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.effectiveDate}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.prodctExpDt}</TableCell>
                          <TableCell style={{fontWeight: 'bold'}}>{projectStatusName.bondAmount}</TableCell>

                          <TableCell style={{fontWeight: 'bold'}}>error</TableCell>
                        </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
            </TableContainer>
           </Box>
           <ToastContainer />
           <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
        )
}
export default UploadProjectStatus;