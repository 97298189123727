import axios from "axios";
import { Stack, Autocomplete, Typography, Button, Box, TextField, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, OutlinedInput, FormHelperText } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { initialAgentValue } from '../../../Models/agentUtility';
import UserDetails from "../../../Hooks/UserDetails";
import { headers, apiUrls, responseCodes, apiResponseCode, usState, componentConst } from "../../../Commoncode/constant"
const AddUpdateAgent = () => {
    let history = useHistory();
    let location = useLocation();
    const [enableInputReserveBlock, setEnableInputReserveBlock] = useState(false);
    const [agentValue, setAgentValue] = useState(initialAgentValue);
    const [showLoader, setShowLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [agentItems, setAgentItem] = useState([]);
    const [agentNumber, setAgentNumber] = useState("");

    //const [selectedAgency, setSelectedAgency] = React.useState({'agencyNumber': '00012', 'agencyName': '00012 - test' });
    const [selectedAgency, setSelectedAgency] = React.useState();
    const [inputSelectedAgency, setInputSelectedAgency] = React.useState('');
    const [editAC, setEditAC] = React.useState('');


    const goToPreviousPath = (responseMessage) => {
        history.push(componentConst.agent, responseMessage)
    }
    useEffect(() => {
        bindAgentList(location.agentprop);
        if (location.agentprop !== undefined) {

            setAgentValue(location.agentprop);
            setEditAC(location.agentprop.agencyCode);
            setEnableInputReserveBlock(!location.agentprop.adReserveBlock);
            setIsEdit(true);
            // bindAgentList(location.agentprop);
        }
    }, [])

    const agentChange = (value) => {
        setAgentNumber("");
        if (value != null) {
            setAgentNumber(value.agencyNumber);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'adReserveBlock' || name === 'adIsSingleBondReserve' || name === 'adActive') {
            setAgentValue({
                ...agentValue,
                [name]: checked
            });
        } else {
            setAgentValue({
                ...agentValue,
                [name]: value
            });
        }
    };

    function bindAgentList(agentprop) {
        setShowLoader(true);
        axios.get(apiUrls.getAgentList, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                setAgentItem([]);
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setAgentItem(response.data.agencyList);
                    if (agentprop) {
                        var sa = response.data.agencyList.find(x => x.agencyNumber === agentprop.agencyCode)?.agencyName;
                        if (sa)
                            setInputSelectedAgency(sa);
                    }

                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }

    function insertAgentDetails() {
        agentValue.addUpdateUserid = UserDetails().userName;
        if (isEdit) {
            agentValue.udLock = agentValue?.udLock === 'true';
        }

        var apiUrl = isEdit ? apiUrls.updateAgent : apiUrls.addAgent;

        axios.post(apiUrl, agentValue, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) {
                    // toast.success(response.data.responseMessage.responseMessage, {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    goToPreviousPath(response.data.responseMessage.responseMessage);
                } else {

                    toast.error(response.data.responseMessage.responseMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setShowLoader(false);
            });
    }

    function enableReserveMaxAndopenallowed(e) {
        setEnableInputReserveBlock(!e.target.checked);
        agentValue.adIsSingleBondReserve = !e.target.checked;
        if (!e.target.checked) {
            agentValue.adReserveMax = 0;
            agentValue.adMaxOpenAllowed = 0;

        }
        else {
            agentValue.adReserveMax = '';
            agentValue.adMaxOpenAllowed = '';
        }
    }

    function setReserveMaxAndopenallowedToZeroAndDisable(event) {
        setEnableInputReserveBlock(event.target.checked);
        agentValue.adReserveBlock = !event.target.checked;
        if (!event.target.checked) {
            agentValue.adReserveMax = '';
            agentValue.adMaxOpenAllowed = '';
        } else {
            agentValue.adReserveMax = 0;
            agentValue.adMaxOpenAllowed = 0;
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h5" className="pageHeading">
                {isEdit === true ? "Update Agent" : "Add Agent"}
            </Typography>
            <Box className="body-container" component="form" noValidate autoComplete="off">
                <Grid container spacing={1}>
                    {isEdit == true && <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            size="small"
                            name='id'
                            id="id"
                            label="ID"
                            inputProps={{ maxLength: 5 }}
                            disabled={isEdit}
                            onChange={handleInputChange}
                            value={agentValue.id}
                            required />
                    </Grid>}
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="loginUserName"
                            size="small"
                            id="loginUserName"
                            label="Login User Name"
                            disabled={isEdit}
                            onChange={handleInputChange}
                            value={agentValue.loginUserName}
                            error={agentValue.loginUserName === ''}
                            helperText={agentValue.loginUserName === '' ? 'Login User Name is required' : ''}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="email"
                            size="small"
                            id="email"
                            label="Email"
                            disabled={isEdit}
                            value={agentValue.email}
                            error={agentValue.email === ''}
                            helperText={agentValue.email === '' ? 'Email is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="firstName"
                            size="small"
                            id="firstName"
                            label="First Name"
                            value={agentValue.firstName}
                            error={agentValue.firstName === ''}
                            helperText={agentValue.firstName === '' ? 'First Name is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            name="lastName"
                            size="small"
                            id="lastName"
                            label="Last Name"
                            value={agentValue.lastName}
                            error={agentValue.lastName === ''}
                            helperText={agentValue.lastName === '' ? 'Last Name is required' : ''}
                            onChange={handleInputChange}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={2} sx={6}>
                            <Autocomplete
                                id="ddlSelectAgent"
                                size="small"
                                options={agentItems}
                                getOptionLabel={(option) => option.agencyName}
                                renderOption={(props, agentItems) => {
                                    return (
                                        <li {...props} key={agentItems.agencyNumber}>
                                            {agentItems.agencyName}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField size="small" {...params} variant="standard" label="Select agency" placeholder="Agency number - Agent name" />
                                )}
                                value={selectedAgency}
                                onChange={(event, agency) => {

                                    setSelectedAgency(agency);
                                    setAgentValue({
                                        ...agentValue,
                                        agencyCode: agency && agency.agencyNumber ? agency.agencyNumber : ''
                                    });
                                }}
                                inputValue={inputSelectedAgency}
                                onInputChange={(event, inputAgency) => {

                                    if (event && event.type !== 'blur') {
                                        setInputSelectedAgency(inputAgency);
                                        setAgentValue({
                                            ...agentValue,
                                            agencyCode: inputAgency
                                        });
                                    }
                                }}
                                required />
                        </Stack>
                    </Grid>
                    {isEdit && <Grid item xs={6}>
                        <FormControl error={agentValue.adState === ''} required variant="outlined" style={{ width: '-webkit-fill-available' }}>
                            <InputLabel>Lock</InputLabel>
                            <Select
                                id="state"
                                size="small"
                                name="udLock"
                                value={agentValue.udLock}
                                label="Lock"
                                required
                                onChange={handleInputChange}
                            >
                                <MenuItem value="true" key="true">
                                    True
                                </MenuItem >
                                <MenuItem value="false" key="false">
                                    False
                                </MenuItem >
                            </Select>
                        </FormControl>
                    </Grid>}
                    <Grid item xs={12}>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                className="btnStyling"
                                color="success"
                                disabled={agentValue.loginUserName === '' || agentValue.firstName === '' || agentValue.lastName == '' || agentValue.email == '' || agentValue.agencyCode == ''}
                                onClick={insertAgentDetails}>{isEdit === true ? "Update" : "Add"}</Button>
                            <span>  </span>
                            <Button variant="contained" className="btnStyling" color="info" onClick={() => goToPreviousPath("")}>Cancel</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <ToastContainer></ToastContainer>
        </React.Fragment>
    )
}
export default AddUpdateAgent;