import axios from "axios";
import React, { useReducer, useState, useEffect } from "react";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from "react-router-dom";
import { Grid, Modal, Box, Button, Typography, TextField } from '@mui/material';
import { roleName, componentConst, headers, apiUrls, responseCodes, apiResponseCode, toastNoiticationConfig } from "../../Commoncode/constant";
import { reserveModalPopUpProp, reserveModalScenarioByName, reserveBondModalDesc, modalStyle } from "../../Models/reserveBond";
import UserDetails from "../../Hooks/UserDetails";
import "./ReserveBondModals.scss";
import "../../style/Style.scss"



function ReserveBondModals(prop) {
    const [state, Dispatch] = useReducer(Reducer, reserveModalPopUpProp);
    const [numberOfBondReserve, setNumberOfBondReserve] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [requestId, setRequestId] = useState(0);
    const [continueDisabled, setContinueDisabled] = useState(false);

    useEffect(() => {
        let loggedInUserDetails = UserDetails();

        setNumberOfBondReserve(prop.modalPopUpScenario.reserveMax);
        if (roleName.uaSupervisor !== loggedInUserDetails.userRoleName && roleName.admin !== loggedInUserDetails.userRoleName && roleName.agent !== loggedInUserDetails.userRoleName) {
            if (prop.modalPopUpScenario.maxOpenAllowed < prop.modalPopUpScenario.currentOpenReserve) {
                Dispatch({ type: reserveModalScenarioByName.additionalBondNotAllowed });
            }
            else if (prop.modalPopUpScenario.reserveBlock === false && prop.modalPopUpScenario.singleReserveBond === true) {
                Dispatch({ type: reserveModalScenarioByName.singleBondReserve });
            }
            else if (prop.modalPopUpScenario.reserveBlock === true) {
                Dispatch({ type: reserveModalScenarioByName.configuredBondReserve });
            }
            else if (prop.modalPopUpScenario.reserveBlock === false && prop.modalPopUpScenario.singleReserveBond === false) {
                Dispatch({ type: reserveModalScenarioByName.agencyNotAuthorized });
            }
            else {
                Dispatch({ type: reserveModalScenarioByName.none });
            }
        }
        else if (roleName.uaSupervisor === loggedInUserDetails.userRoleName || roleName.admin === loggedInUserDetails.userRoleName) {
            if (prop.modalPopUpScenario.maxOpenAllowed < prop.modalPopUpScenario.currentOpenReserve) {
                Dispatch({ type: reserveModalScenarioByName.additionalBondNotAllowedWithOverride });
            }
            else {
                Dispatch({ type: reserveModalScenarioByName.configuredBondReserveWithOverride });
            }
        }
        else if (roleName.agent === loggedInUserDetails.userRoleName) {
            if (prop.modalPopUpScenario.maxOpenAllowed < prop.modalPopUpScenario.currentOpenReserve) {
                Dispatch({ type: reserveModalScenarioByName.additionalBondNotAllowedForAgent });
            }
            else if (prop.modalPopUpScenario.reserveBlock === false && prop.modalPopUpScenario.singleReserveBond === true) {
                Dispatch({ type: reserveModalScenarioByName.singleBondReserveForAgent });
            }
            else if (prop.modalPopUpScenario.reserveBlock === true) {
                Dispatch({ type: reserveModalScenarioByName.configuredBondReserveForAgent });
            }
            else if (prop.modalPopUpScenario.reserveBlock === false && prop.modalPopUpScenario.singleReserveBond === false) {
                Dispatch({ type: reserveModalScenarioByName.agencyNotAuthorizedForAgent });
            }
            else {
                Dispatch({ type: reserveModalScenarioByName.none });
            }
        }

    }, [prop.modalPopUpScenario])

    
    useEffect(() => {
        if(requestId !== 0)
        {
            document.getElementById("linkDisplay").click();
        }
    }, [requestId])
    

    function Reducer(state, action) {
        switch (action.type) {
            case reserveModalScenarioByName.additionalBondNotAllowed:
                return {
                    ...state,
                    title: reserveBondModalDesc.additionalBondNotAllowedDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: false,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.singleBondReserve:
                return {
                    ...state,
                    title: reserveBondModalDesc.singleBondReserveDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: true,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.configuredBondReserve:
                return {
                    ...state,
                    title: reserveBondModalDesc.configuredBondReserveDesc1 + prop.modalPopUpScenario.reserveMax,
                    description: reserveBondModalDesc.configuredBondReserveDesc2 + prop.modalPopUpScenario.currentOpenReserve,
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: true,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.agencyNotAuthorized:
                return {
                    ...state,
                    title: reserveBondModalDesc.agencyNotAuthorizedDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: false,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.additionalBondNotAllowedWithOverride:
                return {
                    ...state,
                    title: reserveBondModalDesc.additionalBondNotAllowedWithOverrideDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: false,
                    overrideBtn: true,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.configuredBondReserveWithOverride:
                return {
                    ...state,
                    title: reserveBondModalDesc.configuredBondReserveWithOverrideDesc1 + prop.modalPopUpScenario.reserveMax,
                    description: reserveBondModalDesc.configuredBondReserveWithOverrideDesc2 + prop.modalPopUpScenario.currentOpenReserve,
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: true,
                    overrideBtn: true,
                    noOfBonds: false,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.overriderBondReserve:
                return {
                    ...state,
                    title: reserveBondModalDesc.overriderBondReserveDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: true,
                    overrideBtn: false,
                    noOfBonds: true,
                    cancelBtnForAgent: false
                }
            case reserveModalScenarioByName.additionalBondNotAllowedForAgent:
                return {
                    ...state,
                    title: reserveBondModalDesc.additionalBondNotAllowedForAgentDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: false,
                    continueBtn: false,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: true
                }
            case reserveModalScenarioByName.singleBondReserveForAgent:
                return {
                    ...state,
                    title: reserveBondModalDesc.singleBondReserveForAgentDesc1 + prop.modalPopUpScenario.reserveMax,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: false,
                    continueBtn: true,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: true
                }
            case reserveModalScenarioByName.configuredBondReserveForAgent:
                return {
                    ...state,
                    title: reserveBondModalDesc.configuredBondReserveForAgentDesc1 + prop.modalPopUpScenario.reserveMax,
                    description: reserveBondModalDesc.configuredBondReserveForAgentDesc2 + prop.modalPopUpScenario.currentOpenReserve + "",
                    selectAgencyBtn: false,
                    cancelBtn: false,
                    continueBtn: true,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: true
                }
            case reserveModalScenarioByName.agencyNotAuthorizedForAgent:
                return {
                    ...state,
                    title: reserveBondModalDesc.agencyNotAuthorizedForAgentDesc1,
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: false,
                    continueBtn: false,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: true
                }
            case reserveModalScenarioByName.none:
            default:
                return {
                    ...state,
                    title: "",
                    description: "",
                    selectAgencyBtn: false,
                    cancelBtn: true,
                    continueBtn: false,
                    overrideBtn: false,
                    noOfBonds: false,
                    cancelBtnForAgent: true
                }
        }
    }

    function closePopup() {
        prop.closePopup(false);
    }

    const overrideClick = () => {
        Dispatch({ type: reserveModalScenarioByName.overriderBondReserve });
    }

    function ContinueClick()
    {
        setContinueDisabled(true);
        let loggedInUserDetails = UserDetails();
        let setDetails = {
            UserName: loggedInUserDetails.userName,
            NoOfBonds: numberOfBondReserve,
            AgencyNum: prop.modalPopUpScenario.agencyNumber
          }
    
          axios.post(apiUrls.getReserveBondInformation, setDetails, { headers: headers })
            .then((response) => {
              if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                setShowLoader(false);
                setRequestId(response.data.requestID);
              }
              else
              {
                setShowLoader(false);
                setRequestId(0);
                toast.warning(toastNoiticationConfig.internalServerError, {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
              setContinueDisabled(false);
            })
              .catch(error => {
                  setShowLoader(false);
              });
    }


    return (
        <Modal open={prop.showPopUpflag} scroll={'paper'} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle} className="modalScrollable">
                <Typography id="modal-modal-title" variant="h6" className="fontH6" style={{ textAlign: 'center' }} >
                    {state.title}
                </Typography>
                <Typography id="modal-modal-description" variant="h6" className="fontH6 mt2" style={{ textAlign: 'center' }} >
                    {state.description}
                </Typography>

                {state.noOfBonds &&
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1} className="mt2">
                        <Grid container spacing={1}>
                            <Grid item sm={12} style={{ textAlign: 'center' }} >
                                <TextField size="small" id="outlined-basic" value={numberOfBondReserve} onChange={(e) => setNumberOfBondReserve(e.target.value)} autoComplete="off" label="Enter number of bonds to be reserved" className="input-custom" variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Typography id="modal-continue" className="mt2" style={{ textAlign: 'center' }} >
                    {state.continueBtn && <NavLink id="linkDisplay" className="displaynone" style={{ textDecoration: 'none' }} to={`${componentConst.displayReserveBond}?requestId=${requestId}`}  > <Button variant="contained" color="success">Continue</Button></NavLink>}
                    {state.continueBtn && <Button disabled={continueDisabled} variant="contained" className="btnStyling ml1 mt1" color="success" onClick={ContinueClick}>Continue</Button>}
                    {state.overrideBtn && <Button variant="contained" className="btnStyling ml1 mt1" color="success" onClick={overrideClick}>Override</Button>}
                    {state.selectAgencyBtn && <Button variant="contained" className="btnStyling ml1 mt1" color="info" onClick={() => closePopup()}>Select Agency</Button>}
                    {state.cancelBtn && <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}> <Button variant="contained" className="btnStyling ml1 mt1" color="info">Cancel</Button> </NavLink>}
                    {state.cancelBtnForAgent && <Button variant="contained" className="btnStyling ml1 mt1" color="info" onClick={() => closePopup()}>Cancel</Button>}
                </Typography>
            <ToastContainer />
            <LoaderComponent show={showLoader}></LoaderComponent>
            </Box>
        </Modal>
    )
}

export default ReserveBondModals;