import axios from "axios";
import React, { useState, useReducer, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import LoaderComponent from "../../Common/Loader/LoaderComponent";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import {useLocation } from "react-router-dom";
import { Button, Typography, Card, Box, Grid ,Modal,Fade,Backdrop} from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import UserDetails from "../../../Hooks/UserDetails";
import { headers, apiUrls, roleName, apiResponseCode, skipToCheck, componentConst, toastNoiticationConfig, responseCodes, exportFileName, commonConst, dataGridDensity, filterOperators } from "../../../Commoncode/constant";
import PrintExportEmailModal from "../../Common/PrintExportEmailModal/PrintExportEmailModal";
import { encryptData,decryptData } from "../../../Commoncode/encryptDecrypt";
import {projectStatusTableHeader,searchBoxProp,exportProjectStatusHeader} from "../../../Models/ProjectStatus/homeModel"
import { modalStyle } from "../../../Models/reserveBond";
const ProjectStatusHome = (props) =>{
    let location = useLocation();
    const [searchBox, setSearchBox] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
    const size = window.innerWidth;
    const [rowsCount, setRowsCount] = useState(0);
    const [searchState, searchDispatch] = useReducer(searchReducer, searchBox);
    const [projectStatus,setProjectStatus]= useState([]);
    const [role,setRole] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
    const [modalPopUpScenarioProp, setModalPopUpScenarioProp] = useState({});
    const [exportAllowed, setExportAllowed] = useState(true);
    const [exportData, setExportData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDelete =()=> {setOpen(false);deleteProjectStatus(location.projectStatusDeleteprop);}
    useEffect(()=>{
      if(location.projectStatusDeleteprop){
        handleOpen();
      }
    },[location.projectStatusDeleteprop])
    
  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.emailProjectStatus, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessage != null && response.data.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }
    const CallPrintExportEmail = () => {
      let exportData = { ...searchState, Export: true };
  
      setShowLoader(true);
      axios.post(apiUrls.getProjectStatusDetails, exportData, { headers: headers })
        .then((response) => {
          setShowLoader(false);
          setExportData([]);
          if (response.data.responseMessage != null && response.data.responseMessage.responseCode === apiResponseCode.DataTooLarge) {
            setExportAllowed(false);
            setShowPrintExportEmailModal(true);
          }
          else if (response.status === responseCodes.success && response.data.responseMessage != null && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) {
        
            if (response.data.projectStatusDetails.length > 0) {
  
              setExportData(response.data.projectStatusDetails);
              setExportAllowed(true);
              setShowPrintExportEmailModal(true);
            }
            else
            {
              toast.warning(toastNoiticationConfig.noRecordFound, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          }
        })
        .catch(error => {
          setShowLoader(false);
        });
    }
    useEffect(() => {
      let loggedInUserDetails = UserDetails();
      if (loggedInUserDetails !== false) {
        setRole(loggedInUserDetails.userRoleSystem.filter(e=>e.systemId ===3)[0].roleName);
        let projectStatusData = localStorage.getItem(commonConst.projectStatusData);
        if (projectStatusData !== null) {
          var filteredData = decryptData(projectStatusData);
          setSearchBox(filteredData.Search);
  
          var filterData = [];
          if (filteredData.FilterData !== null && filteredData.FilterData.length > 0) {
            filteredData.FilterData.map((item, index) => {
              return filterData.push({
                columnField: item.ColumnField,
                operatorValue: item.OperatorValue,
                value: item.Value ? item.Value : null
              });
            });
          }
          setFilteredData(filterData);
          setLinkOperator(filteredData.LinkOperator);
          searchDispatch({ type: searchBoxProp.setFilteredData, value: filteredData });
        }
        else {
          searchDispatch({ type: searchBoxProp.setData, value: loggedInUserDetails });
        }
      }
    }, [])

    useEffect(()=>{
      if(props.location.state !==''){
        
      toast.success(props.location.state , {
        position: toast.POSITION.TOP_RIGHT
      });
      }
    },[props.location.state])

    useEffect(() => {
      submitSearch();
    }, [searchState])

    function submitSearch() {
      var isDataAvailable = false;
  
      for (const item in searchState) {
        if (searchState[item] && item !== skipToCheck.pageNo && searchState[item].toString().trim() !== "") {
          isDataAvailable = true;
        }
      }

      if (isDataAvailable) {
        localStorage.setItem(commonConst.projectStatusData, encryptData(searchState));
        getProjectStatusDetails();
      }
    }

    function onFilterChange(filterModel) {
      if(filterModel.items.length >= 2){
        var check = filterModel.items;
        var columnList = []
        for(var item in check){
          if(!columnList.includes(check[item].columnField)){
            columnList.push(check[item].columnField)
          }
          else{ 
            if(check[item].value){
              toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
                position: toast.POSITION.TOP_RIGHT
              });
              filterModel.items.pop();
              setFilteredData(filterModel.items);
              return;
            }
          }
        }
      }
      
      setFilteredData(filterModel.items);
      if (filterModel !== null && filterModel.linkOperator !== "") {
        setLinkOperator(filterModel.linkOperator);
      }
      else {
        setLinkOperator(GridLinkOperator.And);
      }
      var filterData = [];
      if (filterModel.items !== null && filterModel.items.length > 0) {
        filterModel.items.map((item, index) => {
          if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
            return filterData.push({
              ColumnField: item.columnField,
              OperatorValue: item.operatorValue,
              Value: item.value ? item.value : null
            });
          }
          else {
            return filterData;
          }
        });
      }
      
      searchDispatch({ type: searchBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });
    }

    function searchReducer(searchState, action) {
      switch (action.type) {
        case searchBoxProp.search:
          return {
            ...searchState, Search: action.value,
          }
        case searchBoxProp.pageNumber:
          return {
            ...searchState, PageNumber: action.value,
          }
        case searchBoxProp.pageSize:
          return {
            ...searchState, PageNumber: 0, PageSize: action.value,
          }
        case searchBoxProp.setData:
          return {
            ...searchState,
            UserName: action.value.userName,
            UserRole: action.value.userRoleSystem.filter(e=>e.systemId ===3)[0].roleCode,
            PageSize: 20,
            PageNumber: 0,
            Search: "",
            TotalCount: 0,
            SortData: searchBoxProp.id,
            AscDesc: searchBoxProp.desc,
            FilterData: [],
            LinkOperator: ""
          }
        case searchBoxProp.setFilteredData:
          return {
            ...searchState,
            UserName: action.value.UserName,
            UserRole: action.value.UserRole,
            PageSize: action.value.PageSize,
            PageNumber: action.value.PageNumber,
            Search: action.value.Search,
            TotalCount: action.value.TotalCount,
            SortData: action.value.SortData,
            AscDesc: action.value.AscDesc,
            FilterData: action.value.FilterData,
            LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator
          }
        case searchBoxProp.columnFilterData:
          return {
            ...searchState,
            FilterData: action.value,
            LinkOperator: action.operator
          }
        case searchBoxProp.sortData:
          return {
            ...searchState,
            SortData: action.value[0].field,
            AscDesc: action.value[0].sort,
            PageNumber: 0
          }
        default:
          return {
            ...searchState
          }
      }
    }

    const callFnPageSize = React.useCallback((newPageSize) => {
      searchDispatch({ type: searchBoxProp.pageSize, value: newPageSize });
    }, []);
    const handleClosePrintExportEmailPopup = () => {
      setShowPrintExportEmailModal(false);
    };
    const deleteProjectStatus =(id)=>{
      setShowLoader(true);
      axios.post(apiUrls.deleteProjectStatusDetails,id, { headers: headers })
      .then((response) => {
          setShowLoader(false);
          if (response.status === responseCodes.success && response.data.responseMessage != null && response.data.responseCode === apiResponseCode.SuccessRequest){
            toast.warning(toastNoiticationConfig.recordDeletedSuccessfully , {
              position: toast.POSITION.TOP_RIGHT
            });
            submitSearch();
          }
      })
    .catch(error => {
      setShowLoader(false);
    });
    }

    const getProjectStatusDetails =()=>{
      setShowLoader(true);
      axios.post(apiUrls.getProjectStatusDetails,searchState, { headers: headers })
      .then((response) => {
          setShowLoader(false);
          if (response.status === responseCodes.success && response.data.responseMessage != null && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest){
            setProjectStatus([])
            setProjectStatus(response.data.projectStatusDetails);
            setRowsCount(response.data.totalRecords);
          }
      })
    .catch(error => {
      setShowLoader(false);
    });
    }

    const handleSortModelChange = (prop) => {
      if (prop != null && prop.length > 0) {
        searchDispatch({ type: searchBoxProp.sortData, value: prop });
      }
    }
    return(
        <>
          <Typography variant="h5" className="pageHeading">
            Project Status
          </Typography>
          <Box className="body-container homePageContainer" noValidate autoComplete="off">
            <div className="table-header">
                <div className="table-header-left">
                    <Paper className="paperStyleHome" 
                           sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >
                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                   placeholder="Search" 
                                   inputProps={{ 'aria-label': 'search' }} 
                                   value={searchBox}
                                   onKeyDown={(e) => {if(e.key === 'Enter'){searchDispatch({ type: searchBoxProp.search, value: e.target.value })} }} 
                                   onChange={(e) => { setSearchBox(e.target.value) }} 
                        />
                        <IconButton type="button" sx={{ p: '10px' }} 
                                   aria-label="search" 
                                    onClick={(e) => { searchDispatch({ type: searchBoxProp.search, value: searchBox }); }}
                        >
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                </div>

                <div className="table-header-right">
                    <NavLink style={{ textDecoration: 'none' }} 
                             to={componentConst.addUpdateProjectStatus}> 
                      <Button type="button" 
                              variant="contained" 
                              className="btnStyling" 
                      >Add
                      </Button>
                    </NavLink>&nbsp;

                    {role === roleName.admin &&<NavLink style={{ textDecoration: 'none' }} 
                             to={componentConst.uploadProjectStatus}>  
                      <Button type="button" variant="contained" className="btnStyling">
                        Upload 
                      </Button>
                    </NavLink>}&nbsp;
                    
                    <Button type="button" 
                            variant="contained" 
                            color="primary" 
                            className="btnStyling" 
                            onClick={CallPrintExportEmail}
                    >Export/Email</Button> &nbsp;
                </div>
            </div>

            <div className="table-body">
              <Card sx={{ minWidth: 275 }}>
              <div className="tableContainerHome" style={{ width: '100%' }}>
                <DataGridPro 
                  className="muiDataGrid" 
                  density={dataGridDensity.compact}
                  componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
                  components={{ Toolbar: GridToolbar, disableExport: true }}
                  disableColumnFilter={false}
                  rows={projectStatus}
                  onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                  rowCount={rowsCount}
                  columns={projectStatusTableHeader}
                  rowsPerPageOptions={[20, 50, 100, 500]}
                  pagination
                  page={searchState.PageNumber}
                  pageSize={searchState.PageSize}
                  paginationMode="server"
                  onPageChange={(data) => {
                    searchDispatch({ type: searchBoxProp.pageNumber, value: data });
                  }}
                  onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
                  rowHeight={size >= 1400 ? 50 : 20}
                  maxRowHeight={size >= 1400 ? 50 : 20}
                  minRowHeight={size >= 1400 ? 50 : 20}
                  headerHeight={size >= 1400 ? 50 : 22}
                  //onRowClick={(e) => onRowChange(e)}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  filterModel={{
                    items: filteredData,
                    linkOperator: linkOperator,
                  }}
              />

            </div>
          </Card>
        </div>
          </Box>
          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                timeout: 500,
                },
              }}
          >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}style={{ textAlign: 'center' }}>
              Are you sure you want to delete?
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
              
                <Button variant="contained" color="success" onClick={handleDelete}>Yes</Button>
                 &nbsp;
                <Button variant="contained"  color="info" onClick={handleClose}>No</Button>
      
              </Typography>
              
              
          </Box>
        </Fade>
      </Modal>
      <ToastContainer />
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.projectSatusReport} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportProjectStatusHeader} closePopup={handleClosePrintExportEmailPopup} sendEmail={callSendEmail}></PrintExportEmailModal>    
      <LoaderComponent show={showLoader}></LoaderComponent>
      
        </>
    );
}
export default ProjectStatusHome;
