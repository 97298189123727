import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "./Header.scss";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { NavLink } from "react-router-dom";
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import user from '../../../assets/avatar.jpg';
import IsUserAuthenticated from "../../../Hooks/IsUserAuthenticated";
import ValidateUrl from "../../../Hooks/ValidateUrl";
import UserDetails from "../../../Hooks/UserDetails";
import { commonConst, componentConst, errorMessageConst, confirmModalMessage, menuListKey } from "../../../Commoncode/constant";
import ConfirmModel from "../../Common/ConfModel/ConfirmModel";
import  LogOutUserData  from "../../../Commoncode/logOutUserData";

const Header = (props) => {
  let isUserLoggedin = IsUserAuthenticated();
  let isValidUrl = ValidateUrl();
  let userDetails = UserDetails();

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [actionButtonName, setActionButtonName] = useState("");
  const [cancelButtonName, setCancelButtonName] = useState("");

  let logout = () => {
    setConfirmModalShow(true);
    setConfirmMessage(confirmModalMessage.logOutMessage);
    setActionButtonName(confirmModalMessage.okBtn);
    setCancelButtonName(confirmModalMessage.cancelBtn);
  }

  const invokeActionFunction = () => {
    LogOutUserData();
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === menuListKey.tab) {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === menuListKey.escape) {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  if (isUserLoggedin) {
    if(!isValidUrl)
    {
      return (
        <Redirect to={componentConst.systemAccess} />
      );
    }
    else
    {
    return (
      <React.Fragment>
        <div className="top-container">
          <div className="navbar-wrapper">
            <div className="navbar-minimize" onClick={props.onMenuToggleClick}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
            <div className="page-title">Welcome <strong>{userDetails.userFirstName} {userDetails.userLastName} </strong> {userDetails.userAgencyName != null ? " - " + userDetails.userAgencyName : ""}</div>
          </div>
          <div className="navbar-collapse">
            <div className="user-right">
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <img src={user} className="user-img" alt="logo" />
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                className="user-drop"
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom-start" ? "left top" : "left bottom", }} >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown} >
                          <MenuItem>{userDetails.userFirstName} {userDetails.userLastName}</MenuItem>
                          <MenuItem><NavLink style={{ textDecoration: 'none' }} to={componentConst.systemAccess}> System Access</NavLink></MenuItem>
                          <MenuItem onClick={logout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </div>
        <ConfirmModel showConfirmModal={confirmModalShow} message={confirmMessage} actionButtonName={actionButtonName} cancelButtonName={cancelButtonName} confirmAction={invokeActionFunction} closeConfirmModal={() => setConfirmModalShow(false)} />
      </React.Fragment>);
    }
  }
  else {
    localStorage.removeItem(commonConst.userInfo);
    localStorage.removeItem(commonConst.refrenceData);
    localStorage.removeItem(commonConst.msg);
    localStorage.removeItem(commonConst.homeData);
    localStorage.removeItem(errorMessageConst.blockedUser);
    return (
      <Redirect to={componentConst.loginOption} />
    );
  }
};

export default Header;
