import axios from "axios";
import React,{ useEffect, useState  } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Typography,Box} from '@mui/material';
import { headers,apiUrls, apiResponseCode,responseCodes,utilityDetails } from "../../Commoncode/constant";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@mui/material';
const Utility = ()=>{
    const [showLoader, setShowLoader] = useState(false);
    const [utilityData,setUtilityData] = useState(utilityDetails);
    useEffect(() =>{
        setShowLoader(true);
        axios.get(apiUrls.getUtilityDetails, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    utilityData["Total bond numbers"] = response.data.lastBondNumber;
                    utilityData["Current Prefix"] = response.data.prefix;
                    utilityData["Bond Numbers left"] = response.data.bondNumberRemaining;
                    utilityData["Last used bond number"] = response.data.lastUsedBondNumber;
                    setUtilityData(utilityData);
                }
                else {
                    setShowLoader(false);
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    },[utilityData])
    return(
        <React.Fragment>
            <Typography variant="h5" className="pageHeading" style={{ textAlign: 'center' }}>
                 Bond Details
            </Typography>
            <Box className="body-container">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                          <TableCell style={{fontWeight: 'bold'}}>Bond details</TableCell>
                          <TableCell align="right" style={{fontWeight: 'bold'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.entries(utilityData).map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row[0]}
                        </TableCell>
                        <TableCell align="right">{row[1]}</TableCell>
                        </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Box>
            <LoaderComponent show={showLoader}></LoaderComponent>
        </React.Fragment>
    )
}

export default Utility;