import axios from "axios";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useReducer, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { Button, Typography, Box } from '@mui/material';
import "./AgencyVariance.scss";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { defaultExportValues, tableHeader, requestProp, requestPropCheck, exportHeader } from "../../Models/agencyVariance";
import { headers, filterOperators, apiUrls, apiResponseCode, componentConst, toastNoiticationConfig, responseCodes, exportFileName, dataGridDensity } from "../../Commoncode/constant";
import "../../style/Style.scss";
import PrintExportEmailModal from "../Common/PrintExportEmailModal/PrintExportEmailModal";


const AgencyVariance = () => {
  const size = window.innerWidth;

  const [showLoader, setShowLoader] = useState(false);
  const [tableRows, settableRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [searchState, searchDispatch] = useReducer(searchReducer, requestProp);

  const [showPrintExportEmailModal, setShowPrintExportEmailModal] = useState(false);
  const [exportData, setExportData] = useState(defaultExportValues);
  const [exportAllowed, setExportAllowed] = useState(true);

  useEffect(() => {
    let loggedInUserDetails = UserDetails();
    if (loggedInUserDetails !== false) {
      searchDispatch({ type: requestPropCheck.setData, value: loggedInUserDetails });
    }
  }, [])

  useEffect(() => {
    submitSearch();
    // eslint-disable-next-line
  }, [searchState])


  function searchReducer(searchState, action) {
    switch (action.type) {
      case requestPropCheck.pageNumber:
        return {
          ...searchState, PageNumber: action.value,
        }
      case requestPropCheck.pageSize:
        return {
          ...searchState, PageSize: action.value, PageNumber: 0
        }
      case requestPropCheck.setData:
        return {
          ...searchState,
          UserName: action.value.userName,
          UserRole: action.value.userRoleCode,
          PageNumber: 0,
          PageSize: 20,
          Export: false,
          SortData: "",
          AscDesc: "",
          FilterData: action.value.FilterData,
          LinkOperator: GridLinkOperator.And
        }
      case requestPropCheck.sortData:
        return {
          ...searchState,
          SortData: action.value[0].field,
          AscDesc: action.value[0].sort,
          PageNumber: 0
        }
      case requestPropCheck.setFilteredData:
        return {
          ...searchState,
          UserName: action.value.UserName,
          UserRole: action.value.UserRole,
          PageSize: action.value.PageSize,
          PageNumber: action.value.PageNumber,
          SortData: action.value.SortData,
          AscDesc: action.value.AscDesc,
          FilterData: action.value.FilterData,
          LinkOperator: action.value.LinkOperator === "" ? GridLinkOperator.And : action.value.LinkOperator
        }
      case requestPropCheck.columnFilterData:
        return {
          ...searchState,
          FilterData: action.value,
          LinkOperator: action.operator
        }
      default:
        return {
          ...searchState
        }
    }
  }


  function submitSearch() {
    if (searchState.UserName === "") {
      return false;
    }
    setShowLoader(true);
    axios.post(apiUrls.agencyVarianceReport, searchState, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        settableRows([]);
        settableRows(response.data.agencyVarianceDetails.getAgencyVarianceDtls);
        setRowsCount(response.data.agencyVarianceDetails.totalCount);
      })
      .catch(error => {
        setShowLoader(false);
      });
  }

  function onFilterChange(filterModel) {
    var filterData = [];
    if(filterModel.items.length >= 2){
      var check = filterModel.items;
      var columnList = []
      for(var item in check){
        if(!columnList.includes(check[item].columnField)){
          
          columnList.push(check[item].columnField)
        }
          else{ 
            if(check[item].value){
              
            toast.warning(toastNoiticationConfig.duplicateGridFilterMessage , {
              position: toast.POSITION.TOP_RIGHT
            });
            filterModel.items.pop();
            //setFilteredData(filterModel.items);
            return;
            }
          }
        }
    }
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: requestPropCheck.columnFilterData, value: filterData, operator: filterModel.linkOperator });

  }

  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: requestPropCheck.sortData, value: prop });
    }
  }

  const callFnPageSize = React.useCallback((newPageSize) => {
    searchDispatch({ type: requestPropCheck.pageSize, value: newPageSize });
  }, []);


  const CallPrintExportEmail = () => {
    let exportData = { ...searchState, Export: true };

    setShowLoader(true);
    axios.post(apiUrls.agencyVarianceReport, exportData, { headers: headers })
      .then((response) => {
        setShowLoader(false);
        setExportData(defaultExportValues);

        if (response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.DataTooLarge) {
          setExportAllowed(false);
          setShowPrintExportEmailModal(true);
        }
        else if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          if (response.data.agencyVarianceDetails.getAgencyVarianceDtls.length > 0) {
            setExportData(response.data.agencyVarianceDetails.getAgencyVarianceDtls);
            setExportAllowed(true);
            setShowPrintExportEmailModal(true);
          }
          else
          {
            toast.warning(toastNoiticationConfig.noRecordFound, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }

  const handleClosePrintExportEmailPopup = () => {
    setShowPrintExportEmailModal(false);
  };

  const callSendEmail = () => {

    setShowPrintExportEmailModal(false);
    let emailRequestDetails = { ...searchState, Export: true };
    setShowLoader(true);
    axios.post(apiUrls.emailAgencyVarianceReport, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        else {
          toast.success(toastNoiticationConfig.emailNotSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }

  const handleCloseExportPopup = () => {

    toast.success(toastNoiticationConfig.exportSuccessfully, {
      position: toast.POSITION.TOP_RIGHT
    });

    setShowPrintExportEmailModal(false);
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        Agency Booked Variance Report
      </Typography>

      <div className="body-container">

        <Box sx={{ height: 417, overflow: "auto" }}>
          <DataGridPro autoHeight={false} className="muiDataGrid" rows={tableRows} columns={tableHeader}
            density={dataGridDensity.compact}
            componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
            components={{ Toolbar: GridToolbar, disableExport: true }}
            disableColumnFilter={false}
            onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
            rowCount={rowsCount}
            rowsPerPageOptions={[20, 50, 100, 500]}
            pagination
            page={searchState.PageNumber}
            pageSize={searchState.PageSize}
            paginationMode="server"
            onPageChange={(data) => {
              searchDispatch({ type: requestPropCheck.pageNumber, value: data });
            }}
            onPageSizeChange={(newPageSize) => callFnPageSize(newPageSize)}
            rowHeight={size >= 1400 ? 50 : 20}
            maxRowHeight={size >= 1400 ? 50 : 20}
            minRowHeight={size >= 1400 ? 50 : 20}
            headerHeight={size >= 1400 ? 50 : 22}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </div>

      <div className="col-md-12">
        <Box textAlign="center">
          <Button type="button" variant="contained" color="primary" className="btnStyling" onClick={CallPrintExportEmail}>Export/Email</Button>
          <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}>  <Button type="button" variant="contained" color="primary" className="btnStyling">Cancel</Button> </NavLink> &nbsp;
        </Box>
      </div>

      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
      <PrintExportEmailModal isExportValid={exportAllowed} filename={exportFileName.agencyVarianceReport} showDialogModal={showPrintExportEmailModal} data={exportData} headers={exportHeader} closePopup={handleClosePrintExportEmailPopup} closeExportPopup={handleCloseExportPopup} sendEmail={callSendEmail}></PrintExportEmailModal>
    </React.Fragment>
  )
}

export default AgencyVariance;