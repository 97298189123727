export const submissionProp = {
    LogId:0,
    AccountName: "",
    AgencyName: "",
    BrokerName: "",
    UnderwriterId: 0,
    RegionId: 0,
    StatusId: 0,
    AccountId: 0,
    Program: "",
    SubmittedDate: "",
    Comments: "",
    CurrentSurety:"",
    isFormUpdated: false
}
export const submissionValidateProp = {
    AccountName: "AccountName",
    AgencyName: "AgencyName",
    BrokerName: "BrokerName",
    UnderwriterId: "UnderwriterId",
    RegionId: "RegionId",
    StatusId: "StatusId",
    AccountId: "AccountId",
    Program: "Program",
    CurrentSurety: "CurrentSurety",
    SubmittedDate: "SubmittedDate",
    Comments: "Comments",
    SetProp:"SetProp",
    Reset:"Reset"
}

export const defaultDDL = {
    key: 0,
    value: ""
}


export const exportSubmissionHeader =
  [
    { key: 'accountName', label: 'Account Name', width: 150 },
    { key: 'agencyName', label: 'Agency Name', width: 200 },
    { key: 'brokerName', label: 'Broker Name', width: 200 },
    { key: 'underwriter', label: 'Underwriter', width: 220 },
    { key: 'status', label: 'Status', width: 205 },
    { key: 'account', label: 'Account', width: 205 },
    { key: 'currentSurety', label: 'Current Surety', width: 205 },
    { key: 'program', label: 'Program' },
    { key: 'lastUpdateBy', label: 'Last Update By', width: 205 },
    { key: 'lastUpdate', label: 'Last Update', width: 200 }
  ]