import { commonConst,systemAccess,componentConst } from "../Commoncode/constant";
import { decryptData } from "../Commoncode/encryptDecrypt";


export default function ValidateUrl() {
    let userInfo = localStorage.getItem(commonConst.userInfo);
    if (userInfo) {
        var currentSystem = decryptData(userInfo).userSystem;

        var path = window.location.pathname.toLowerCase();
        if(currentSystem === systemAccess.reservation)
        {
            if(path === componentConst.systemAccess.toLowerCase() || path === componentConst.home.toLowerCase() || path === componentConst.reserveBond.toLowerCase() || path === componentConst.displayReserveBond.toLowerCase() || path === componentConst.updateBond.toLowerCase() || path === componentConst.agencyVarianceReport.toLowerCase() || path === componentConst.bondThirtyDaysReport.toLowerCase() || path === componentConst.utility.toLowerCase() || path === componentConst.agency.toLowerCase() || path === componentConst.addUpdateAgency.toLowerCase() || path === componentConst.key.toLowerCase() || path === componentConst.agent.toLowerCase()|| path === componentConst.addUpdateAgent.toLowerCase() ||path === componentConst.bondUpload.toLowerCase()||path === componentConst.users.toLowerCase()||path === componentConst.addUpdateUser.toLowerCase())
            {
                return true;
            }
            else
                return false;
        }
        else if(currentSystem === systemAccess.submission)
        {
            if(path === componentConst.systemAccess.toLowerCase() 
            || path === componentConst.submission.toLowerCase() 
            || path === componentConst.createSubmission.toLowerCase() 
            || path === componentConst.uwsubmission.toLowerCase())
            {
                return true;
            }
            else
                return false;
        }
        else if(currentSystem === systemAccess.projectStatus)
        {
            if(path === componentConst.systemAccess.toLowerCase() 
                ||path === componentConst.projectStatus.toLowerCase()
                ||path === componentConst.addUpdateProjectStatus.toLowerCase()
                ||path === componentConst.uploadProjectStatus.toLowerCase())
            {
                return true;
            }
            else
                return false;
        }
        else if(path === componentConst.systemAccess.toLowerCase())
        {
                return true;
        }
        else
        {
            return false;
        }
    }
    else {
        return false;
    }
}