import React from "react";
import "./LoaderComponent.css";

function LoaderComponent(props) {

    return (<>
        {props.show ?
            <div>
                <div className="bachground-block"></div>
                <div className="spinner-border text-primary centered" role="status">
                    <span className="sr-only"></span>
                </div>
            </div> : ''}
    </>
    );
}
export default LoaderComponent;