import axios from "axios";
import React, { useEffect, useState, useReducer } from "react";
import { NavLink } from "react-router-dom";
import { DataGridPro, GridToolbar, GridLinkOperator } from '@mui/x-data-grid-pro';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography, Card, Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LoaderComponent from "../../Common/Loader/LoaderComponent";
import { agentTableHeader, searchBoxProp } from "../../../Models/agentUtility";
import Button from '@mui/material/Button';
import { headers, apiUrls, responseCodes, apiResponseCode, componentConst, skipToCheck, filterOperators, dataGridDensity } from "../../../Commoncode/constant"
const AgentDashboard = ({ location }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [searchBox, setSearchBox] = useState("");
  const [AgentList, setAgentList] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [linkOperator, setLinkOperator] = useState(GridLinkOperator.And);
  const [filteredData, setFilteredData] = useState([]);
  const [agentSearchState, searchDispatch] = useReducer(searchReducer, searchBox);
  const size = window.innerWidth;

  useEffect(() => {
    var result = JSON.parse(window.localStorage.getItem('filterModelAgent'));
    if (result) {
      var filterData = [];
      setSearchBox(result.Search);
      setFilteredData(filterData);
      setLinkOperator(result.LinkOperator);
      searchDispatch({ type: searchBoxProp.setFilteredData, value: result });
    } else {
      searchDispatch({ type: searchBoxProp.setData });
    }
    if (location.state != null && location.state !== "") {
      toast.success(location.state, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }, []);

  useEffect(() => {
    getAgentValue();
  }, [agentSearchState])

  function getAgentValue() {
    var isDataAvailable = false;

    for (const item in agentSearchState) {
      if (agentSearchState[item] && item !== skipToCheck.pageNo && agentSearchState[item].toString().trim() !== "") {
        isDataAvailable = true;
      }
    }

    if (isDataAvailable) {
      window.localStorage.setItem('filterModelAgent', JSON.stringify(agentSearchState));
      setShowLoader(true);

      axios.post(apiUrls.getAgentUserList, agentSearchState, { headers: headers })
        .then((response) => {
          setShowLoader(false);
          if (response.status === responseCodes.success && response.data.responseMessage.responseMessage.length > 0 && response.data.responseMessage.responseCode === apiResponseCode.SuccessRequest) {
            var result = response.data.agentDetails;
            setAgentList(result);
            setRowsCount(response.data.recordCount);
          }
        })
        .catch(error => {
          setShowLoader(false);
        });
    }
  }

  function searchAgent() {
    getAgentValue();
  }
  function searchReducer(agentSearchState, action) {
    switch (action.type) {
     
      case searchBoxProp.search:
        return {
          ...agentSearchState, Search: action.value,
        }
      case searchBoxProp.pageNumber:
        return {
          ...agentSearchState, Page: action.value,
        }
      case searchBoxProp.pageSize:
        return {
          ...agentSearchState, Page: 0, PageSize: action.value,
        }
      case searchBoxProp.setData:
        return {
          ...agentSearchState,
          Search:"",
          Page:0,
          PageSize:50,
          SortType:"desc",
          SortingColumn:"Id",
          RoleId:1
        }
      case searchBoxProp.setFilteredData:
        return {
          ...agentSearchState,
          Search:"",
          Page:action.value.Page,
          PageSize:action.value.PageSize,
          SortType:action.value.AscDesc,
          SortingColumn:action.value.SortData,
          RoleId:1
        }
      case searchBoxProp.sortData:
        return {
          ...agentSearchState,
          SortingColumn: action.value[0].field,
          SortType: action.value[0].sort,
          Page: 0
        }
      default:
        return {
          ...agentSearchState
        }
    }
  }

  function changePageSize(newPageSize) {
    searchDispatch({ type: searchBoxProp.pageSize, value: newPageSize });
  }
  function onFilterChange(filterModel) {
    setFilteredData(filterModel.items);
    if (filterModel !== null && filterModel.linkOperator !== "") {
      setLinkOperator(filterModel.linkOperator);
    }
    else {
      setLinkOperator(GridLinkOperator.And);
    }

    var filterData = [];
    if (filterModel.items !== null && filterModel.items.length > 0) {
      filterModel.items.map((item, index) => {
        if ((item.value !== undefined && item.value !== "") || (item.operatorValue === filterOperators.isEmpty || item.operatorValue === filterOperators.isNotEmpty)) {
          return filterData.push({
            ColumnField: item.columnField,
            OperatorValue: item.operatorValue,
            Value: item.value ? item.value : null
          });
        }
        else {
          return filterData;
        }
      });
    }
    searchDispatch({ type: searchBoxProp.columnFilterData, value: filterData, operator: filterModel.linkOperator === "" ? linkOperator : filterModel.linkOperator });
  }
  const handleSortModelChange = (prop) => {
    if (prop != null && prop.length > 0) {
      searchDispatch({ type: searchBoxProp.sortData, value: prop });
    }
  }
  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        Agents
      </Typography>
      <Box className="body-container homePageContainer" noValidate autoComplete="off" >
        <div className="table-header">
          <div className="table-header-left">
            <Paper className="paperStyleHome" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} value={searchBox}
                onKeyPress={(e) => e.key === 'Enter' && searchDispatch({ type: searchBoxProp.search, value: e.target.value })} onChange={(e) => { setSearchBox(e.target.value) }} />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => { searchDispatch({ type: searchBoxProp.search, value: searchBox }); }}>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
          </div>

          <div className="table-header-right">
            <NavLink to={{ pathname: componentConst.addUpdateAgent }}><Button type="button" variant="contained" className="btnStyling">Add</Button></NavLink>&nbsp;
          </div>
        </div>
        <div className="table-body">
          <Card sx={{ minWidth: 275 }}>
            <div className="tableContainerHome" style={{ width: '100%' }}>

              <DataGridPro
                getRowId={(row) => row.id}
                density={dataGridDensity.compact}
                componentsProps={
                  {
                    toolbar:
                    {
                      printOptions:
                        { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true }
                    }
                  }
                }
                components={{ Toolbar: GridToolbar, disableExport: true }}
                className="muiDataGrid"
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                disableColumnFilter={false}
                columns={agentTableHeader}
                rows={AgentList}
                rowsPerPageOptions={[20, 50, 100, 500]}
                rowCount={rowsCount}
                rowHeight={size >= 1400 ? 50 : 20}
                maxRowHeight={size >= 1400 ? 50 : 20}
                minRowHeight={size >= 1400 ? 50 : 20}
                headerHeight={size >= 1400 ? 50 : 22}
                page={agentSearchState.Page}
                pageSize={agentSearchState.PageSize}
                paginationMode="server"
                disableSelectionOnClick={true}
                filter={false}
                onPageChange={(data) => {
                  searchDispatch({ type: searchBoxProp.pageNumber, value: data });
                }}
                onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
                pagination
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                hideFilters={true}
              />
            </div>
          </Card>
        </div>
      </Box>
      <ToastContainer />
      <LoaderComponent show={showLoader}></LoaderComponent>
    </React.Fragment>
  )
}
export default AgentDashboard;