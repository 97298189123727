import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Autocomplete, Button, Typography, TextField, Checkbox,FormControlLabel } from '@mui/material'
import { useHistory } from "react-router-dom";
import React, { useState, useReducer, useEffect } from "react";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { removeTime } from "../../Hooks/Validation";
import { headers, bondStatusCode, apiUrls, apiResponseCode, roleName, skipToCheck, eventsConst, toastNoiticationConfig, responseCodes, confirmModalMessage, note } from "../../Commoncode/constant";
import "../displayReserveBond/DisplayReserveBond.scss";
import Box from '@mui/material/Box';
import "../../style/Style.scss";
import "./UpdateBond.scss";
import { updateBondPropByName, updateBondProp, bondDataValidateProp, defaultStatusList, defaultProfitCenterList, defaultDepartmentList, defaultProductList, agencyListProp } from "../../Models/updateBond";
import ConfirmModel from "../Common/ConfModel/ConfirmModel";

const UpdateBond = (props) => {

    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [actionButtonName, setActionButtonName] = useState("");
    const [cancelButtonName, setCancelButtonName] = useState("");

    const [userRole, setUserRole] = useState(roleName.ua);
    const [statusFieldDisabled, setStatusFieldDisabled] = useState(false);

    const [agentItems, setAgentItem] = useState([]);
    const [agentItemsValue, setAgentItemValue] = useState(agencyListProp);
    const [bookedAgentItemsValue, setBookedAgentItemValue] = useState(agencyListProp);

    const [statusItems, setStatusItems] = useState([]);
    const [statusItemsValue, setStatusItemsValue] = useState(defaultStatusList);

    const [profitCenterItems, setProfitCenterItems] = useState([]);
    const [profitCenterItemsValue, setProfitCenterItemsValue] = useState(defaultProfitCenterList);

    const [departmentItems, setDepartmentItems] = useState([]);
    const [departmentItemsValue, setDepartmentItemsValue] = useState(defaultDepartmentList);

    const [productItems, setProductItems] = useState([]);
    const [productItemsValue, setProductItemsValue] = useState(defaultProductList);

    const [showLoader, setShowLoader] = useState(false);
    const [state, Dispatch] = useReducer(Reducer, updateBondProp);
    useEffect(() => {
        if (props.location.search) {
            let loggedInUserDetails = UserDetails();
            setUserRole(loggedInUserDetails.userRoleName);

            const search = props.location.search.replace(/#/g, "?");
            const params = new URLSearchParams(search);

            var bondNum = params.get('bondnum');
            if (bondNum === undefined || bondNum === null || bondNum === "") {
                document.getElementById("homeLink").click();
            }
            Dispatch({ type: updateBondPropByName.reset });
            setBondDetails(bondNum);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search])

    function setBondDetails(bondNum) {
        let loggedInUserDetails = UserDetails();
        var getBondUpdateRequest = {
            UserName: loggedInUserDetails.userName,
            BondNumber: bondNum
        }
        setShowLoader(true);
        axios.post(apiUrls.getBondUpdateReservationDetails, getBondUpdateRequest, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === responseCodes.success && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setAgentItem(response.data.bondUpdateDetails.agencyDetails);
                    setAgentItemValue(response.data.bondUpdateDetails.agencyDetails.find(item => item.agencynumber === response.data.bondUpdateDetails.agencyNum));
                    setBookedAgentItemValue(response.data.bondUpdateDetails.agencyDetails.find(item => item.agencynumber === response.data.bondUpdateDetails.bookedAgencyNum))

                    if (response.data.bondUpdateDetails.statusCode === bondStatusCode.booked && userRole !== roleName.admin) {
                        setStatusFieldDisabled(true);
                    }

                    let statusList = response.data.bondUpdateDetails.statusDetails === null ? [] : response.data.bondUpdateDetails.statusDetails;
                    if (loggedInUserDetails.userRoleName === roleName.agent) {

                        if (response.data.bondUpdateDetails.statusCode === bondStatusCode.reserved) {
                            setStatusItems(statusList.filter(item => item.statusCode === bondStatusCode.reserved || item.statusCode === bondStatusCode.void));
                        }
                        else if (response.data.bondUpdateDetails.statusCode === bondStatusCode.void) {
                            setStatusItems(statusList.filter(item => item.statusCode === bondStatusCode.void));
                        }
                        else {
                            setStatusItems(statusList.filter(item => item.statusCode === response.data.bondUpdateDetails.statusCode));
                        }

                    }
                    else if (loggedInUserDetails.userRoleName !== roleName.admin) {
                        setStatusItems(statusList.filter(item => item.statusCode === bondStatusCode.reserved || item.statusCode === bondStatusCode.void || item.statusCode === bondStatusCode.agtClosed  || item.statusCode === bondStatusCode.over36));
                    }
                    else {
                        setStatusItems(response.data.bondUpdateDetails.statusDetails === null ? [] : response.data.bondUpdateDetails.statusDetails);
                    }
                    if (response.data.bondUpdateDetails.statusDetails.find(item => item.statusCode === response.data.bondUpdateDetails.statusCode) !== undefined) {
                        setStatusItemsValue(response.data.bondUpdateDetails.statusDetails.find(item => item.statusCode === response.data.bondUpdateDetails.statusCode));
                    }

                    setProfitCenterItems(response.data.bondUpdateDetails.profitCenterDetails === null ? [] : response.data.bondUpdateDetails.profitCenterDetails);
                    if (response.data.bondUpdateDetails.profitCenterDetails.find(item => item.profitCenterCode === response.data.bondUpdateDetails.profitCenterCode) !== undefined) {
                        setProfitCenterItemsValue(response.data.bondUpdateDetails.profitCenterDetails.find(item => item.profitCenterCode === response.data.bondUpdateDetails.profitCenterCode));
                    }

                    setDepartmentItems(response.data.bondUpdateDetails.deptDetails == null ? [] : response.data.bondUpdateDetails.deptDetails);
                    if (response.data.bondUpdateDetails.deptDetails !== null && response.data.bondUpdateDetails.deptDetails.find(item => item.deptCode === response.data.bondUpdateDetails.departmentCode) !== undefined) {
                        setDepartmentItemsValue(response.data.bondUpdateDetails.deptDetails.find(item => item.deptCode === response.data.bondUpdateDetails.departmentCode));
                    }

                    setProductItems(response.data.bondUpdateDetails.prodDetails == null ? [] : response.data.bondUpdateDetails.prodDetails);
                    if (response.data.bondUpdateDetails.prodDetails !== null && response.data.bondUpdateDetails.prodDetails.find(item => item.productCode === response.data.bondUpdateDetails.productCode) !== undefined) {
                        setProductItemsValue(response.data.bondUpdateDetails.prodDetails.find(item => item.productCode === response.data.bondUpdateDetails.productCode));
                    }

                    Dispatch({ type: updateBondPropByName.setProp, value: response.data.bondUpdateDetails });
                }
                else {
                    setShowLoader(false);
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }

    function Reducer(state, action) {
        let loggedInUserDetail = UserDetails();
        switch (action.type) {
            case updateBondPropByName.agencyNum:
                return {
                    ...state, AgencyNum: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.insuredName:
                return {
                    ...state, InsuredName: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.bookedAgencyNum:
                return {
                    ...state, BookedAgencyNum: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.statusCode:
                return {
                    ...state, StatusCode: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.profitCenterCode:
                return {
                    ...state, ProfitCenterCode: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.departmentCode:
                return {
                    ...state, DepartmentCode: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.productCode:
                return {
                    ...state, ProductCode: action.value,
                    isFormUpdated: true
                }
            case updateBondPropByName.comment:
                return {
                    ...state, Comment: action.value,
                }
            case updateBondPropByName.internalComments:
                return {
                    ...state, InternalComments:action.value,
                    isFormUpdated: true
            }
            case updateBondPropByName.agencyVarianceCheck:
                return{
                    ...state, AgencyVarianceResolved:state.AgencyVarianceResolved === true ? false : true,
                    isFormUpdated: true
                }
            case updateBondPropByName.dateBooked:
                return {
                    ...state, DateBooked: removeTime(action.value),
                    isFormUpdated: true
                }
            case updateBondPropByName.dateReserved:
                return {
                    ...state, DateReserved: removeTime(action.value),
                    isFormUpdated: true
                }
            case updateBondPropByName.setProp:
                return {
                    ...state,
                    BondNumber: action.value.bondNumber,
                    UserName: loggedInUserDetail.userName,
                    InsuredName: action.value.insuredName === null ? "" : action.value.insuredName,
                    DateReserved: action.value.dateReserved === null ? "" : removeTime(action.value.dateReserved),
                    DateBooked: action.value.dateBooked === null ? "" : removeTime(action.value.dateBooked),
                    Comment: action.value.comment === null ? "" : action.value.comment,
                    AgencyNum: action.value.agencyNum,
                    StatusCode: action.value.statusCode === null ? "" : action.value.statusCode,
                    ProfitCenterCode: action.value.profitCenterCode === null ? 0 : action.value.profitCenterCode,
                    DepartmentCode: action.value.departmentCode === null ? 0 : action.value.departmentCode,
                    ProductCode: action.value.productCode === null ? 0 : action.value.productCode,
                    BookedAgencyNum: action.value.bookedAgencyNum === null ? "" : action.value.bookedAgencyNum,
                    isFormUpdated: false,
                    AgencyVarianceResolved: action.value.agencyVarianceResolved === null ? "false" : action.value.agencyVarianceResolved,
                    InternalComments:action.value.internalComments === null ? "" : action.value.internalComments,
                }
            case updateBondPropByName.reset:
                return {
                    ...state,
                    BondNumber: "",
                    UserName: "",
                    InsuredName: "",
                    DateReserved: "",
                    DateBooked: "",
                    Comment: "",
                    AgencyNum: "",
                    StatusCode: "",
                    ProfitCenterCode: 0,
                    DepartmentCode: 0,
                    ProductCode: 0,
                    isFormUpdated: false,
                    BookedAgencyNum: ""
                }
            default:
                return {
                    ...state
                }
        }
    }

    function bindDepartment(profitCenterCode) {
        setDepartmentItems([]);
        setDepartmentItemsValue(defaultDepartmentList);
        Dispatch({ type: updateBondPropByName.departmentCode, value: 0 })

        setProductItems([]);
        setProductItemsValue(defaultProductList);
        Dispatch({ type: updateBondPropByName.productCode, value: 0 })

        if (profitCenterCode === "" || profitCenterCode === 0) {
            return false;
        }

        var getDetailsRequest = {
            ProfitCenterCode: profitCenterCode,
            DepartmentCode: 0
        }

        setShowLoader(true);
        axios.post(apiUrls.bindDepartmentByProfitCenter, getDetailsRequest, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === 200 && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setDepartmentItems(response.data.getDetailsData.deptDetails);
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }
        let history = useHistory();
        const goToPreviousPath = () => {
            history.goBack()
        }
    function bindProduct(departmentCode) {
        setProductItems([]);
        setProductItemsValue(defaultProductList);
        Dispatch({ type: updateBondPropByName.productCode, value: 0 })

        if (departmentCode === 0 || departmentCode === "") {
            return false;
        }

        var getDetailsRequest = {
            DepartmentCode: departmentCode
        }

        setShowLoader(true);
        axios.post(apiUrls.bindProductByDepartment, getDetailsRequest, { headers: headers })
            .then((response) => {
                setShowLoader(false);
                if (response.status === 200 && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
                    setProductItems(response.data.getDetailsData.productDetails);
                }
            })
            .catch(error => {
                setShowLoader(false);
            });
    }


    const invokeActionFunction = () => {
        var isDataAvailable = false;

        for (const item in state) {
            if (state[item] && item !== skipToCheck.isFormUpdated && state[item].toString().trim() !== "") {
                isDataAvailable = true;
            }
        }

        if (isDataAvailable) {
            setShowLoader(true);
            axios.post(apiUrls.postBondUpdateReservationDetails, state, { headers: headers })
                .then((response) => {
                    setShowLoader(false);
                    if (response.status === 200 && response.data.responseMessages.responseMessage.length > 0 && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

                        toast.success(toastNoiticationConfig.bondUpdatedSuccessfully, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setConfirmModalShow(false);
                        goToPreviousPath();
                        
                    }
                })
                .catch(error => {
                    setShowLoader(false);
                });
        }
    }

    let updateButtonClick = () => {
        if (userRole === roleName.admin && state[bondDataValidateProp.StatusCode] === bondStatusCode.booked && state[bondDataValidateProp.BookedAgencyNum] === "") {
            toast.warning(toastNoiticationConfig.bookingAgencyRequired, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (userRole === roleName.admin && state[bondDataValidateProp.StatusCode] === bondStatusCode.booked && state[bondDataValidateProp.DateBooked] === "") {
            toast.warning(toastNoiticationConfig.bookingDateRequired, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (state[bondDataValidateProp.StatusCode] === bondStatusCode.void && state[bondDataValidateProp.Comment] === "") {
            toast.warning(toastNoiticationConfig.commentRequired, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        
        setConfirmModalShow(true);
        setConfirmMessage(confirmModalMessage.modifyBondDetails);
        setActionButtonName(confirmModalMessage.actionNameYes);
        setCancelButtonName(confirmModalMessage.actionNameNo);

    }

   
    return (
        <React.Fragment>

            <Typography variant="h5" className="pageHeading" style={{ textAlign: 'center' }}>
                Update Reservation Details
            </Typography>

            <Box className="body-container" component="form" noValidate autoComplete="off">

                <div>
                    <TextField className="col-6" size="small" disabled={true} id="txtBondNumber" label="Bond Number" value={state.BondNumber} />

                    <Autocomplete disabled={userRole === roleName.admin ? false : true} className="col-6" id="ddlSelectAgent" size="small" options={agentItems} value={agentItemsValue} getOptionLabel={(option) => option.agencyName}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.agencyNum, value: value.agencynumber }); setAgentItemValue({ agencyNumber: value.agencynumber, agencyName: event.target.innerHTML }) }}
                        renderOption={(props, agentItems) => {
                            return (
                                <li {...props} key={agentItems.agencynumber}>
                                    {agentItems.agencyName}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField size="small" {...params} label="Select agent" id="textAgency" placeholder="Agency number - Agent name" />
                        )} />

                    {userRole === roleName.agent ? false : true && <Autocomplete disabled={userRole === roleName.admin ? statusItemsValue.statusCode === bondStatusCode.booked ? false : true : true} className="col-6" id="ddlBookedAgency" size="small" options={agentItems} value={bookedAgentItemsValue} getOptionLabel={(option) => option.agencyName}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.bookedAgencyNum, value: value.agencynumber }); setBookedAgentItemValue({ agencyNumber: value.agencynumber, agencyName: event.target.innerHTML }) }}
                        renderOption={(props, agentItems) => {
                            return (
                                <li {...props} key={agentItems.agencynumber}>
                                    {agentItems.agencyName}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Booked agency" id="bookedAgency" placeholder="Agency number - Agent name" />
                        )} />}

                    {userRole === roleName.agent ? false : true && <TextField disabled={userRole === roleName.admin ? statusItemsValue.statusCode === bondStatusCode.booked ? false : true : true} value={state.DateBooked} id="date" label="Date Booked" type="date" className="col-6" size="small" InputLabelProps={{ shrink: true, }}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.dateBooked, value: event.target.value }) }} />}

                    <TextField size="small" disabled={userRole === roleName.admin ? false : true} value={state.DateReserved} id="dateReserved" label="Date Reserved" type="date" className="col-6" InputLabelProps={{ shrink: true, }}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.dateReserved, value: event.target.value }) }} />

                    <TextField size="small" disabled={userRole === roleName.admin ? false : true} className="col-6" id="txtInsuredName" label="Insured Name" value={state.InsuredName} onChange={(event) => { Dispatch({ type: updateBondPropByName.insuredName, event: eventsConst.onChange, value: event.target.value }); }} />

                    <Autocomplete disabled={userRole === roleName.inquiry ? true : (userRole === roleName.admin ? false : statusFieldDisabled)} className="col-6" id="ddlStatus" size="small" options={statusItems} value={statusItemsValue} getOptionLabel={(option) => option.statusName ? option.statusName : ''}
                        isOptionEqualToValue={(option, value) => {
                            if (value === "" || value === option ) {
                                return true;
                            } 
                            else {
                                return false;
                            }
                        }}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.statusCode, value: value.statusCode }); setStatusItemsValue({ statusCode: value.statusCode, statusName: event.target.innerHTML }) }}
                        renderOption={(props, statusItems) => {
                            return (
                                <li {...props} key={statusItems.statusCode}>
                                    {statusItems.statusName}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField size="small" {...params} label="Status" placeholder="Status" />
                        )} />

                    {userRole === roleName.agent ? false : true && <Autocomplete disabled={userRole === roleName.admin ? false : true} className="col-6" id="ddlProfitCenter" size="small" options={profitCenterItems} value={profitCenterItemsValue} getOptionLabel={(option) => option.profitCenterName}
                        onChange={(event, value) => { Dispatch({ type: updateBondPropByName.profitCenterCode, value: value.profitCenterCode }); setProfitCenterItemsValue({ profitCenterCode: value.profitCenterCode, profitCenterName: event.target.innerHTML }); bindDepartment(value.profitCenterCode); }}
                        renderOption={(props, profitCenterItems) => {
                            return (
                                <li {...props} key={profitCenterItems.profitCenterCode}>
                                    {profitCenterItems.profitCenterName}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField size="small" {...params} label="Profit Center" placeholder="Profit Center" />
                        )} />
                    }

                    {userRole === roleName.agent ? false : true &&
                        <Autocomplete disabled={userRole === roleName.admin ? false : true} className="col-6" id="ddlDepartment" size="small" options={departmentItems} value={departmentItemsValue} getOptionLabel={(option) => option.deptName}
                            onChange={(event, value) => { Dispatch({ type: updateBondPropByName.departmentCode, value: value.deptCode }); setDepartmentItemsValue({ deptCode: value.departmentCode, deptName: event.target.innerHTML }); bindProduct(value.deptCode); }}
                            renderOption={(props, departmentItems) => {
                                return (
                                    <li {...props} key={departmentItems.deptCode}>
                                        {departmentItems.deptName}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Department" placeholder="Department" />
                            )} />
                    }

                    {userRole === roleName.agent ? false : true &&
                        <Autocomplete disabled={userRole === roleName.admin ? false : true} className="col-6" id="ddlProduct" size="small" options={productItems} value={productItemsValue} getOptionLabel={(option) => option.productName}
                            onChange={(event, value) => { Dispatch({ type: updateBondPropByName.productCode, value: value.productCode }); setProductItemsValue({ productCode: value.productCode, productName: event.target.innerHTML }) }}
                            renderOption={(props, productItems) => {
                                return (
                                    <li {...props} key={productItems.productCode}>
                                        {productItems.productName}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Product" placeholder="Product" />
                            )} />
                    }

                    {userRole !== roleName.agent &&
                    <FormControlLabel control={<Checkbox checked={state.AgencyVarianceResolved} 
                                      onChange={(e) => { 
                                                            Dispatch({ type: updateBondPropByName.agencyVarianceCheck }); 
                                                       }
                                                }/> } 
                                       disabled={userRole === roleName.inquiry ? true : false}
                                      className="col-12 checkbox" 
                                      label="Agency Variance Resolved"/>}
                    {userRole !== roleName.agent && 
                    <TextField size="small" inputProps={{ maxLength: 200 }} 
                               multiline fullWidth 
                               minRows={6}
                               disabled={userRole === roleName.inquiry ? true : false}
                               className="col-12 multilineTextField" 
                               label="For Internal Use Only" 
                               value={state.InternalComments} 
                               onChange={(e) => { 
                                                    Dispatch({ 
                                                                type: updateBondPropByName.internalComments, 
                                                                value: e.target.value 
                                                            }); 
                                                }} 
                    />} 
                    <TextField size="small" inputProps={{ maxLength: 200 }} 
                                multiline 
                                fullWidth 
                                minRows={6} 
                                disabled={userRole === roleName.inquiry ? true : userRole === roleName.admin ? false : statusFieldDisabled} className="col-12" label="Comment" value={state.Comment} onChange={(e) => { Dispatch({ type: updateBondPropByName.comment, value: e.target.value }); }} />
                </div>

                <div className={userRole === roleName.agent ? "col-12" : "displaynone"}>
                    <p className="note"><b>Note:</b> {note.voidEvidenceForAgent}</p>
                </div>
                
                <div className={userRole !== roleName.agent ? "col-12" : "displaynone"}>
                    <p className="note"><b>Note:</b> {note.voidEvidenceForInternalUser}</p>
                </div>

                <div className="col-12 text-center">
                    <Button variant="contained" className="btnStyling" color="success" disabled={!state.isFormUpdated} onClick={updateButtonClick}>Update</Button>
                    <span>  </span>
                    <Button variant="contained" className="btnStyling" color="info" onClick={goToPreviousPath}>Cancel</Button> 
                </div>
                

            </Box>
            <ToastContainer />
            <LoaderComponent show={showLoader}></LoaderComponent>
            <ConfirmModel showConfirmModal={confirmModalShow} message={confirmMessage} actionButtonName={actionButtonName} cancelButtonName={cancelButtonName} confirmAction={invokeActionFunction} closeConfirmModal={() => setConfirmModalShow(false)} />

        </React.Fragment>

    )
}
export default UpdateBond;