import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from "react-router-dom";
import { Button, Typography } from '@mui/material';
import React, { useState, useEffect } from "react";
import LoaderComponent from "../Common/Loader/LoaderComponent";
import UserDetails from "../../Hooks/UserDetails";
import { headers, apiUrls, componentConst, dataGridDensity, exportFileName, responseCodes, apiResponseCode, toastNoiticationConfig } from "../../Commoncode/constant";
import { displayReserveBondUrlPram } from "../shared/common";
import "../displayReserveBond/DisplayReserveBond.scss";
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import "../../style/Style.scss";
import { exportHeader, tableHeader } from "../../Models/displayReserveBond";
import { CSVLink } from "react-csv";
import { setDateTimeZone } from "../../Hooks/DateUtility";
import Grid from '@mui/material/Grid';

const size = window.innerWidth;
function DisplayReserveBond(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [tableRows, settableRows] = useState([]);
  const [exportTableRows, setExportTableRows] = useState([]);
  const [requestId, setRequestId] = useState(0);

  let today = setDateTimeZone(new Date());
  let day = ("0" + today.getDate()).slice(-2);
  let month = ("0" + (today.getMonth() + 1)).slice(-2);
  let currDate = "Date Reserved (MM/DD/YYYY): " + month + '/' + day + '/' + today.getFullYear();


  useEffect(() => {
    if (props.location.search) {

      const search = props.location.search.replace(/#/g, "?");
      const params = new URLSearchParams(search);

      setRequestId(params.get(displayReserveBondUrlPram.requestId));

      setShowLoader(true);
      let setDetails = {
        requestId: params.get(displayReserveBondUrlPram.requestId)
      }

      axios.post(apiUrls.getReserveBondInformationNyRequestId, setDetails, { headers: headers })
        .then((response) => {
          if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {
          setShowLoader(false);
          var totalRecords = [];
          setExportTableRows([]);
          settableRows([]);
          settableRows(response.data.getBondNumberDtls);
          if (response.data.getBondNumberDtls.length > 0) {
            response.data.getBondNumberDtls.map((item, index) => {
              return totalRecords.push({
              dateReserved: item.dateReserved ? item.dateReserved : '',
              agencyNumber: item.agencyNumber ? '=""' +item.agencyNumber+ '""' : '',
              agencyName: item.agencyName ? item.agencyName : '',
              bondNumber: item.bondNumber? item.bondNumber:'',
              bondAmount: item.bondAmount? item.bondAmount:'',
              principal:item.principal?item.principal:'',
              obligee:item.obligee?item.obligee:'',
              executionDate:item.executionDate?item.executionDate:''
            });
          });
          setExportTableRows(totalRecords);
        }
          }
          else
          {
            setShowLoader(false);
            settableRows([]);
            toast.warning(toastNoiticationConfig.internalServerError, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        })
          .catch(error => {
              setShowLoader(false);
          });

    }
    // eslint-disable-next-line 
  }, [])




  const callSendEmail = () => {
    if(requestId === 0)
    {
      toast.warning(toastNoiticationConfig.noRecordFound, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let loggedInUserDetails = UserDetails();
    let emailRequestDetails = {
      UserName: loggedInUserDetails.userName,
      RequestID: requestId
    };

    setShowLoader(true);
    axios.post(apiUrls.emailReserveBondInformation, emailRequestDetails, { headers: headers })
      .then((response) => {
        setShowLoader(false);

        if (response.status === responseCodes.success && response.data.responseMessages != null && response.data.responseMessages.responseCode === apiResponseCode.SuccessRequest) {

          toast.success(toastNoiticationConfig.emailSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.success(toastNoiticationConfig.emailNotSentSuccessfully, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(error => {
        setShowLoader(false);
      });

  }


  const callExport = () => {

    toast.success(toastNoiticationConfig.exportSuccessfully, {
      position: toast.POSITION.TOP_RIGHT
    });

  };



  return (
    <React.Fragment>
      <Typography variant="h5" className="pageHeading">
        Bond Number Information
      </Typography>


      <div className="body-container">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h9" className="fontLabel" style={{ textAlign: 'left' }}>
              {currDate}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h9" className="fontLabel" style={{ textAlign: 'left' }}>
              Agency Name: {tableRows[0]?.agencyName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h9" className="fontLabel" style={{ textAlign: 'left' }}>
              Agency Number: {tableRows[0]?.agencyNumber}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ height: 380, overflow: "auto" }}>
          <DataGrid autoHeight={false} density={dataGridDensity.compact} className="muiDataGrid" rows={tableRows} columns={tableHeader}
            pagination
            rowsPerPageOptions={[20, 50, 100]}
            rowHeight={size >= 1400 ? 50 : 20}
            maxRowHeight={size >= 1400 ? 50 : 20}
            minRowHeight={size >= 1400 ? 50 : 20}
            headerHeight={size >= 1400 ? 50 : 22}
          />
        </Box>
      </div>


      <div className="col-md-12">
        <Box textAlign="center"> <CSVLink filename={exportFileName.bondNumberInformation} style={{ textDecoration: 'none' }} data={exportTableRows} headers={exportHeader}> <Button variant="contained" color="primary" className="btnStyling ml1" onClick={callExport}>Export</Button> </CSVLink>
          <Button variant="contained" className="btnStyling ml1" color="primary" onClick={callSendEmail}>Email</Button>
          <NavLink style={{ textDecoration: 'none' }} to={componentConst.home}><Button variant="contained" color="primary" className="btnStyling ml1" >Home</Button></NavLink>
        </Box>
      </div>


      <ToastContainer />

      <LoaderComponent show={showLoader}></LoaderComponent>

    </React.Fragment>
  )
}

export default DisplayReserveBond;