import { compareAsc, format } from 'date-fns'

export function getInstallmentDueDate(date) {
    if (date) {
        date = new Date(new Date(date).toLocaleString("en-US", { timeZone: "America/New_York" }));
        date.setDate(date.getDate() + 46);
        date = format(date, 'yyyy-MM-dd');

        return date;
    }
    else {
        return date;
    }
}
//to validate (MM/DD/YYYY), with a year between 1900 and 2099
export function validateDate(testdate) {
        var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
        return date_regex.test(testdate);
}

export function dateFormating(date) {
    if (date) {
       // date = new Date(new Date(date).toLocaleString("en-US", { timeZone: "America/New_York" }));
        date = new Date(date);
        date = format(date, 'MM-dd-yyyy');

        return date;
    }
    else {
        return date;
    }
}

export function getSixMonthPriorDate(date) {
    if (date) {
        var startDate = new Date(new Date(date).toLocaleString("en-US", { timeZone: "America/New_York" }));
        startDate.setMonth(startDate.getMonth() - 5);
        startDate = startDate.setDate(1);
        startDate = format(startDate, 'MM-dd-yyyy');

        var endDate = new Date(new Date(date).toLocaleString("en-US", { timeZone: "America/New_York" }));
        endDate.setDate(1);
        endDate.setMonth(endDate.getMonth() + 2);
        endDate.setDate(0);
        endDate = format(endDate, 'MM-dd-yyyy');

        return `${startDate} - ${endDate}`;
    }
    else {
        return date;
    }
}